import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, Paper, Stack, TableHead, Typography } from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
import { Loading, Loading2 } from "../Components/Loading";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddIcon from "@mui/icons-material/Add";

import {
  ChartDountMarketShare,
  ChartDountValueShare,
  ChartDountRevenue,
} from "../Components/Chart";
import { TablePopUp } from "../Components/Table";
import {
  applyScenario,
  getProjectData,
} from "../Components/Network/Lib/Simulator";
import InputTypeBox from "../Components/scenarioBuilder/InputTypeBox";
import InputGroupBox from "../Components/scenarioBuilder/InputGroupBox";
import InputSkusBox from "../Components/scenarioBuilder/InputSkusBox";
import TableContainerTemp from "../Components/scenarioBuilder/TableContainer";
import ApplyButtonBox from "../Components/scenarioBuilder/ApplyButtonBox";
import { styled } from '@mui/material/styles';
import {Table, TableRow, TableCell, TableBody, TableContainer} from "@mui/material";


// function EditToolbar(props) {
//   const { setRows, setRowModesModel } = props;
//   const [fetchedRows, setFetchedRows] = useState([]);
//   const [rowData, setRowData] = useState([]);
//   const [selectedIds, setSelectedIds] = useState([]);
//   const [skuOptions, setSkuOptions] = React.useState([]);
//   const [sku, setSku] = React.useState("");
//   const [maximize, setMaximize] = React.useState("");
//   const [group, setGroup] = React.useState("");
//   const [show, setShow] = React.useState(false);
//   const [groupOptions, setGroupOptions] = React.useState([]);
//   const [groupTypeOptions, setGroupTypeOptions] = React.useState([]);
//   const [groupType, setGroupType] = useState("");
//   const [flag, setFlag] = useState(true);

//   const theme = useTheme();

//   const handleClick = () => {
//     console.log(fetchedRows);
//     let found_row = {};
//     fetchedRows.forEach((row) => {
//       if (row.name == sku && !selectedIds.includes(row.id)) {
//         found_row = row;
//         let id = found_row.id;
//         setSelectedIds((oldArray) => [...oldArray, id]);
//         setRows((oldRows) => [
//           ...oldRows,
//           {
//             id,
//             name: found_row.name,
//             old_price: found_row.old_price,
//             new_price: found_row.new_price,
//             old_size: found_row.old_size,
//             new_size: found_row.new_size,
//             old_wd: found_row.old_wd,
//             new_wd: found_row.new_wd,
//             isNew: true,
//           },
//         ]);
//         setRowModesModel((oldModel) => ({
//           ...oldModel,
//           [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
//         }));
//       } else {
//         return;
//       }
//     });
//   };

//   function handleSummary(group, data) {
//     let grouped = [];
//     data.forEach(function (o) {
//       if (!this[o[group]]) {
//         this[o[group]] = {
//           Brand: o[group],
//           SOP: 0,
//           Changed_SOP: 0,
//           base_market_share: 0,
//           new_market_share: 0,
//           base_value_share: 0,
//           new_value_share: 0,
//           base_value_sales: 0,
//           new_value_sales: 0,
//         };
//         grouped.push(this[o[group]]);
//       }
//       this[o[group]].SOP += o.SOP;
//       this[o[group]].Changed_SOP += o.Changed_SOP;
//       this[o[group]].base_market_share += o.base_market_share;
//       this[o[group]].new_market_share += o.new_market_share;
//       this[o[group]].base_value_share += o.base_value_share;
//       this[o[group]].new_value_share += o.new_value_share;
//       this[o[group]].base_value_sales += o.base_value_sales;
//       this[o[group]].new_value_sales += o.new_value_sales;
//     }, Object.create(null));
//     setGroupOptions(grouped);
//   }

//   useEffect(() => {
//     getProjectData({
//       name: "savola smart shopper oil category traditional",
//     }).then((response) => {
//       // console.log(response.data);
//       const skus = [];
//       const rows1 = [];
//       response.data.data.forEach((item) => {
//         skus.push(item["SKU Name"]);
//         rows1.push({
//           id: item["index"],
//           name: item["SKU Name"],
//           old_price: item["Base Price"],
//           new_price: item["New Price"],
//           old_size: item["Base Size"],
//           new_size: item["New Size"],
//           old_wd: item["base_wd"],
//           new_wd: item["new_wd"],
//         });
//       });
//       console.log(response.data);
//       setFetchedRows(rows1);
//       setSkuOptions(skus);
//       setGroupTypeOptions(response.data.group);
//       handleSummary(maximize, response.data.data);
//     });
//   }, []);
//   const ITEM_HEIGHT = 48;
//   const ITEM_PADDING_TOP = 8;
//   const MenuProps = {
//     PaperProps: {
//       style: {
//         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//         width: 250,
//       },
//     },
//   };

//   return (
//     <>
//       <Stack
//         justifyContent="center"
//         alignItems="center"
//         direction="row"
//         sx={{ gap: 1, mb: 1 }}
//       >
//         <Paper sx={{ p: 1, boxShadow: 0 }}>
//           <Box sx={{ width: 200 }}>
//             <StyledHeaderDescription
//               sx={{ pt: 0.7, pb: 1 }}
//             ></StyledHeaderDescription>
//             <FormControl sx={{ width: "100%" }} size="small">
//               <InputLabel id="demo-simple-select-label">
//                 Select Group Type
//               </InputLabel>

//               <Select
//                 labelId="demo-multiple-chip-label"
//                 id="demo-multiple-chip"
//                 value={groupType}
//                 onChange={(event) => {
//                   setGroupType(event.target.value);
//                 }}
//                 input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
//                 MenuProps={MenuProps}
//               ></Select>
//             </FormControl>
//           </Box>
//         </Paper>
//         <Paper sx={{ p: 2, boxShadow: 0 }}>
//           <Box sx={{ width: 200 }}>
//             <StyledHeaderDescription
//               sx={{ pt: 0.7, pb: 1 }}
//             ></StyledHeaderDescription>
//             <FormControl sx={{ width: "100%" }} size="small">
//               <InputLabel id="demo-simple-select-label">
//                 Select Group
//               </InputLabel>
//               <Select
//                 labelId="demo-simple-select-label"
//                 id="demo-simple-select"
//                 value={group}
//                 label="group"
//                 onChange={(event) => {
//                   setGroup(event.target.value);
//                 }}
//               >
//                 <MenuItem value={"Afia Corn"}>Afia Corn</MenuItem>
//                 <MenuItem value={"Afia SF"}>Afia SF</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//         </Paper>
//         <Paper sx={{ p: 2, boxShadow: 0 }}>
//           <Box sx={{ width: 200 }}>
//             <StyledHeaderDescription
//               sx={{ pt: 0.7, pb: 1 }}
//             ></StyledHeaderDescription>
//             <Box sx={{ width: 200 }}>
//               <FormControl sx={{ width: "100%" }} size="small">
//                 <InputLabel id="demo-simple-select-label">
//                   Select SKU
//                 </InputLabel>
//                 <Select
//                   labelId="demo-simple-select-label"
//                   id="demo-simple-select"
//                   value={sku}
//                   label="maximize"
//                   onChange={(event) => {
//                     setSku(event.target.value);
//                     setMaximize(event.target.value);
//                     handleSummary(event.target.value, rowData);
//                   }}
//                 >
//                   {skuOptions.map((name) => (
//                     <MenuItem key={name} value={name}>
//                       {name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Box>
//           </Box>
//         </Paper>
//         <Button
//           variant="contained"
//           sx={{
//             textTransform: "capitalize",
//             position: "relative",
//             mt: 3,
//             backgroundColor: `${theme.palette.primary.main}`,
//           }}
//           color="primary"
//           startIcon={<AddIcon />}
//           onClick={handleClick}
//         >
//           SKU
//         </Button>
//       </Stack>
//     </>
//   );
// }

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Scenarionew = () => {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rowData, setRowData] = React.useState([]);
  const [rowData1, setRowData1] = React.useState([]);
  const [skuOptions, setSkuOptions] = React.useState([]);
  const [sku, setSku] = React.useState({});
  const [skus, setSKUs] = React.useState([]);
  const [maximize, setMaximize] = React.useState("");
  const [groupOptions, setGroupOptions] = React.useState([]);
  const [groupTypeOptions, setGroupTypeOptions] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [group, setGroup] = React.useState("");
  const [result, setResult] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [target, setTarget] = React.useState("");
  const [promotion, setPromotion] = React.useState("");
  const [intervals, setIntervals] = React.useState("");
  const [personName, setPersonName] = React.useState([]);
  const [bestPromotion, setBestPromotion] = React.useState([]);

  const theme = useTheme();

  // const handleRowEditStart = (params, event) => {
  //   event.defaultMuiPrevented = true;
  // };

  // const handleRowEditStop = (params, event) => {
  //   event.defaultMuiPrevented = true;
  // };

  // const handleEditClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  // };

  // const handleSaveClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  // };

  // const handleDeleteClick = (id) => () => {
  //   setRows(rows.filter((row) => row.id !== id));
  // };

  // const handleCancelClick = (id) => () => {
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
  //   });

  //   const editedRow = rows.find((row) => row.id === id);
  //   if (editedRow.isNew) {
  //     setRows(rows.filter((row) => row.id !== id));
  //   }
  // };

  // const processRowUpdate = (newRow) => {
  //   const updatedRow = { ...newRow, isNew: false };
  //   setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
  //   return updatedRow;
  // };

  // const handleRowModesModelChange = (newRowModesModel) => {
  //   setRowModesModel(newRowModesModel);
  // };

  const handleClick = () => {
    setShow(true);
    setLoading(false);
  };

  const handleClose = () => {
    setShow(false);
    // setresult({});
    console.log(result);
  };
  function handleSummary(group, data) {
    let grouped = [];
    data.forEach(function (o) {
      if (!this[o[group]]) {
        this[o[group]] = {
          Brand: o[group],
          SOP: 0,
          Changed_SOP: 0,
          base_market_share: 0,
          new_market_share: 0,
          base_value_share: 0,
          new_value_share: 0,
          base_value_sales: 0,
          new_value_sales: 0,
        };
        grouped.push(this[o[group]]);
      }
      this[o[group]].SOP += o.SOP;
      this[o[group]].Changed_SOP += o.Changed_SOP;
      this[o[group]].base_market_share += o.base_market_share;
      this[o[group]].new_market_share += o.new_market_share;
      this[o[group]].base_value_share += o.base_value_share;
      this[o[group]].new_value_share += o.new_value_share;
      this[o[group]].base_value_sales += o.base_value_sales;
      this[o[group]].new_value_sales += o.new_value_sales;
    }, Object.create(null));
    console.log(grouped);
    setGroupOptions(grouped);
  }

  const handleChange = (event) => {
    setGroup(event.target.value);
    console.log(event.target.value);
    const rows = [];
    rowData1.forEach((item) => {
      if (item[maximize] === event.target.value) {
        rows.push(item);
      }
    });
    setSKUs(rows);
    console.log(rows);
  };

  const handleRowChange = (e, row, key) => {
    console.log(e.target.value, row, key);
    let newArr = [...skuOptions];
    if (key === "price") {
      for (const i in newArr) {
        if (newArr[i]["SKU Name"] === row) {
          
            console.log(newArr[i]["new_price"]);
            newArr[i]["new_price"] = parseFloat(e.target.value);

        }
      }
    } else if (key === "size") {
      for (const i in newArr) {
        console.log(newArr[i]["SKU Name"]);
        if (newArr[i]["SKU Name"] === row) {
          newArr[i]["new_size"] = parseFloat(e.target.value);
        }
      }
    } else if (key === "wd") {
      for (const i in newArr) {
        console.log(newArr[i]["SKU Name"]);
        if (newArr[i]["SKU Name"] === row) {
          newArr[i]["new_wd"] = parseFloat(e.target.value);
        }
      }
    }
    console.log(newArr);
    setSkuOptions(newArr);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleRowDelete = (index) => {
    setSkuOptions(skuOptions.filter((item) => item.index !== index));
    console.log(skuOptions);
  };

  const addSku = () => {
    const addedSku = JSON.parse(JSON.stringify(sku));
    setSkuOptions([...skuOptions, addedSku]);
    console.log(rowData1);
  };
  useEffect(() => {
    getProjectData({
      project_name: "araby6",
    }).then((response) => {
      const rows1 = [];
      console.log(response.data);
      setRowData(rows1);
      setRowData1(response.data.data);
      setGroupTypeOptions(response.data.type);
    });
  }, []);

  return (
    <>
      <Typography
        sx={{
          color: `${theme.palette.primary.main}`,
          pt: 3,
          fontSize: "20px",
          fontWeight: 700,
        }}
      >
        Scenario Builder
      </Typography>
      <Box
        sx={{
          fontSize: "1.1rem",
          pb: 5,
          color: "#6D6D6D",
        }}
      >
        Assess the uncertainties and potential disruptions by exploring their
        impact with our scenario builder tool.
      </Box>
      <Paper elevation={0}>
      <Grid container spacing={2}>
        <Grid  item xs={6}>
        <Item elevation={4} style={{marginBottom: "20px", marginLeft: "20px"}}>
        <Stack justifyContent={"center"} direction="row">
          <InputTypeBox
            maximize={maximize}
            setMaximize={setMaximize}
            handleSummary={handleSummary}
            groupTypeOptions={groupTypeOptions}
            rowData1={rowData1}
          />
          <InputGroupBox
            group={group}
            groupOptions={groupOptions}
            handleChange={handleChange}
          />
          <InputSkusBox
            sku={sku}
            rowData1={rowData1}
            skus={skus}
            setSku={setSku}
          />
          <Button
            sx={{
              marginLeft: "auto",
              // background: "#4680FF",
              height: "30px",
              textTransform: "capitalize",
              color: "white",
              mt: 7,
              ml: 3,
            }}
            variant="contained"
            onClick={addSku}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </Stack>
        
        <TableContainerTemp
          skuOptions={skuOptions}
          handleRowChange={handleRowChange}
          handleRowDelete={handleRowDelete}
        />
        <ApplyButtonBox
          setLoading={setLoading}
          setFlag={setFlag}
          setOpen={setOpen}
          setResult={setResult}
          skuOptions={skuOptions}
        />
        </Item>
        </Grid>
          <Grid item xs={6}>
          <Typography
                  sx={{
                    color: `${theme.palette.primary.main}`,
                    fontSize: "20px",
                    fontWeight: 700,
                   
                  }}
                >
                  Company Performance
                </Typography>
                {loading?
                (<Item elevation={4} style={{marginBottom: "10px", marginRight:"20px", height:"335px", justifyContent:"center"}}>
                    <Typography>
                      Please apply a scenario to show the results
                    </Typography>
                  </Item>):
                  (
                    <>
                    <Item elevation={4} style={{marginBottom: "10px", marginRight:"20px"}}>
                    {flag ? (
                        <>
                          <Loading2 />
                        </>
                      ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <ChartDountMarketShare
                                marketShare={(result?.new_volume_share)?.toFixed(2)}
                                currentmarketShare={Math.floor(result?.base_volume_share)}
                              />
                        </Grid>
                      <Grid item xs={6}>
                          <ChartDountValueShare
                            valueShare={(result?.new_value_share *100)?.toFixed(2)}
                            currentvalueShare={Math.floor(result?.base_value_share * 100)}
                          />
                      </Grid>
                    </Grid>
                      )}
                    </Item>
                  
                    <Item elevation={4} style={{marginBottom: "20px", marginRight:"20px"}}>
                    {flag ? (
                        <>
                          <Loading2 />
                        </>
                      ) : (
                          <ChartDountRevenue
                            revenue={result?.new_revenue?.toFixed(2)}
                            currentrevenue={Math.floor(result?.base_revenue)}
                            revenueperc={result?.new_rev_perc}
                            currentrevenueperc={result?.base_rev_perc}
                          />
                      )}

                    </Item>
                    </>
                    )}
          <Typography
                  sx={{
                    color: `${theme.palette.primary.main}`,
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                >
                  Main Competitor
            </Typography>
            {loading ?
                (<Item elevation={4} style={{marginBottom: "10px", marginRight:"20px", height:"225px", justifyContent:"center"}}>
                    <Typography>
                      Please apply a scenario to show the results
                    </Typography>
                  </Item>):
                  (
          <Item elevation={4} style={{marginBottom: "20px", marginRight:"20px"}}>
          {flag ? (
                        <>
                          <Loading2 />
                        </>
                      ) : (
                        <>
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "15px",
                            fontWeight: 500
                          }}
                        style={{textAlign: "left"}}>
                          Samsung
                        </Typography>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Brand</TableCell>
                                <TableCell align="right">Sales</TableCell>
                                <TableCell align="right">Market Share</TableCell>
                                <TableCell align="right">Volume Share</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                  key={0}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    Samsung
                                  </TableCell>
                                  <TableCell align="right">100M</TableCell>
                                  <TableCell align="right">50%</TableCell>
                                  <TableCell align="right">60%</TableCell>
                                </TableRow>
                                <TableRow
                                  key={1}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    Samsung
                                  </TableCell>
                                  <TableCell align="right">100M</TableCell>
                                  <TableCell align="right">50%</TableCell>
                                  <TableCell align="right">60%</TableCell>
                                </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button disabled={flag} onClick={()=>{setShow(true)}}>More Details</Button>
                        </>
                      )}
                
                  </Item>
                  )}
          
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        fullScreen={fullScreen}
        open={show}
        maxWidth="xl"
        aria-labelledby="responsive-dialog-title"
      >
        {flag ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <DialogContent>
              <Stack
                direction="row"
                gap={2}
                sx={{
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <ChartDountMarketShare
                  marketShare={(result?.new_volume_share)?.toFixed(2)}
                  currentmarketShare={Math.floor(result?.base_volume_share)}
                />
                <ChartDountValueShare
                  valueShare={(result?.new_value_share *100)?.toFixed(2)}
                  currentvalueShare={Math.floor(result?.base_value_share * 100)}
                />
                <ChartDountRevenue
                  revenue={result?.new_revenue?.toFixed(2)}
                  currentrevenue={Math.floor(result?.base_revenue)}
                  revenueperc={result?.new_rev_perc}
                  currentrevenueperc={result?.base_rev_perc}
                />
              </Stack>
              <TablePopUp data={result} />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  border: "1px solid gary",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  mt: 1,
                  mb: 1,
                  color: "gray",
                  border: "1ps solid red",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  m: 1,
                }}
                startIcon={<FileDownloadOutlinedIcon />}
              >
                Download
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default Scenarionew;
