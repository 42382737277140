import React from "react";
import Dropzone from "react-dropzone";

import {
  Container,
  Grid,
  Button,
  Icon,
  Segment,
  Header,
  Modal,
  Step,
  Dimmer,
  Loader,
} from "semantic-ui-react";

function AddFinancialData({
  addFinancial,
  setState,
  isFileready,
  onDrop,
  files,
  handleFinancial,
  isUploading,
}) {
  return (
    <Container>
      <Modal
        centered={true}
        open={addFinancial}
        onClose={() => setState({ addFinancial: false })}
        onOpen={() => setState({ addFinancial: true })}
      >
        <Modal.Header>Add Financial Data</Modal.Header>
        <Modal.Content>
          <Grid centered>
            <Step.Group size="small">
              <Step>
                <Icon name="file excel" />
                <Step.Content>
                  <Step.Title>Financial Data</Step.Title>
                  <Step.Description>
                    Add your Financial Data From Excel
                  </Step.Description>
                </Step.Content>
              </Step>

              <Step disabled={!isFileready}>
                <Icon name="upload" />
                <Step.Content>
                  <Step.Title>Uploading</Step.Title>
                  <Step.Description>
                    Uploading the financial data
                  </Step.Description>
                </Step.Content>
              </Step>
            </Step.Group>
          </Grid>
          <Segment placeholder>
            <Dimmer active={isUploading} inverted>
              <Loader indeterminate></Loader>
            </Dimmer>
            <Segment>
              {!isFileready ? (
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="ContainerDrag">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                      <aside></aside>
                    </section>
                  )}
                </Dropzone>
              ) : (
                <Header textAlign="center">
                  <Icon name="settings" />
                  <Header.Content>
                    Financial Data Name
                    <Header.Subheader>{files[0]?.name}</Header.Subheader>
                  </Header.Content>
                </Header>
              )}
            </Segment>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={isFileready} color="blue" onClick={handleFinancial}>
            Next
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}

export default AddFinancialData;
