import React, { Component } from "react";
import GridSimulator from "../Components/grid/grid";
import axios from "axios";
import { getAuthLevel, getToken, getUrl } from "../Utils/Common";
import { Box, Paper, Typography } from "@mui/material";
import { Loading } from "../Components/Loading";
import { StyledTitleTypography } from "../Styled/Typography";
import { MappingData } from "../Utils/Handlres";
import WarningModal from "../Components/WarningModal";

export default class simulator extends Component {
  constructor(props) {
    super(props);
    this.initialized = false; // Set a flag to ensure that componentDidMount logic runs only once
  }
  state = {
    unSavedData: {},
    projectname: "",
    activeItem: "Main Simulator",
    isUploading: false,
    rowData1: [],
    rowData: [],
    columnsGridMain: [],
    columnsGridSum: [],
    rowDataScenario: [],
    columnsSOPScenario: [],
    columnsMarketScenario: [],
    columnsValueScenario: [],
    valueScenario: [],
    groups: [],
    currentGroup: [],
    grouping: {},
    type: [],
    options: [],
    scenarios: [],
    segments: [],
    currentSegment: "",
    currentscenario: "",
    companyName: "",
    companyLocation: "",
    scenario_state_undo: [],
    scenario_state_redo: [],
    mountFlag: false,
    finance_available: true,
  };

  componentDidMount() {
    if (!this.initialized) {
      this.initialized = true;
      this.getGridData();
      this.getScenarios();
      window.addEventListener(
        "beforeunload",
        this.handleBeforeUnload.bind(this)
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      "beforeunload",
      this.handleBeforeUnload.bind(this)
    );
  }

  handleBeforeUnload = (event) => {
    if (this.state.unSavedData["change_dict"]) {
      event.preventDefault();
      event.returnValue = ""; // Display a confirmation message (some browsers may not show this message)
      this.setState({ unSavedData: {} });
    }
  };

  handleSegments = (segments) => {
    let arr = [];
    for (const key in segments) {
      segments[key]["Segment Levels"].forEach((ele) => {
        arr.push({
          keys: key + " : " + ele,
          text: key + " : " + ele,
          value: key + " : " + ele,
        });
      });
    }
    this.setState({ segments: arr });
    return arr;
  };
  handleGrouping = (groups) => {
    let arr = [];
    for (const key in groups) {
      arr.push({
        key,
        text: key.replace("_", " "),
        value: key,
        data: groups[key],
      });
    }
    return arr;
  };
  handleStateChanges = (newState) => {
    this.setState({
      rowData: newState.rowData,
      rowData1: newState.rowData1,
      currentGroup: newState.currentGroup,
      grouping: newState.grouping,
      columnsGridMain: newState.columns,
      columnsGridSum: newState.columns1,
      currentscenario: newState.scenario,
      scenarios: newState.scenarios,
      scenario_state_redo: newState.scenario_state_redo,
      scenario_state_undo: newState.scenario_state_undo,
      finance_available: newState.finance_available,
    });
  };
  async getScenarios() {
    const { data } = await axios.get(`${getUrl()}/sim/get_saved_scenarios?`, {
      params: {
        project_name: "araby6",
      },
    });
    const scenarioData = data.scenarios.map((item) => ({
      keys: item._id,
      text: item._id,
      value: item._id,
    }));
    this.setState({ scenarios: scenarioData });
  }
  async getGridData() {
    const { data } = await axios.get(`${getUrl()}/sim/get_project_by_name?`, {
      params: {
        project_name: "araby6",
      },
      headers: {
        "x-access-token": getToken(),
      },
    });
    if (!Object.keys(data["data"][0]).includes("base_vat")) {
      this.setState({
        finance_available: false,
      });
    }
    console.log(data);

    this.setState({
      segments: this.handleSegments(data["segments"]),
      projectname: "araby6",
      rowData: data.data,
      type: [
        {
          base_price: 1,
        },
        {
          base_size: 1,
        },
      ],
      groups: this.handleGrouping(data["grouping_options"]),
      currentGroup: [this.handleGrouping(data["grouping_options"])[0].key],
      grouping: {
        props: [this.handleGrouping(data["grouping_options"])[0].key],
        expandedAll: true,
      },
      companyName: data["company_name"],
      companyLocation: data["company_location"],
      mountFlag: true,
      columns: data.columns,
      options: data["columns_options"],
      numeric_columns: data["numeric_columns"],
      new_products_options: data["new_products_options"],
    });
    this.handleSegments(data["segments"]);
  }

  render() {
    return (
      <Paper sx={{ p: 2, m: 1 }}>
        <StyledTitleTypography>Simulator</StyledTitleTypography>
        <Box
          sx={{
            fontSize: "1.1rem",
            pb: 1,
            color: "#777",
            mt: "-10px !important",
            mb: "10px",
          }}
        >
          Forecast the impact of different pricing strategies on product demand
          by testing various scenarios, all tailored to your specific
          objectives.
        </Box>
        {this.state.mountFlag ? (
          <>
            <GridSimulator
              props={this.state}
              setData={this.setState.bind(this)}
              onChangeData={this.handleStateChanges}
            />
          </>
        ) : (
          <>
            <Loading />
          </>
        )}
      </Paper>
    );
  }
}
