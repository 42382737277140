import React from "react";
import { Paper } from "@mui/material";
import SegmentTable from "./SegmentTable";
import PieChart from "../../Shared/PieChart";
import TableDividerPieChart from "./TableDividerPieChart";
import TableAccordion from "./TableAccordion";

function Segment() {
  return (
    <Paper>
      <TableAccordion />
    </Paper>
  );
}

export default Segment;
