import { Box } from "@mui/material";
import React from "react";

export const ProgressBar = ({
  bgcolor,
  progressChild,
  number,
  icon,
  options,
}) => {
  const Childdiv = {
    width: `${progressChild}%`,
    backgroundColor: bgcolor,
    paddingLeft: "10px",
  };

  const progresstext = {
    margin: "auto",
    padding: 10,
    width: "fit-content",
    whiteSpace: "nowrap",
  };

  return (
    <Box sx={{ display: "flex" }}>
      <div style={{ width: "100%" }}>
        <div style={Childdiv}>
          <span style={progresstext}>{`${number}${icon ? icon : "%"}`}</span>
        </div>
      </div>
      <div>{options && options}</div>
    </Box>
  );
};

export const MuliProgressBar = ({
  progress1,
  number1,
  progress2,
  number2,
  progress3,
  number3,
  bgcolor1,
  bgcolor2,
}) => {
  const Parentdiv = {
    width: "100%",
    backgroundColor: "#D9D9D9",
    display: "inline-flex",
  };

  const progresstext = {
    padding: 10,
  };

  return (
    <div style={Parentdiv}>
      <div
        style={{
          display: " inline-block",
          width: `${progress1}%`,
          backgroundColor: `${bgcolor1}`,
          color: "white",
        }}
      >
        <span style={progresstext}>{`${number1}%`}</span>
      </div>
      <div
        style={{
          display: " inline-block",
          width: `${progress2}%`,
          backgroundColor: `${bgcolor2}`,
        }}
      >
        <span>{`${number2}%`}</span>
      </div>
      <div
        style={{
          display: " inline-block",
          width: `${progress3}%`,
        }}
      >
        <span>{`${number3}%`}</span>
      </div>
    </div>
  );
};

export const ProgressLine = ({ number }) => {
  return (
    <div style={{ backgroundColor: "red", height: "15px", width: "100%" }}>
      {number}%
    </div>
  );
};
