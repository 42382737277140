import React, { useEffect, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import { StyledTitleTypography } from "../../Styled/Typography";
import { Gaps } from "../../Components/Landscape/GapandOpportunities";
import ValueTableComponent from "../../Components/Dashboard/marketTables/ValueTable";
import VolumeTableComponent from "../../Components/Dashboard/marketTables/VolumeTable";
import PriceIdxTableComponent from "../../Components/Dashboard/marketTables/PriceIdxTable";
import SegmentsTable from "../../Components/Dashboard/companyPerformance/tables/SegmentsTable";
import ValueTable from "../../Components/Dashboard/companyPerformance/tables/ValueTable";
import VolumeTable from "../../Components/Dashboard/companyPerformance/tables/VolumeTable";
import PriceIdxTable from "../../Components/Dashboard/companyPerformance/tables/PriceIdxTable";
import BrandValueKPIsTable from "../../Components/Dashboard/brandValueKPIs/tables/BrandValueKPIsTable";
import axios from "axios";
import { toastError } from "../../Utils/Toast";
import Skelton from "../../Components/Shared/Skelton";
import { ToastContainer } from "react-toastify";
import { getUrl } from "../../Utils/Common";

const BASEURL = getUrl();

function Dashboard() {
  const [totalMarket, setTotalMarket] = useState([]);
  const [loadingTotalMarket, setLoadingTotalMarket] = useState(false);

  const [companyPerformance, setCompanyPerformance] = useState([]);
  const [loadingCompanyPerformance, setLoadingCompanyPerformance] =
    useState(false);

  const [brandValueKpi, setBrandValueKpi] = useState([]);
  const [loadingBrandValueKpi, setLoadingBrandValueKpi] = useState(false);

  let initialized = false;

  const getTotalMarket = async () => {
    try {
      setLoadingTotalMarket(true);
      const { data } = await axios.get(`${BASEURL}/sales/tot_mkt`);
      setTotalMarket(data);
      localStorage.setItem("totalMarket", JSON.stringify(data));
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingTotalMarket(false);
    }
  };

  const getTotalCompanyPerformance = async () => {
    try {
      setLoadingCompanyPerformance(true);
      const { data } = await axios.get(
        `${BASEURL}/sales/mfc_segments`
      );
      setCompanyPerformance(data);
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingCompanyPerformance(false);
    }
  };

  const getBrandValueKpi = async () => {
    try {
      setLoadingBrandValueKpi(true);
      const { data } = await axios.get(`${BASEURL}/sales/brand_value_kpis`);
      setBrandValueKpi(data);
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingBrandValueKpi(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      getTotalMarket();
      getTotalCompanyPerformance();
      getBrandValueKpi();
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <Paper sx={{ p: 1, m: 1 }}>
        <Box>
          <StyledTitleTypography>. Market</StyledTitleTypography>
          {loadingTotalMarket ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Skelton times={1} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Skelton times={1} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Skelton times={1} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <ValueTableComponent totalMarket={totalMarket} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <VolumeTableComponent totalMarket={totalMarket} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <PriceIdxTableComponent totalMarket={totalMarket} />
              </Grid>
            </Grid>
          )}
        </Box>
        <Box sx={{ mt: "15px", minHeight: "250px" }}>
          <StyledTitleTypography>. Company Performance</StyledTitleTypography>
          {loadingCompanyPerformance ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <Skelton times={3} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Skelton times={3} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Skelton times={3} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Skelton times={3} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <SegmentsTable companyPerformance={companyPerformance} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <ValueTable companyPerformance={companyPerformance} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <VolumeTable companyPerformance={companyPerformance} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <PriceIdxTable companyPerformance={companyPerformance} />
              </Grid>
            </Grid>
          )}
        </Box>
        <Box sx={{ mt: "15px" }}>
          <StyledTitleTypography>. Brand Value KPIs</StyledTitleTypography>
          {loadingBrandValueKpi ? (
            <Skelton times={3} />
          ) : (
            <BrandValueKPIsTable brandValueKpi={brandValueKpi} />
          )}
        </Box>
        <Box sx={{ mt: "15px" }}>
          <StyledTitleTypography>
            .Gaps (Mfc Growth &lt; Mkt)
          </StyledTitleTypography>
          <Gaps />
        </Box>
      </Paper>
    </>
  );
}

export default Dashboard;
