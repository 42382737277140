import { Box } from "@mui/material";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function ContributorChart({ data }) {
  const finalData = data?.map((item) => ({
    x: item.segment,
    y: item.contribution,
  }));

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 380,
    },
    colors: "rgb(0, 38, 94)",
    xaxis: {
      type: "category",
      group: {
        style: {
          fontSize: "10px",
          fontWeight: 700,
        },
        groups: [
          { title: "Q1", cols: 4 },
          { title: "Q2", cols: 4 },
        ],
      },
    },
    yaxis: {
      title: {
        text: "Contribution",
      },
    },
  });

  return (
    <Box sx={{ p: 2 }}>
      <ReactApexChart
        options={options}
        series={[{ data: finalData }]}
        type="bar"
        height={295}
      />
    </Box>
  );
}

export default ContributorChart;
