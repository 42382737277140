import axios from "axios";

const axiosClient = axios.create({
  baseURL: `https://corsproxy.io/?http://simulatorbackend-env.eba-3itakpmk.eu-west-3.elasticbeanstalk.com`,

  // baseURL: `http://simulator-env.eba-v2p3p26w.eu-west-3.elasticbeanstalk.com`,
  //baseURL: `http://127.0.0.1:5000`,

  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-cors-api-key": "temp_51e8ad9c278296f5d81927a51883127f",
    "X-Requested-With": "XMLHttpRequest",
  },
});

export default axiosClient;
