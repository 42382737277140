import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { applyScenario } from "../Network/Lib/Simulator";

function ApplyButtonBox({ skuOptions, setResult, setFlag, setOpen, setLoading }) {
  //{'project_name': 'araby6', 'change_dict': {'TOSHIBA 32 HD': {'new_price': 3965}}, 'new_products': None, 'segmentation': None}
  const handleScenario = () => {
    const data = {};
    data["project_name"] = "araby6";
    data["change_dict"] = {};
    for (const i in skuOptions) {
      console.log(skuOptions[i]["new_price"], skuOptions[i]["base_price"]);
      if (skuOptions[i]["new_price"] !== skuOptions[i]["base_price"]) {
        data["change_dict"][skuOptions[i]["SKU Name"]] = {}
        data["change_dict"][skuOptions[i]["SKU Name"]]['new_price'] = skuOptions[i]["new_price"];
      }
    }
    data['new_products'] = null
    data['segmentation'] = null
    applyScenario(data).then((response) => {
      console.log(response.data)
      if (response) {
        setResult(response.data);
        setFlag(false);
      }
      setOpen(true);
    });
  };
  const handleClickOpen = () => {
    setLoading(false)
    setFlag(true);
  };
  return (
    <Box display="flex" justifyContent={"center"}>
      <Typography
        variant="outlined"
        onClick={() => {
          handleClickOpen();
          handleScenario();
        }}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Button
          variant="contained"
          sx={{ textTransform: "capitalize", fontWeight: "bold", m: 3 }}
        >
          Apply{" "}
        </Button>
      </Typography>
    </Box>
  );
}

export default ApplyButtonBox;
