import { Box, Button, Stack } from "@mui/material";
import {
  StyledHeaderDescription,
  StyledModulesTitle,
} from "../../Styled/Typography";
import { StyledModulesCard } from "../../Styled/Stack";

const CardModuleOne = ({ title, description, img, handleClick }) => {
  return (
    <StyledModulesCard>
      <Box
        component="img"
        src={`${img}`}
        height="10rem"
        width="10rem"
        sx={{ m: 1 }}
      />
      <Stack sx={{ p: 2, m: 3 }} spacing={3}>
        <StyledModulesTitle>{title} </StyledModulesTitle>
        <StyledHeaderDescription>{description}</StyledHeaderDescription>
      </Stack>
      <Button
        variant="contained"
        sx={{ textTransform: "capitalize", mb: 2 }}
        onClick={handleClick}
      >
        Contiune
      </Button>
    </StyledModulesCard>
  );
};
export default CardModuleOne;
