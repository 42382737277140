import React, { useEffect } from "react";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import Box from "@mui/material/Box";
import { MuliProgressBar, ProgressBar } from "../../Progress";

function P3mValues({ maxHeight }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      textAlign: "center",
      padding: "5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
      padding: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const list = [
    {
      name: "40+ €",
      mkt: 0.2,
      alamieName: 3,
      lamarName: 1,
      others1Name: 3,
      alamie: 0.428571429,
      lamar: 0.142857143,
      others1: 0.428571429,
      duracell: 1.7,
    },
    {
      name: "30-40 €",
      mkt: 0.1,
      alamieName: 2,
      lamarName: 1,
      others1Name: 4,
      alamie: 0.628571429,
      lamar: 0.242857143,
      others1: 0.228571429,
      duracell: 1.6,
    },
    {
      name: "28-30 €",
      mkt: 0.1,
      alamieName: 1,
      lamarName: 3,
      others1Name: 3,
      alamie: 0.428571429,
      lamar: 0.142857143,
      others1: 0.428571429,
    },
    {
      name: "26-28 €",
      mkt: 0.3,
      alamieName: 4,
      lamarName: 3,
      others1Name: 2,
      alamie: 0.458571429,
      lamar: 0.144857143,
      others1: 0.128571429,
    },
    {
      name: "24-26 €",
      mkt: 0.6,
      alamieName: 4,
      lamarName: 1,
      others1Name: 4,
      alamie: 0.658571429,
      lamar: 0.244857143,
      others1: 0.028571429,
      duracell: 8.0,
    },
    {
      name: "22-24 €",
      mkt: 0.1,
      alamieName: 1,
      lamarName: 1,
      others1Name: 2,
      alamie: 0.758571429,
      lamar: 0.244857143,
      others1: 0.328571429,
      duracell: 5.7,
    },
    {
      name: "20-22 €",
      mkt: 0.4,
      alamieName: 6,
      lamarName: 4,
      others1Name: 3,
      alamie: 0.658571429,
      lamar: 0.244857143,
      others1: 0.428571429,
    },
    {
      name: "18-20 €",
      mkt: 0.2,
      alamieName: 3,
      lamarName: 5,
      others1Name: 1,
      alamie: 0.358571429,
      lamar: 0.544857143,
      others1: 0.128571429,
      duracell: 50.8,
    },
    {
      name: "16-18 €",
      mkt: 0.4,
      alamieName: 1,
      lamarName: 4,
      others1Name: 6,
      alamie: 0.158571429,
      lamar: 0.444857143,
      others1: 0.628571429,
      duracell: 21.4,
    },
    {
      name: "14-16 €",
      mkt: 0.1,
      alamieName: 2,
      lamarName: 2,
      others1Name: 4,
      alamie: 0.258571429,
      lamar: 0.444857143,
      others1: 0.228571429,
    },
    {
      name: "12-14 €",
      mkt: 0.2,
      alamieName: 1,
      lamarName: 3,
      others1Name: 4,
      alamie: 0.258571429,
      lamar: 0.444857143,
      others1: 0.228571429,
    },
    {
      name: "10-12 €",
      mkt: 1.9,
      alamieName: 8.4,
      lamarName: 3,
      others1Name: 4,
      alamie: 0.458571429,
      lamar: 0.444857143,
      others1: 0.228571429,
      duracell: 3.1,
    },
    {
      name: "8-10 €",
      mkt: 5,
      alamieName: 4,
      lamarName: 3,
      others1Name: 4,
      alamie: 0.458571429,
      lamar: 0.444857143,
      others1: 0.228571429,
    },
    {
      name: "6-8 €",
      mkt: 2,
      alamieName: 5,
      lamarName: 3,
      others1Name: 2,
      alamie: 0.458571429,
      lamar: 0.444857143,
      others1: 0.228571429,
    },
    {
      name: "4-6 €",
      mkt: 1,
      alamieName: 1,
      lamarName: 1,
      others1Name: 5,
      alamie: 0.458571429,
      lamar: 0.444857143,
      others1: 0.228571429,
    },
    {
      name: "2-4 €",
      mkt: 21,
      alamieName: 1,
      lamarName: 14,
      others1Name: 3,
      alamie: 0.458571429,
      lamar: 0.444857143,
      others1: 0.228571429,
      duracell: 18.5,
    },
    {
      name: "0-2 €",
      mkt: 45,
      alamieName: 1,
      lamarName: 43,
      others1Name: 3,
      alamie: 0.458571429,
      lamar: 0.444857143,
      others1: 0.228571429,
    },
  ];

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: maxHeight ? maxHeight : "100%" }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ minWidth: "80px" }}></StyledTableCell>
              <StyledTableCell>P3m Value Share (%) </StyledTableCell>
              <StyledTableCell>Duracell (%)</StyledTableCell>
              <StyledTableCell>Price Index</StyledTableCell>
              <StyledTableCell>
                Comparison Across Main Competition
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((a, idx) => {
              return (
                <StyledTableRow
                  sx={{
                    fontWeight: "bold",
                    border: a.name == "30-40" ? "8px solid #FBB8B8" : "",
                  }}
                  key={idx}
                >
                  <StyledTableCell> {a.name}</StyledTableCell>
                  <StyledTableCell> {a.mkt}</StyledTableCell>
                  <StyledTableCell>
                    {a.duracell ? (
                      <ProgressBar
                        bgcolor="#D9D9D9"
                        progressChild={a.duracell}
                        number={a.duracell}
                      />
                    ) : (
                      ""
                    )}
                  </StyledTableCell>
                  <StyledTableCell>X</StyledTableCell>
                  <StyledTableCell>
                    <MuliProgressBar
                      progress1={a.lamar * 100}
                      number1={a.lamarName}
                      progress2={a.alamie * 100}
                      number2={a.alamieName}
                      progress3={a.others1 * 100}
                      number3={a.others1Name}
                      bgcolor1="#00A097"
                      bgcolor2="#B2DFDC"
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
            <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "70px", display: "flex" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: "#00A097",
                      }}
                    ></Box>
                    araby
                  </Box>{" "}
                  <Box sx={{ width: "70px", display: "flex" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",

                        backgroundColor: "#B2DFDC",
                      }}
                    ></Box>{" "}
                    tayeb
                  </Box>{" "}
                  <Box sx={{ width: "50px", display: "flex" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",

                        backgroundColor: "#D9D9D9",
                      }}
                    ></Box>{" "}
                    Other
                  </Box>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default P3mValues;
