import {
  React,
  defineCustomElements,
  Grid,
  hierarchyTree,
  columnsGridTypes,
  createCheckboxCellTemplate,
  createColumns,
  createColumnsFinance,
  states,
  GroupBySection,
  SelectScenario,
  DefaultScenario,
  AddRemoveDecimal,
  SelectSegment,
  MainCalculation,
  SummaryGrid,
  AddScenario,
  ScenarioName,
  AttributesChanges,
  FinancialData,
  SelectedRow,
  BaseDefaultScenario,
  AddFinancialData,
  apiInstance,
  handleLessHandler,
  processGroupData,
  summaryConcatRowData,
  updateParentIds,
  updateSummaryDataAndRowData,
  updateTreeState,
  afterEditInitialData,
  afterEditModifiedData,
  applyChangedAttrPercentage,
  handleMoreDigit,
  handleChangedSOPLoping,
  updateColumnsHandler,
  ToastContainer,
  toastError,
  exportToExcel,
  groupDataByField,
  groupDataByField2,
  toast,
  toastSuccess,
  toastWarning,
  checkSkuObject,
  convertSegmentStringToObject,
  getObjectWantToModified,
  createColumnSummary,
} from "./utils/imports";

export default class GridSimulator extends React.Component {
  constructor(props) {
    super(props);
    this.square = { minHeight: 140 };
    this.setState = this.setState.bind(this);
    this.increased = [];
    this.decreased = [];
    this.increasedAttr = [];
    this.decreasedAttr = [];
    this.currentlySeleced = {};
    this.outputObject = {};

    this.onDrop = (files) => {
      this.setState({ files });
      this.setState({ isFileready: true });
    };
    this.initial_columns = createCheckboxCellTemplate(
      states(
        createColumnSummary,
        createColumns,
        createColumnsFinance,
        this.increased,
        this.decreased,
        this.increasedAttr,
        this.decreasedAttr,
        this.initial_columns
      ),
      this.setState,
      this.ChangedSOPRequest.bind(this),
      this.toggleRow.bind(this),
      this.increasedAttr,
      this.decreasedAttr,
      this.props.props
    );

    this.state = states(
      createColumnSummary,
      createColumns,
      createColumnsFinance,
      this.increased,
      this.decreased,
      this.increasedAttr,
      this.decreasedAttr,
      this.initial_columns
    );
    this.afterEdit = this.afterEdit.bind(this);
    this.beforeEditStart = this.beforeEditStart.bind(this);
    defineCustomElements();
    this.columnTypes = columnsGridTypes;

    this.onChangeStates = {
      scenarios: this.state.scenarios,
      rowData: this.state.rowData,
      rowData1: this.state.rowData1,
      currentGroup: this.state.currentGroup,
      grouping: this.state.grouping,
      finance_available: this.state.finance_available,
      scenario: this.state.currentscenario,
      columns: this.state.columns,
      columnsSummary: this.state.columnsSummary,
      scenario_state_redo: this.state.scenario_state_redo,
      scenario_state_undo: this.state.scenario_state_undo,
    };
  }

  onSelectItem(row) {
    if (row["checked"]) {
      this.currentlySeleced[row.id] = true;
    } else {
      delete this.currentlySeleced[row.id];
    }
    const ids = [];
    for (let i in this.currentlySeleced) {
      ids.push(i);
    }
  }
  sumForCompany(data, companyName) {
    let summedBaseSOP = 0;
    let summedBaseMarketShare = 0;
    let summedNewSOP = 0;
    let summedNewMarketShare = 0;
    for (const i in data) {
      if (data[i].Company === 1) {
        summedBaseSOP += data[i].base_sop;
        summedBaseMarketShare += data[i].base_market_share;
        summedNewSOP += data[i].new_sop;
        summedNewMarketShare += data[i].new_market_share;
      }
    }
    this.setState((prevState) => ({
      rowData1: [
        ...prevState.rowData1,
        {
          Brand: companyName,
          base_sop: summedBaseSOP,
          new_sop: summedNewSOP,
          base_market_share: summedBaseMarketShare,
          new_market_share: summedNewMarketShare,
        },
      ],
    }));
  }

  handleSummary(group, data, companyName, regroup = false) {
    let grouped = [];
    grouped = groupDataByField(data, group);
    this.setState({
      rowData1: grouped,
    });
    // this.handleChangedMarket(data, "summary");
    this.handleGrouping(group, data, grouped, regroup);
  }

  handleSummary2(group, data, parentId, length) {
    let grouped = [];
    grouped = groupDataByField2(data, group, parentId);
    for (const i in grouped) {
      grouped[i]["_id"] = length + parseInt(i) + 1;
      grouped[i]["SKU Name"] = grouped[i]["Brand"];
    }
    return grouped;
  }

  handleGrouping(group, rowData, summaryData, regroup = false, expand = false) {
    if (group.length !== 0) {
      if (!this.state.trimmedRowsEdited || regroup) {
        if (group.length === 1) {
          updateSummaryDataAndRowData(group, summaryData, rowData);
          const hashArr = summaryConcatRowData(summaryData, rowData);
          const tree = hierarchyTree(hashArr, 0, []);
          updateTreeState(tree, this.state.expandedAll, this.setState);
        } else {
          summaryData = processGroupData(
            group,
            summaryData,
            rowData,
            this.handleSummary2.bind(this)
          );
          updateParentIds(group, rowData, summaryData);
          for (const i in rowData) {
            rowData[i]["_id"] = parseInt(i) + summaryData.length + 1;
          }
          const hashArr = summaryConcatRowData(summaryData, rowData);
          const tree = hierarchyTree(hashArr, 0, []);
          updateTreeState(tree, this.state.expandedAll, this.setState);
        }
      } else if (expand) {
        if (group.length === 1) {
          updateSummaryDataAndRowData(group, summaryData, rowData);
        } else {
          summaryData = processGroupData(
            group,
            summaryData,
            rowData,
            this.handleSummary2.bind(this)
          );
          updateParentIds(group, rowData, summaryData);
        }
        for (const i in rowData) {
          rowData[i]["_id"] = parseInt(i) + summaryData.length + 1;
        }
        const hashArr = summaryConcatRowData(summaryData, rowData);
        const tree = hierarchyTree(hashArr, 0, []);
        updateTreeState(tree, this.state.expandedAll, this.setState);
      } else {
        updateSummaryDataAndRowData(group, summaryData, rowData);
        const hashArr = summaryConcatRowData(summaryData, rowData);
        const tree = hierarchyTree(hashArr, 0, []);
        updateTreeState(tree, this.state.expandedAll, this.setState);
      }
    } else {
      this.setState({
        trimmedRows: [],
        tree: this.state.rowData,
      });
    }
  }

  setScenario(e, { value }) {
    this.setState({
      isFetching: true,
      currentscenario: value,
    });
    apiInstance
      .get("/sim/get_scenario_by_name?", {
        params: {
          project_name: this.state.projectname,
          scenario_name: value,
        },
      })
      .then((res) => {
        this.setState({ rowData: res.data });
        toastSuccess("changed scenario successfully");
        // this.handleChangedMarket(res.data, "change secanrio");
        this.handleChangedSOP();
        this.handleSummary(
          this.state.currentGroup,
          res.data,
          this.state.companyName
        );
        this.setState({
          isFetching: false,
        });
        this.props.onChangeData(this.onChangeStates);
      })
      .catch((error) => {
        this.setState({ isFetching: false });
        toastError("OOPS something wrong happened");
      });
  }

  UpdateColumns(data) {
    let { cs, ic } = updateColumnsHandler(
      data,
      this.initial_columns,
      this.increasedAttr,
      this.decreased
    );
    this.setState({ columns: cs, inputColumns: ic });
  }

  beforeEdit(event) {
    if (Number(event.detail.val)) {
      return { ...event.detail, val: +event.detail.val };
    } else {
      return event.detail;
    }
  }

  beforeEditStart(event) {
    this.state.tree.forEach((ele) => {
      if (event.detail.model.hasOwnProperty("Brand")) {
        event.preventDefault();
      }
    });
    this.setState({ firstVal: event.detail.model[event.detail.prop] });
  }

  afterEdit(event) {
    this.setState({
      isFetching: true,
    });
    this.SKUs = [];
    let changed = "";
    let changed1 = "";
    const data = {};
    data["name"] = this.state.projectname;
    if (event.detail.prop === undefined) {
      afterEditInitialData(
        event,
        changed,
        changed1,
        data,
        this.setState,
        this.state.inputInvalid,
        this.handleChangedAttr.bind(this),
        this.ChangedSOPRequest.bind(this),
        this.state.scenario_state_undo
      );
    } else {
      afterEditModifiedData(
        event,
        changed1,
        this.state.firstVal,
        this.state.projectname,
        this.setState,
        this.handleChangedAttr.bind(this),
        this.ChangedSOPRequest.bind(this),
        this.state.scenario_state_undo,
        this.SKUs
      );
    }
  }

  async restoreDefault(e) {
    this.setState({
      isFetching: true,
    });

    const finalObject = {
      project_name: this.state.projectname,
      change_dict: null,
      new_products: null,
      segmentation: null,
    };

    try {
      const res = await apiInstance.post("/sim/scenario", finalObject);
      toastSuccess("Restore default successfully");
      this.props.setData({
        ...this.props.props,
        unSavedData: {},
      });

      this.setState(
        {
          rowData: Object.values(res.data),
          currentscenario: "",
          currentSegment: [],
          changedData: {},
        },
        () => {
          // this.handleChangedMarket(res.data, "restore default");
          this.handleChangedAttr();
          this.props.onChangeData(this.onChangeStates);
          this.handleSummary(
            this.state.currentGroup,
            Object.values(res.data),
            this.state.companyName
          );
        }
      );
      this.handleChangedSOP();
      this.setState({
        isFetching: false,
      });
    } catch (error) {
      toastError("oops something wrong happened");
      this.setState({ isFetching: false });
    }
  }

  async ChangedSOPRequest(data) {
    let ObjectWantToModified = getObjectWantToModified(data);
    const finalObject = {
      project_name: data.name,
      change_dict: { ...ObjectWantToModified },
      new_products: null,
      segmentation:
        this.state.currentSegment.length > 0
          ? convertSegmentStringToObject(this.state.currentSegment)
          : null,
    };
    const updatedChangeDict = checkSkuObject(
      finalObject,
      this.outputObject,
      data
    );

    finalObject["change_dict"] = updatedChangeDict || null;

    try {
      const res = await apiInstance.post("/sim/scenario", finalObject);
      toastSuccess("updated successfully, save it as scenario");

      this.props.setData({
        ...this.props.props,
        unSavedData: finalObject,
      });
      this.setState({
        isFetching: false,
        changedData: finalObject,
        rowData: res.data,
        editGroupAttrFlag: false,
        currentGroupAttrModal: "",
        currentSkusAttrModal: [],
        attrPercentage: 0,
      });

      // this.handleChangedMarket(res.data, "change values");
      this.handleChangedSOP();
      this.props.onChangeData(this.onChangeStates);
      this.handleSummary(
        this.state.currentGroup,
        Object.values(res.data),
        this.state.companyName
      );
      // this.handleChangedAttr(Object.keys(data)[1]);
    } catch (error) {
      console.log(error);
      this.setState({ isFetching: false });
      toastError("oops something wrong happened");
    }
  }

  handleChangedSOP() {
    this.increased = [];
    this.decreased = [];
    handleChangedSOPLoping(
      this.state.rowData,
      this.decreased,
      this.increased,
      this.state.rowData1
    );
  }

  handleChangedAttr(attr) {
    this.increasedAttr = [];
    this.decreasedAttr = [];
    if (attr != null) {
      if (attr.includes("_")) {
        for (var i = 0; i < this.state.rowData.length; i++) {
          if (
            this.state.rowData[i][attr] <
            this.state.rowData[i][attr.replace("new_", "base_")]
          ) {
            this.decreasedAttr.push(this.state.rowData[i]["SKU Name"]);
          } else {
            if (
              this.state.rowData[i][attr] >
              this.state.rowData[i][attr.replace("new_", "base_")]
            ) {
              this.increasedAttr.push(this.state.rowData[i]["SKU Name"]);
            }
          }
        }
      } else {
        for (i = 0; i < this.state.rowData.length; i++) {
          if (
            this.state.rowData[i]["Base " + attr] >
            this.state.rowData[i]["New " + attr]
          ) {
            this.decreasedAttr.push(this.state.rowData[i]["SKU Name"]);
          } else {
            if (
              this.state.rowData[i]["Base " + attr] <
              this.state.rowData[i]["New " + attr]
            ) {
              this.increasedAttr.push(this.state.rowData[i]["SKU Name"]);
            }
          }
        }
      }
    }
  }

  handleApplySegment() {
    this.setState({ isFetching: true });
    const data = {};
    data["name"] = this.state.projectname;
    data["segments"] = this.state.currentSegment;
    this.ChangedSOPRequest(data);
  }

  handleSegmentChange(e, { value }) {
    this.setState({ currentSegment: value });
  }
  handlegroups(e, { value }) {
    this.setState({ currentGroup: value });
    this.handleSummary(value, this.state.rowData, this.state.companyName, true);
    this.setState({
      grouping: {
        props: value,
        expandedAll: true,
      },
    });
    this.props.onChangeData(this.onChangeStates);
  }

  handleGroupsAttrModal(e, { value }) {
    this.setState({ currentGroupAttrModal: value });
    let newSKU = [];
    for (let x in this.state.rowData) {
      newSKU.push(this.state.rowData[x][value]);
    }
    newSKU = [...new Set(newSKU)];
    let newSKU1 = [];
    for (let x in newSKU) {
      newSKU1.push({ key: newSKU[x], value: newSKU[x], text: newSKU[x] });
    }
    this.setState({ skusAttrModal: newSKU1 });
  }

  handleSKUAttrModal(e, { value }) {
    this.setState({ currentSkusAttrModal: value });
  }

  handleAttrAttrModal(e, { value }) {
    this.setState({ currentAttrModal: value });
  }

  applyChangedAttrPercentage() {
    let rowData = this.state.rowData;
    let data = {};
    data[this.state.currentAttrModal] = {};
    data["name"] = this.state.projectname;

    applyChangedAttrPercentage(
      this.state.rowData,
      this.state.currentSkusAttrModal,
      this.state.currentGroup,
      this.state.currentAttrModal,
      this.state.attrPercentage,
      this.setState,
      data
    );
    if (!this.state.inputInvalid) {
      const objectOrder = {};
      objectOrder["name"] = null;
      data = Object.assign(objectOrder, data);
      this.setState({ rowData: rowData, isFetching: true }, () => {
        this.setState({
          scenario_state_undo: [...this.state.scenario_state_undo, data],
        });
        this.ChangedSOPRequest(data);
        this.handleChangedAttr(this.state.currentAttrModal);
      });
      // this.setState({ editGroupAttrFlag: false });
    }
  }

  handleMore() {
    let updatedColumns = handleMoreDigit(this.state.columns);
    this.setState({ columns: updatedColumns });
  }

  handleLess() {
    let newUpdatedColumns = handleLessHandler(this.state.columns);
    this.setState({ columns: newUpdatedColumns });
  }
  handleFinancial(e) {
    this.setState({ isUploading: true });
    const formData = new FormData();
    formData.append("myFile", this.state.files[0], this.state.files[0].name);
    formData.append("name", this.state.projectname);
    apiInstance
      .post("/sop/uploadfinancial", formData)
      .then((res) => {
        if (Object.keys(res.data["data"][0]).includes("base_vat")) {
          this.setState({
            rowData: res.data["data"],
            finance_available: true,
            addFinancial: false,
          });
          this.props.onChangeData(this.onChangeStates);
        }
        this.setState({
          files: [],
          isFileready: false,
        });
        this.setState({ isUploading: false, addScenario: false });
      })
      .catch((error) => {
        this.setState({ isFetching: false });
      });
  }

  async handleChangeBase(e) {
    this.setState({ isFetching: true });

    try {
      const res = await apiInstance.post(
        "/sim/change_base_scenario",
        this.state.changedData
      );
      console.log(res.data);
      if (res) {
        this.setState({ rowData: res.data, changedData: {} });
        this.props.setData({
          ...this.props.props,
          unSavedData: {},
        });
        // this.handleChangedMarket(res.data, "base sceanrio");
        this.handleSummary(
          this.state.currentGroup,
          res.data,
          this.state.companyName
        );
        this.handleChangedSOP();
        this.props.onChangeData(this.onChangeStates);
        this.setState({
          isFetching: false,
        });
        this.setState({ scenario_state_redo: [], scenario_state_undo: [] });
        toastSuccess(res.data);
      }
    } catch (error) {
      console.log(error);
      toastError("oops something wring happened!");
      this.setState({ isFetching: false });
    }
  }

  handleScenario(e) {
    this.setState({ isUploading: true });
    const formData = new FormData();
    console.log(this.state.files[0], this.state.files[0].name);
    formData.append("myFile", this.state.files[0], this.state.files[0].name);
    formData.append("name", this.state.projectname);
    apiInstance
      .post("/sop/uploadscenario", formData)
      .then((res) => {
        this.setState({
          scenarios: Object.values(res.data)[0]["scenarios"],
        });
        this.props.onChangeData(this.onChangeStates);
      })
      .catch((error) => {
        toastError("something wrong happened");
        setTimeout(() => {
          this.setState({
            addScenario: false,
            isFileready: false,
          });
        }, 2000);
      });

    this.setState({
      isFetching: false,
      files: [],
      isUploading: false,
    });
  }
  exportCurrentScenario() {
    apiInstance
      .post("/sop/savescenario", this.state, { responseType: "blob" })
      .then(({ data }) => {
        exportToExcel(data, `${this.savedCurrentScenario}.xlsx`);
      })
      .catch((error) => {
        this.setState({ isFetching: false });
      });
  }

  async exportFinancialTemplate() {
    try {
      const { data } = await apiInstance.post(
        "/sop/exportfinance",
        this.state.rowData,
        {
          responseType: "blob",
        }
      );
      exportToExcel(data, "financial.xlsx");
    } catch (error) {
      this.setState({ isFetching: false });
    }
  }

  componentDidMount() {
    this.setState({
      segments: this.props.props.segments,
      rowData: this.props.props.rowData,
      type: this.props.props.type,
      groups: this.props.props.groups,
      currentGroup: this.props.props.currentGroup,
      companyName: this.props.props.companyName,
      companyLocation: this.props.props.companyLocation,
      columns: this.props.props.columns,
      options: this.props.props.options,
      numeric_columns: this.props.props.numeric_columns,
      new_products_options: this.props.props.new_products_options,
      scenarios: this.props.props.scenarios,
      currentscenario: this.props.props.currentscenario,
      finance_available: this.props.props.finance_available,
      scenario_state_redo: this.props.props.scenario_state_redo,
      scenario_state_undo: this.props.props.scenario_state_undo,
    });

    this.handleSummary(
      this.props.props.currentGroup,
      this.props.props.rowData,
      this.props.props.companyName
    );
    this.setState(
      {
        projectname: this.props.props.projectname,
        grouping: {
          props: [this.props.props.currentGroup],
          expandedAll: true,
        },
      },
      () => {
        this.updateChangeableAttrs();
        // this.handleChangedMarket(this.props.props.rowData, "mount");
        this.handleChangedSOP();
      }
    );
    this.UpdateColumns({
      type: this.props.props.type,
      options: this.props.props.options,
    });
  }

  toggleRow = (e) => {
    var rowId = e.target.getAttribute("dataid");
    const grid = e.target.closest("revo-grid");
    const row = grid.source.find((r) => r._id.toString() === rowId);
    row.expanded = !row.expanded;
    this.updateChildrenVisibility(grid, row);
    const trimmedRows = {};
    for (let i = 0; i < grid.source.length; i++) {
      if (!grid.source[i].visible) {
        trimmedRows[i] = true;
      }
    }
    grid.trimmedRows = trimmedRows;
    this.setState({ trimmedRows: trimmedRows });
  };

  updateChildrenVisibility = (grid, row) => {
    for (const childId of row.children) {
      const child = grid.source.find((r) => r._id === childId);
      child.visible = row.expanded && row.visible;
      if (child.children) {
        this.updateChildrenVisibility(grid, child);
      }
    }
  };

  updateChangeableAttrs() {
    /* this is wrtiten to price and size*/
    let attrs = [];
    // for (let x in Object.keys(this.state.rowData[0])) {
    //   if (Object.keys(this.state.rowData[0])[x].includes("new")) {
    //     attrs.push({
    //       key: Object.keys(this.state.rowData[0])[x].replace("new", " "),
    //       value: Object.keys(this.state.rowData[0])[x].replace("new", " "),
    //       text: Object.keys(this.state.rowData[0])[x].replace("new", " "),
    //     });
    //   }
    // }
    this.setState({ attrsAttrModal: attrs });
  }

  summaryClick() {
    if (this.state.summaryToggle === false) {
      this.setState({ summaryToggle: true });
      this.setState({ summaryToggleCol: 10 });
    } else {
      this.setState({ summaryToggle: false });
      this.setState({ summaryToggleCol: 14 });
    }
  }

  handleintroduction() {
    const data = this.state.tree;
    if (this.state.introduction) {
      let trimmedRows = {};
      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        if (row["Market Share"] === 0) {
          trimmedRows[i] = true;
        }
      }

      this.setState({ trimmedRows: trimmedRows, introduction: false });
    } else {
      this.handleGrouping(
        this.state.currentGroup,
        this.state.rowData,
        this.state.rowData1,
        false,
        true
      );
      this.setState({ introduction: true });
    }
  }
  financeMinimize() {
    if (this.state.finance_available) {
      if (this.state.financeToggle === false) {
        this.setState({ financeToggle: true });
        let cs = [...this.initial_columns];
        let inCols = [...this.state.inputColumns];
        const cols = cs.concat(inCols);
        this.setState({ columns: cols });
      } else {
        this.setState({ financeToggle: false });
        let cs = [...this.state.columns];
        let finCols = [...this.state.columnsFinance];
        const cols = cs.concat(finCols);
        this.setState({ columns: cols });
      }
    } else {
      this.setState({ addFinancialModal: true });
    }
  }

  saveScenario() {
    this.setState({
      savingScenario: true,
    });
    const scenarioName =
      this.state.projectname + " " + this.state.currentscenario;
    let mappedScenarioName = {
      keys: scenarioName,
      text: scenarioName,
      value: scenarioName,
    };

    if (this.state.changedData["change_dict"]) {
      const scenarioObject = {
        ...this.state.changedData,
        scenario_name: this.state.currentscenario,
      };
      apiInstance
        .post("/sim/save_scenario_to_db", scenarioObject)
        .then((res) => {
          if (res.data == "Saved") {
            toastSuccess("scenario saved successfully");
            this.setState({
              scenarios: [...this.state.scenarios, mappedScenarioName],
              scenarioInput: false,
              unSavedData: {},
              currentscenario: scenarioName,
            });
          }
          if (res.data == "Duplicated") {
            toast.warning("OOPS Duplicated Scenario!");
          }
          this.setState({
            savingScenario: false,
          });
          this.props.onChangeData(this.onChangeStates);
        })
        .catch((error) => {
          this.setState({
            savingScenario: false,
          });
          if (error) {
            toastError("something wrong happened");
            setTimeout(() => {
              this.setState({ scenarioInput: false });
            }, 2000);
          }
          this.setState({ isFetching: false });
        });
    } else {
      toastError("please do some update first!");
      this.setState({
        savingScenario: false,
      });
      setTimeout(() => {
        this.setState({
          scenarioInput: false,
        });
      }, 2000);
    }
  }

  renderLabel = (label) => ({
    color: "blue",
    content: `${label.text}`,
    icon: "check",
  });

  renderLabel1 = (label) => ({
    color: "red",
    content: `${label.text}`,
    icon: "check",
  });

  handleUndo() {
    this.setState({
      isFetching: true,
    });
    const data = this.state.scenario_state_undo.pop();
    const data_request =
      this.state.scenario_state_undo[this.state.scenario_state_undo.length - 1];
    this.setState({
      scenario_state_redo: [...this.state.scenario_state_redo, data],
    });
    if (data_request !== undefined) {
      this.ChangedSOPRequest(data_request);
      this.handleChangedAttr(Object.keys(data_request)[1]);
    } else {
      this.restoreDefault();
      this.handleChangedAttr(Object.keys(data)[1]);
    }
  }

  handleRedo() {
    this.setState({
      isFetching: true,
    });
    const data = this.state.scenario_state_redo.pop();
    this.setState({
      scenario_state_undo: [...this.state.scenario_state_undo, data],
    });

    this.ChangedSOPRequest(data);
    this.handleChangedAttr(Object.keys(data)[1]);
  }
  handleSearchChange = (e, { searchQuery }) => this.setState({ searchQuery });

  render() {
    const { multiple, search } = this.state;
    return (
      <div>
        <ToastContainer />
        <Grid container spacing={1}>
          <Grid container spacing={1} columns={15}>
            <Grid item xs={7} md={3}>
              <GroupBySection
                square={this.square}
                search={search}
                multiple={multiple}
                groups={this.state.groups}
                currentGroup={this.state.currentGroup}
                handlegroups={this.handlegroups.bind(this)}
                handleSearchChange={this.handleSearchChange}
                renderLabel={this.renderLabel}
              />
            </Grid>
            <Grid item xs={8} md={3}>
              <SelectScenario
                isFetching={this.state.isFetching}
                square={this.square}
                scenarios={this.state.scenarios}
                currentscenario={this.state.currentscenario}
                setScenario={this.setScenario.bind(this)}
                disabled={this.state.isFetching}
              />
            </Grid>
            <Grid item xs={7} md={3}>
              <DefaultScenario
                currentscenario={this.state.currentscenario}
                isFetching={this.state.isFetching}
                square={this.square}
                restoreDefault={this.restoreDefault.bind(this)}
                setState={this.setState.bind(this)}
              />
            </Grid>
            <Grid item xs={8} md={3}>
              <AddRemoveDecimal
                isFetching={this.state.isFetching}
                square={this.square}
                handleLess={this.handleLess.bind(this)}
                handleMore={this.handleMore.bind(this)}
              />
            </Grid>
            <Grid item xs={15} md={3}>
              <SelectSegment
                isFetching={this.state.isFetching}
                segments={this.state.segments}
                currentSegment={this.state.currentSegment}
                renderLabel1={this.renderLabel}
                handleSearchChange={this.handleSearchChange}
                handleSegmentChange={this.handleSegmentChange.bind(this)}
                handleApplySegment={this.handleApplySegment.bind(this)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MainCalculation
              state={{
                isFetching: this.state.isFetching,
                scenario_state_redo: this.state.scenario_state_redo,
                scenario_state_undo: this.state.scenario_state_undo,
                expandedAll: this.state.expandedAll,
                currentGroup: this.state.currentGroup,
                rowData: this.state.rowData,
                rowData1: this.state.rowData1,
                columns: this.state.columns,
                columnTypes: this.columnTypes,
                tree: this.state.tree,
                trimmedRows: this.state.trimmedRows,
                changedData: this.state.changedData,
                newSelectedColumns: this.state.newSelectedColumns,
              }}
              functions={{
                handleChangeBase: this.handleChangeBase.bind(this),
                setState: this.setState,
                handleRedo: this.handleRedo.bind(this),
                handleUndo: this.handleUndo.bind(this),
                exportCurrentScenario: this.exportCurrentScenario.bind(this),
                handleGrouping: this.handleGrouping.bind(this),
                financeMinimize: this.financeMinimize.bind(this),
                handleintroduction: this.handleintroduction.bind(this),
                afterEdit: this.afterEdit.bind(this),
                beforeEditStart: this.beforeEditStart.bind(this),
                beforeEdit: this.beforeEdit.bind(this),
              }}
              columnTypes={this.columnTypes}
            />
          </Grid>
        </Grid>

        <SummaryGrid
          isFetching={this.state.isFetching}
          columnsSummary={this.state.columnsSummary}
          columnTypes={this.columnTypes}
          rowData1={this.state.rowData1}
          summaryToggle={this.state.summaryToggle}
          summaryClick={this.summaryClick.bind(this)}
        />
        <AddScenario
          addScenario={this.state.addScenario}
          setState={this.setState}
          isFileready={this.state.isFileready}
          isUploading={this.state.isUploading}
          onDrop={this.onDrop.bind(this)}
          files={this.state.files}
          handleScenario={this.handleScenario.bind(this)}
        />
        <ScenarioName
          setState={this.setState}
          savingScenario={this.state.savingScenario}
          currentscenario={this.state.currentscenario}
          saveScenario={this.saveScenario.bind(this)}
          scenarioInput={this.state.scenarioInput}
        />
        <AttributesChanges
          state={{
            editGroupAttrFlag: this.state.editGroupAttrFlag,
            groups: this.state.groups,
            currentGroupAttrModal: this.state.currentGroupAttrModal,
            skusAttrModal: this.state.skusAttrModal,
            currentSkusAttrModal: this.state.currentSkusAttrModal,
            attrsAttrModal: this.state.attrsAttrModal,
            currentAttrModal: this.state.currentAttrModal,
            attrPercentage: this.state.attrPercentage,
            isFetching: this.state.isFetching,
            search: this.state.search,
            multiple: this.state.multiple,
            options: this.state.options,
            numeric_columns: this.props.props.numeric_columns,
          }}
          functions={{
            handleGroupsAttrModal: this.handleGroupsAttrModal.bind(this),
            handleSKUAttrModal: this.handleSKUAttrModal.bind(this),
            handleSearchChange: this.handleSearchChange.bind(this),
            handleAttrAttrModal: this.handleAttrAttrModal.bind(this),
            applyChangedAttrPercentage:
              this.applyChangedAttrPercentage.bind(this),
            setState: this.setState,
          }}
          renderLabel={this.renderLabel}
        />
        <FinancialData
          addFinancialModal={this.state.addFinancialModal}
          setState={this.setState}
          exportFinancialTemplate={this.exportFinancialTemplate.bind(this)}
        />
        <SelectedRow
          setState={this.setState}
          inputInvalid={this.state.inputInvalid}
          invalidMin={this.state.invalidMin}
          invalidMax={this.state.invalidMax}
          invalidSKU={this.state.invalidSKU}
          wd={this.state.wd}
        />
        <BaseDefaultScenario
          setState={this.setState}
          hints={this.state.hints}
        />
        <AddFinancialData
          setState={this.setState}
          addFinancial={this.state.addFinancial}
          files={this.state.files}
          isFileready={this.state.isFileready}
          onDrop={this.onDrop}
          handleFinancial={this.handleFinancial.bind(this)}
          isUploading={this.state.isUploading}
        />
      </div>
    );
  }
}
