import { Box } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function SyncChart() {
  const [options, setOptions] = useState({
    chart: {
      type: "area",
      stacked: true,
      height: 300,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    markers: {
      size: 5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#8e8da4",
        },
        offsetX: 0,
        formatter: function (val) {
          return (val / 1000000).toFixed(2);
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      tickAmount: 8,
      min: new Date("01/01/2022").getTime(),
      max: new Date("01/12/2022").getTime(),
      labels: {
        rotate: -15,
        rotateAlways: true,
        formatter: function (val, timestamp) {
          return moment(new Date(timestamp)).format("DD MMM YYYY");
        },
      },
    },
    yaxis: {
      title: {
        text: "Monthly Brand Share",
      },
    },

    tooltip: {
      shared: true,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetX: -10,
    },
  });

  const [series, setSeries] = useState([
    {
      name: "PRODUCT A",
      data: [
        [new Date("01/02/2022").getTime(), 20],
        [new Date("01/05/2022").getTime(), 25],
        [new Date("01/12/2022").getTime(), 18],
      ],
    },
    {
      name: "PRODUCT B",
      data: [
        [new Date("01/02/2022").getTime(), 15],
        [new Date("01/07/2022").getTime(), 30],
        [new Date("01/11/2022").getTime(), 22],
      ],
    },
    {
      name: "PRODUCT C",
      data: [
        [new Date("01/03/2022").getTime(), 30],
        [new Date("01/09/2022").getTime(), 35],
        [new Date("01/10/2022").getTime(), 28],
        // Add your data points here
      ],
    },
  ]);

  return (
    <Box sx={{ p: 2 }}>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={325}
      />
    </Box>
  );
}

export default SyncChart;
