import React, { useState, useEffect } from "react";
import TableData from "../../Shared/table/TableData";

function SegmentTable() {
  const tableData = [
    {
      index: "Total Category",
      valueSales: 14508,
      changeYA: 85,
      priceIdx: 89,
      volumeShare: 10542,
      volumeChangeYA: 80,
      volumePriceIdx: 88,
    },
    {
      index: "Block",
      valueSales: 1600,
      changeYA: 90,
      priceIdx: 78,
      volumeShare: 2541,
      volumeChangeYA: 57,
      volumePriceIdx: 19,
    },
    {
      index: "Cooking",
      valueSales: 2314,
      changeYA: 58,
      priceIdx: 97,
      volumeShare: 2578,
      volumeChangeYA: 78,
      volumePriceIdx: 97,
    },
    {
      index: "Grated",
      valueSales: 3214,
      changeYA: 45,
      priceIdx: 85,
      volumeShare: 2357,
      volumeChangeYA: 54,
      volumePriceIdx: 98,
    },
    {
      index: "Spreadable",
      valueSales: 3698,
      changeYA: 68,
      priceIdx: 58,
      volumeShare: 2598,
      volumeChangeYA: 83,
      volumePriceIdx: 70,
    },
    {
      index: "Snacking",
      valueSales: 4217,
      changeYA: 82,
      priceIdx: 30,
      volumeShare: 2478,
      volumeChangeYA: 25,
      volumePriceIdx: 38,
    },
    {
      index: "Sliced",
      valueSales: 5210,
      changeYA: 82,
      priceIdx: 39,
      volumeShare: 3891,
      volumeChangeYA: 64,
      volumePriceIdx: 56,
    },
    {
      index: "Indulgence",
      valueSales: 4215,
      changeYA: 96,
      priceIdx: 30,
      volumeShare: 1479,
      volumeChangeYA: 60,
      volumePriceIdx: 37,
    },
  ];
  const tableConfig = [
    {
      label: "",
      render: (segmentData) => segmentData.index,
    },
    {
      label: "Value Sales",
      render: (segmentData) => segmentData.valueSales + " $",
    },
    {
      label: "Change YA",
      render: (segmentData) => segmentData.changeYA + " %",
    },
    {
      label: "Price Idx",
      render: (segmentData) => segmentData.priceIdx,
    },
    {
      label: "Volume Share",
      render: (segmentData) => segmentData.volumeShare + " $",
    },
    {
      label: "Change YA",
      render: (segmentData) => segmentData.volumeChangeYA + " %",
    },
    {
      label: "Price Idx",
      render: (segmentData) => segmentData.volumePriceIdx,
    },
  ];

  const [segmentTable, setSegmentTable] = useState({
    tableData,
    tableConfig,
  });

  return <TableData tableData={segmentTable} />;
}

export default SegmentTable;
