import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  Paper,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTitleTypography } from "../../Styled/Typography";
import Select from "@mui/material/Select";
import { PriceElasticity } from "../../Components/Chart";
import { MenuItem } from "@mui/material";
import { getProjectData } from "../../Components/Network/Lib/Simulator";
import { SwitchingBehaviour } from "../../Components/Table";
import LabTabs from "../../Components/Tabs";
import Trade from "../../Components/PricingAndPPA/Trade/Trade";
import Consumer from "../../Components/PricingAndPPA/Consumer/Consumer";
import Insights from "../../Components/PricingAndPPA/Insights/Insights";

function PricingAndPPA() {
  const [sku, setSku] = useState(["Afia corn 0.8 L"]);
  const [selectSkus, setSelectSkus] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [brandSwitchingSKU, setBrandSwitchingSKU] = React.useState([]);

  useEffect(() => {
    getProjectData({
      name: "savola smart shopper oil category traditional",
    }).then((response) => {
      const skus = [];
      response.data.data.forEach((item) => {
        skus.push(item["SKU Name"]);
      });
      setRowData(response.data.data);
      setSku(skus);
    });
  }, []);

  const tabs = ["Trade", "Consumer", "Insights"];
  const navigateTabs = tabs.map((ele, idx) => {
    if (ele == "Trade") {
      return <Trade />;
    }
    if (ele == "Consumer") {
      return <Consumer />;
    }
    if (ele == "Insights") {
      return <Insights />;
    }
  });

  return (
    <>
      <LabTabs tabs={tabs} navigateTabs={navigateTabs} />

      {/* <Grid container spacing={1} sx={{ alignItems: "baseline" }}>
        <Grid item xs={6}>
          <Paper>
            <Stack
              sx={{
                pl: 3,
                pt: 1,
                px: 3,
              }}
              direction="row"
              alignItems="Center"
              justifyContent="space-between"
            >
              <StyledTitleTypography>Price Elasticity</StyledTitleTypography>
              <Box minWidth={150}>
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      fontSize: "12px",
                      paddingBottom: "10px",
                      overflow: "initial",
                      lineHeight: ".5",
                    }}
                    id="demo-simple-select-label"
                  >
                    Sku
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectSkus}
                    label="Sku"
                    onChange={(event) => setSelectSkus(event.target.value)}
                    sx={{
                      maxWidth: 250,
                      "& .MuiSelect-select": {
                        padding: "8px",
                        height: "36px",
                      },
                      "& .MuiMenuItem-root": {
                        height: "36px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      },
                    }}
                  >
                    {sku.map((row, idx) => (
                      <MenuItem value={row}>{row}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <Box
              sx={{
                fontSize: "12px",
                mb: "2px",
                color: "#6D6D6D",
                ml: "22px",
              }}
            >
              Understand changes in demand by analyzing how consumers react to
              variations in price points.
            </Box>
            <PriceElasticity
              key={selectSkus}
              sku={selectSkus}
              projectName="savola smart shopper oil category traditional"
            />
          </Paper>
        </Grid>
        <Grid xs={6}>
          <Paper sx={{ ml: "5px", minHeight: "525px" }}>
            <Stack
              sx={{
                pl: 3,
                pt: 1,
                px: 3,
              }}
              direction="row"
              alignItems="Center"
              justifyContent="space-between"
            >
              <StyledTitleTypography>Brand Switching</StyledTitleTypography>
              <Box minWidth={150}>
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      fontSize: "12px",
                      paddingBottom: "10px",
                      overflow: "initial",
                      lineHeight: ".5",
                    }}
                    id="demo-simple-select-label"
                  >
                    Sku
                  </InputLabel>
                  <Select
                    multiple
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectSkus}
                    label="Sku"
                    onChange={(event) => setSelectSkus(event.target.value)}
                    sx={{
                      maxWidth: 250,
                      "& .MuiSelect-select": {
                        padding: "8px",
                        height: "36px",
                      },
                      "& .MuiMenuItem-root": {
                        height: "36px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      },
                    }}
                  >
                    {sku.map((row, idx) => (
                      <MenuItem value={row}>{row}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <Box
              sx={{
                fontSize: "12px",
                mb: "2px",
                color: "#6D6D6D",
                ml: "22px",
              }}
            >
              Discover the key to building a loyal customer base by learning the
              reasons behind changes in consumer buying habits.
            </Box>

            <SwitchingBehaviour brandSwitchingSKU={brandSwitchingSKU} />
          </Paper>
        </Grid>
      </Grid> */}
    </>
  );
}

export default PricingAndPPA;
