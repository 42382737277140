import React, { useRef, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import TableData from "../../../Components/Shared/table/TableData";
import { ProgressBar } from "../../../Components/Progress";
import DropDown from "../utils/DropDown";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

function ValueTableComponent({ totalMarket }) {
  const tableData = totalMarket?.map((ele) => {
    return { ...ele, totalCategory: "Total category" };
  });

  const tableConfig = [
    {
      label: "Value",
      render: (valueData) => valueData.totalCategory,
    },
    {
      label: "",
      render: (valueData) => (valueData.value_sales/10000).toFixed(2) + " $",
    },
    {
      label: "Growth",
      render: (valueData) => (
        <Box
          sx={{
            color: valueData.value_sales_growth < 1 ? "red" : "green",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {valueData.value_sales_growth < 1 ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {(valueData.value_sales_growth/100000).toFixed(2)}
              <SouthIcon />
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {(valueData.value_sales_growth/10000000000000000).toFixed(2)}
              <NorthIcon />
            </Box>
          )}
        </Box>
      ),
      header: () => <DropDown />,
    },
  ];

  const ValueTable = {
    tableData,
    tableConfig,
  };

  return <TableData tableData={ValueTable} />;
}

export default ValueTableComponent;
