import { Grid } from "@mui/material";
import React from "react";
import P3mValues from "../../MarketLandscape/share/P3mValues";
import RelativePriceIndexChart from "./RelativePriceIndexChart";
import AvgPriceIndexChart from "./AvgPriceIndexChart";

function Trade() {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <RelativePriceIndexChart />
        </Grid>
        <Grid item xs={6}>
          <AvgPriceIndexChart />
        </Grid>
        <Grid item xs={6}>
          <P3mValues />
        </Grid>
        <Grid item xs={6}>
          <P3mValues />
        </Grid>
      </Grid>
    </>
  );
}

export default Trade;
