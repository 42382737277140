import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";
import background from "../Assets/Mask_group.png";
import footer from "../Assets/footer.png";

export const StyledHeaderCover = styled(Stack)(({ theme }) => ({
  backgroundImage: `url(${background})`,
  height: "100vh",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledServiceCard = styled(Stack)(({ theme }) => ({
  height: "auto",
  width: "auto",
  backgroundColor: `${theme.palette.secondary.main}`,
}));

//footer
export const StyledFooterCover = styled(Stack)(({ theme }) => ({
  backgroundImage: `url(${footer})`,
  paddingTop: "20px",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledSocailMedia = styled(Stack)(({ theme }) => ({
  height: "40px",
  width: "40px",
  backgroundColor: `${theme.palette.secondary.main}`,
  borderRadius: "100%",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  color: `${theme.palette.primary.main}`,
}));

//Modules

export const StyledModules = styled(Stack)(({ theme }) => ({
  backgroundColor: `${theme.palette.secondary.dark}`,
  minHeight: "calc(100vh - 55px)",
  backgroundSize: "cover",
}));


export const StyledModulesCard = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.secondary.main}`

}));


