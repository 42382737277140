import { ListItem, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { List } from "@mui/material";
import opportunitiesImage from "../../Assets/opp.svg";
import gapImage from "../../Assets/gap.svg";
import { ResponsiveDialog,ResponsiveDialog1,ResponsiveDialog2,ResponsiveDialog3,ResponsiveDialog4, ResponsiveDialogTwoTable,ResponsiveDialogTwoTable1,
  ResponsiveDialogTwoTable2,ResponsiveDialogTwoTable3,ResponsiveDialogTwoTableG1,
  ResponsiveDialogTwoTableG2 ,ResponsiveDialogTwoTableG3,ResponsiveDialogG4,ResponsiveDialogG5,ResponsiveDialogG6} from "../Popup";
import { theme } from "../../Theme";

export const Opportunities = () => {
  return (
    <>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          // height: "9rem",
        }}
      >
        <Stack direction="row" alignItems="center">
          <Box
            component="img"
            src={opportunitiesImage}
            sx={{ width: "1.7rem" }}
          />
          <Typography sx={{ fontWeight: "bold" }}>
            <Typography
              component="span"
              sx={{ color: "#41DD64", fontWeight: "bold" }}
            >
              {" "}
              Opportunities
            </Typography>{" "}
          </Typography>
        </Stack>
        {/* <Box sx={{ fontSize: "0.8rem", m: 2, color: "#CCCBCB" }}>
         Criteria used :
         <Box component="ul" sx={{ml:2}}>
          <Box component="li">Manufacturer Growth IYA &lt; Market Growth IYA </Box>
          </Box>
        </Box> */}

        <List
          aria-labelledby="decorated-list-demo"
          sx={{ "--ListItemDecorator-size": "32px" }}
        >
          {/* 1 */}
          <ListItem>
            <ResponsiveDialogTwoTable1
              text={
                <Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: `${theme.palette.primary.light}`,
                      fontWeight: "bold",
                    }}
                  >
                    Manufacturer:
                  </Typography>{" "}
                  The top opportunity here for investment was{" "}
                  <Typography
                    component="span"
                    sx={{
                      color: `#41DD64`,
                      fontWeight: "bold",
                    }}
                  >
                    PALM & VEGETABLE OIL
                  </Typography>{" "}
                  Although SUNFLOWER OIL, CORN OIL has increased its volume
                  sales over the past year
                </Typography>
              }
              labels={[
                {
                  brand: "Total Category",
                  valueShare: 54.335,
                  valueShareDYA: 109,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 77.03,
                  valueShareDYA: 117,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 55.31,
                  valueShareDYA: 105,
                },
                { brand: "CORN OIL", valueShare: 46.7, valueShareDYA: 110 },
                { brand: " BLEND OIL", valueShare: 29.42, valueShareDYA: 111 },
                { brand: " CANOLA OIL", valueShare: 71.4, valueShareDYA: 100 },
              ]}
              MFC={[
                {
                  brand: "Total Category",
                  valueShare: 16.029,
                  valueShareDYA: 102,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 15.4,
                  valueShareDYA: 110,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 21.7,
                  valueShareDYA: 107,
                },
                { brand: "CORN OIL", valueShare: 18.77, valueShareDYA: 103 },
                { brand: " BLEND OIL", valueShare: 32.6, valueShareDYA: 96 },
                { brand: " CANOLA OIL", valueShare: 25.1, valueShareDYA: 94 },
              ]}
            />
          </ListItem>

          {/* 2 */}
          <ListItem>
            <ResponsiveDialogTwoTable2
              text={
                <Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: `${theme.palette.primary.light}`,
                      fontWeight: "bold",
                    }}
                  >
                    AFIA:
                  </Typography>{" "}
                  The top opportunities here for investment were{" "}
                  <Typography
                    component="span"
                    sx={{
                      color: `#41DD64`,
                      fontWeight: "bold",
                    }}
                  >
                    PALM & VEGETABLE OIL and CORN OIL.
                  </Typography>{" "}
                </Typography>
              }
              labels={[
                {
                  brand: "Total Category",
                  valueShare: 54.335,
                  valueShareDYA: 109,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 77.03,
                  valueShareDYA: 117,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 55.31,
                  valueShareDYA: 105,
                },
                { brand: "CORN OIL", valueShare: 46.7, valueShareDYA: 110 },
                { brand: " BLEND OIL", valueShare: 29.42, valueShareDYA: 111 },
                { brand: " CANOLA OIL", valueShare: 71.4, valueShareDYA: 100 },
              ]}
              MFC={[
                {
                  brand: "Total Category",
                  valueShare: 70.29,
                  valueShareDYA: 103,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 10.4,
                  valueShareDYA: 98,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 11.78,
                  valueShareDYA: 105,
                },
                { brand: "CORN OIL", valueShare: 19.77, valueShareDYA: 111 },
                { brand: " BLEND OIL", valueShare: 12.6, valueShareDYA: 95 },
                { brand: " CANOLA OIL", valueShare: 10.5, valueShareDYA: 93 },
              ]}
            />
          </ListItem>
          {/* 3 */}
          <ListItem>
            <ResponsiveDialogTwoTable3
              text={
                <Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: `${theme.palette.primary.light}`,
                      fontWeight: "bold",
                    }}
                  >
                    HALA:
                  </Typography>{" "}
                  Although
                  <Typography
                    component="span"
                    sx={{
                      color: `#41DD64`,
                      fontWeight: "bold",
                    }}
                  >
                    SUNFLOWER OIL
                  </Typography>{" "}
                  has increased its volume sales over the past year, it`s still
                  below the market.
                </Typography>
              }
              labels={[
                {
                  brand: "Total Category",
                  valueShare: 54.335,
                  valueShareDYA: 109,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 77.03,
                  valueShareDYA: 117,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 55.31,
                  valueShareDYA: 105,
                },
                { brand: "CORN OIL", valueShare: 46.7, valueShareDYA: 110 },
                { brand: " BLEND OIL", valueShare: 29.42, valueShareDYA: 111 },
                { brand: " CANOLA OIL", valueShare: 71.4, valueShareDYA: 100 },
              ]}
              MFC={[
                {
                  brand: "Total Category",
                  valueShare: 70.29,
                  valueShareDYA: 103,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 10.4,
                  valueShareDYA: 111,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 11.78,
                  valueShareDYA: 101,
                },
                { brand: "CORN OIL", valueShare: 19.77, valueShareDYA: 98 },
                { brand: " BLEND OIL", valueShare: 12.6, valueShareDYA: 100 },
                { brand: " CANOLA OIL", valueShare: 10.5, valueShareDYA: 93 },
              ]}
            />
          </ListItem>


          {/* 4 */}


          {/* <ListItem>
            <ResponsiveDialog1
              text={
                <>
                  <Typography>
                    <Typography
                      component="span"
                      sx={{
                        color: `${theme.palette.primary.light}`,
                        fontWeight: "bold",
                      }}
                    >
                      Total category:
                    </Typography>{" "}
                    The market is trending towards{" "}
                    <Typography
                      component="span"
                      sx={{ color: "#41DD64", fontWeight: "bold" }}
                    >
                      HALAH, ZAHRATI, LEEZA{" "}
                    </Typography>
                  </Typography>
                </>
              }
              labels={[
                { brand: "AFIA", valueShare: 1.159, valueShareDYA: -0.133 },
                { brand: "AL ARABI", valueShare: 0.547, valueShareDYA: -0.067 },
                { brand: "HALAH", valueShare: 0.263, valueShareDYA: 0.068 },
                { brand: "ZAHRATI", valueShare: 0.137, valueShareDYA: 0.003 },
                { brand: "LEEZA", valueShare: 0.098, valueShareDYA: 0.002 },
                { brand: "ZAKI", valueShare: 0.024, valueShareDYA: 0.018 },
                { brand: "ZAIN", valueShare: 0.005, valueShareDYA: 0.003 },
                { brand: "Z-LIGHT", valueShare: 0.001, valueShareDYA: 0.001 },
                { brand: "AL TAYEB", valueShare: 0, valueShareDYA: -0.003 },
                { brand: "SLITE", valueShare: 0, valueShareDYA: 0 },
              ]}
            />
          </ListItem> */}
          {/* 5 */}
          {/* <ListItem>
            <ResponsiveDialog2
              text={
                <Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: `${theme.palette.primary.light}`,
                      fontWeight: "bold",
                    }}
                  >
                    BLEND OIL:
                  </Typography>
                  The market is trending towards{" "}
                  <Typography
                    component="span"
                    sx={{ color: "#41DD64", fontWeight: "bold" }}
                  >
                    {" "}
                    LEEZA, ZAKI, HALAH
                  </Typography>
                </Typography>
              }
              labels={[
                { brand: "LEEZA", valueShare: 0.098, valueShareDYA: 0.002 },
                { brand: "ZAHRATI", valueShare: 0.054, valueShareDYA: -0.037 },
                { brand: "ZAKI", valueShare: 0.023, valueShareDYA: 0.019 },
                { brand: "HALAH", valueShare: 0.005, valueShareDYA: 0.005 },
              ]}
            />
          </ListItem> */}
          {/*  */}

          {/* 6 */}
          {/* <ListItem>
            <ResponsiveDialog3
              text={
                <Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: `${theme.palette.primary.light}`,
                      fontWeight: "bold",
                    }}
                  >
                    PALM & VEGETABLE OIL :
                  </Typography>
                  The market is trending towards{" "}
                  <Typography
                    component="span"
                    sx={{ color: "#41DD64", fontWeight: "bold" }}
                  >
                    {" "}
                    HALAH, ZAIN
                  </Typography>{" "}
                </Typography>
              }
              labels={[
                { brand: "AL ARABI", valueShare: 0.538, valueShareDYA: -0.072 },
                { brand: "HALAH", valueShare: 0.089, valueShareDYA: 0.021 },
                { brand: "ZAIN", valueShare: 0.005, valueShareDYA: 0.003 },
                { brand: "AL TAYEB", valueShare: 0, valueShareDYA: -0.003 },
              ]}
            />
          </ListItem> */}

          {/* 6 */}
          {/* <ListItem>
            <Typography>
              <ResponsiveDialog4
                text={
                  <Typography sx={{}}>
                    <Typography
                      component="span"
                      sx={{
                        color: `${theme.palette.primary.light}`,
                        fontWeight: "bold",
                        
                      }}
                    >
                      SUNFLOWER OIL :
                    </Typography>
                    The market is trending towards{" "}
                    <Typography
                      component="span"
                      sx={{ color: "#41DD64", fontWeight: "bold" ,}}
                    >
                      {" "}
                      HALAH, ZAHRATI, AL ARABI
                    </Typography>{" "}
                  </Typography>
                }
                labels={[
                  { brand: "HALAH", valueShare: 0.169, valueShareDYA: 0.042 },
                  { brand: "AFIA", valueShare: 0.161, valueShareDYA: -0.057 },
                  { brand: "ZAHRATI", valueShare: 0.083, valueShareDYA: 0.04 },
                  {
                    brand: "AL ARABI",
                    valueShare: 0.009,
                    valueShareDYA: 0.005,
                  },
                  { brand: "ZAKI", valueShare: 0.001, valueShareDYA: -0.001 },
                  { brand: "Z-LIGHT", valueShare: 0.001, valueShareDYA: 0.001 },
                  { brand: "LEEZA", valueShare: 0, valueShareDYA: 0 },
                  { brand: "SLITE", valueShare: 0, valueShareDYA: 0.0 },
                ]}
              ></ResponsiveDialog4>
            </Typography>
          </ListItem> */}
        </List>
      </Paper>
    </>
  );
};

export const Gaps = () => {
  return (
    <Paper
      sx={{
        p: 3,
        height: "198px",
        display: "flex",
        flexDirection: "column",
        // height: "9rem",
      }}
    >
      <Stack direction="row" alignItems="center">
        <Typography component="img" src={gapImage} sx={{ width: "1.7rem" }} />

        <Typography
          sx={{
            fontWeight: "bold",
            color: "red",
            fontFamily: "Barlow Semi Condensed",
            m: 1
          }}
        >
          Gaps
        </Typography>
      </Stack>
      {/* <Typography sx={{ fontSize: "0.8rem", m: 2, color: "#CCCBCB", fontFamily: "Barlow Semi Condensed", }}>
        Lorem ipsum dolor sit amet consectetur. Nunc in lectus leo habitant
        pretium id. Malesuada id libero morbi nisl in. Orci vitae interdum risus
        elementum at. Sed ac imperdiet quisque sit eu vestibulum arcu volutpat.
      </Typography> */}
      <Stack direction="row" justifyContent="space-between">
        <List
          aria-labelledby="decorated-list-demo"
          sx={{ "--ListItemDecorator-size": "32px" }}
        >
          <ListItem>
            <ResponsiveDialogTwoTableG1
              text={
                <Typography >
                  <Typography
                    component="span"
                    sx={{
                      color: `${theme.palette.primary.light}`,
                      fontWeight: "bold",
                      
                    }}
                  >
                    Manufacturer:
                  </Typography>{" "}


                  There is clearly a gap in{" "}

                  <Typography
                    component="span"
                    sx={{
                      color: `red`,
                      fontWeight: "bold",
                      
                    }}
                  >
                    BLEND OIL, CANOLA OIL
                  </Typography>{" "}
                </Typography>
              }
              labels={[
                {
                  brand: "Total Category",
                  valueShare: 54.335,
                  valueShareDYA: 109,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 77.03,
                  valueShareDYA: 117,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 55.31,
                  valueShareDYA: 105,
                },
                { brand: "CORN OIL", valueShare: 46.7, valueShareDYA: 110 },
                { brand: " BLEND OIL", valueShare: 29.42, valueShareDYA: 111 },
                { brand: " CANOLA OIL", valueShare: 71.4, valueShareDYA: 100 },
              ]}
              MFC={[
                {
                  brand: "Total Category",
                  valueShare: 16.029,
                  valueShareDYA: 102,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 15.4,
                  valueShareDYA: 110,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 21.7,
                  valueShareDYA: 107,
                },
                { brand: "CORN OIL", valueShare: 18.77, valueShareDYA: 103 },
                { brand: " BLEND OIL", valueShare: 32.6, valueShareDYA: 96 },
                { brand: " CANOLA OIL", valueShare: 25.1, valueShareDYA: 94 },
              ]}
            />
          </ListItem>

          {/* 2 */}
          <ListItem>
            <ResponsiveDialogTwoTableG2
              text={
                <Typography >
                  <Typography
                    component="span"
                    sx={{
                      color: `${theme.palette.primary.light}`,
                      fontWeight: "bold",
                     

                    }}
                  >
                    AFIA:
                  </Typography>{" "}
                  There is clearly a gap in{' '}
                  <Typography
                    component="span"
                    sx={{
                      color: `red`,
                      fontWeight: "bold",
                    

                    }}
                  >
                    SUNFLOWER OIL BLEND OIL, CANOLA OIL.
                  </Typography>{" "}
                </Typography>
              }
              labels={[
                {
                  brand: "Total Category",
                  valueShare: 54.335,
                  valueShareDYA: 109,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 77.03,
                  valueShareDYA: 117,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 55.31,
                  valueShareDYA: 105,
                },
                { brand: "CORN OIL", valueShare: 46.7, valueShareDYA: 110 },
                { brand: " BLEND OIL", valueShare: 29.42, valueShareDYA: 111 },
                { brand: " CANOLA OIL", valueShare: 71.4, valueShareDYA: 100 },
              ]}
              MFC={[
                {
                  brand: "Total Category",
                  valueShare: 70.29,
                  valueShareDYA: 103,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 10.4,
                  valueShareDYA: 98,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 11.78,
                  valueShareDYA: 105,
                },
                { brand: "CORN OIL", valueShare: 19.77, valueShareDYA: 111 },
                { brand: " BLEND OIL", valueShare: 12.6, valueShareDYA: 95 },
                { brand: " CANOLA OIL", valueShare: 10.5, valueShareDYA: 93 },
              ]}
            />
          </ListItem>
          {/* 3 */}
          {/* <ListItem>
            <ResponsiveDialogTwoTableG3
              text={

                <Typography sx={{ }}>
                  <Typography
                    component="span"
                    sx={{
                      color: `${theme.palette.primary.light}`,
                   
                      fontWeight: "bold",
                    }}
                  >
                    HALA:
                  </Typography>{" "}
                  There is clearly a gap in
                  <Typography
                    component="span"
                    sx={{
                      color: `red`,
                      fontWeight: "bold",
                      

                    }}
                  >
                    {" "}
                    CORN OIL AND CANOLA OIL .
                  </Typography>{" "}
                </Typography>
              }
              labels={[
                {
                  brand: "Total Category",
                  valueShare: 54.335,
                  valueShareDYA: 109,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 77.03,
                  valueShareDYA: 117,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 55.31,
                  valueShareDYA: 105,
                },
                { brand: "CORN OIL", valueShare: 46.7, valueShareDYA: 110 },
                { brand: " BLEND OIL", valueShare: 29.42, valueShareDYA: 111 },
                { brand: " CANOLA OIL", valueShare: 71.4, valueShareDYA: 100 },
              ]}
              MFC={[
                {
                  brand: "Total Category",
                  valueShare: 70.29,
                  valueShareDYA: 103,
                },
                {
                  brand: "SUNFLOWER OIL",
                  valueShare: 10.4,
                  valueShareDYA: 111,
                },
                {
                  brand: "PALM & VEGETABLE OIL",
                  valueShare: 11.78,
                  valueShareDYA: 101,
                },
                { brand: "CORN OIL", valueShare: 19.77, valueShareDYA: 98 },
                { brand: " BLEND OIL", valueShare: 12.6, valueShareDYA: 100 },
                { brand: " CANOLA OIL", valueShare: 10.5, valueShareDYA: 93 },
              ]}
            />
          </ListItem>

          <ListItem>
            <ResponsiveDialogG4
              text={
                <Typography sx={{
                  fontFamily: "Barlow Semi Condensed",

                }}>
                  <Typography
                    component="span"
                    sx={{
                      color: `${theme.palette.primary.light}`,
                      fontWeight: "bold",
                      
                    }}
                  >
                    CORN OIL:
                  </Typography>
                  There is clearly a gap in{" "}
                  <Typography
                    component="span"
                    sx={{ color: "red", fontWeight: "bold" }}
                  >
                    {" "}
                    AFIA
                  </Typography>
                </Typography>
              }
              labels={[
                { brand: "AFIA", valueShare: 0.998, valueShareDYA: -0.0 },
                { brand: "Z-LIGHT", valueShare: 0.0, valueShareDYA: 0.0 },
              ]}
            />
          </ListItem>
          <ListItem>
            <ResponsiveDialogG5              text={

                <Typography sx={{  }}>
                  <Typography
                    component="span"
                    sx={{
                      color: `${theme.palette.primary.light}`,
                      fontWeight: "bold",
                      
                    }}
                  >
                    PALM & VEGETABLE OIL :
                  </Typography>
                  There is a gap in our brands in
                  <Box
                    component="span"
                    sx={{ color: "red", fontWeight: "bold" }}
                  >
                    AFIA, AL ARABI, AL TAYEB
                  </Box>
                </Typography>
              }
              labels={[
                { brand: "AL ARABI", valueShare: 0.538, valueShareDYA: -0.072 },
                { brand: "HALAH", valueShare: 0.089, valueShareDYA: 0.021 },
                { brand: "ZAIN", valueShare: 0.005, valueShareDYA: 0.003 },
                { brand: "AL TAYEB", valueShare: 0, valueShareDYA: -0.003 },
              ]}
            />
          </ListItem>
          <ListItem>
            <Typography sx={{  }}>
              <ResponsiveDialogG6
                text={
                  <Typography sx={{  }}>
                    <Typography
                      component="span"
                      sx={{
                        color: `${theme.palette.primary.light}`,
                        fontWeight: "bold",
                        
                      }}
                    >
                      SUNFLOWER OIL :
                    </Typography>
                    There is a gap in our brands in{" "}
                    <Typography
                      component="span"
                      sx={{ color: "red", fontWeight: "bold" }}
                    >
                      {" "}
                      AFIA
                    </Typography>{" "}
                  </Typography>
                }
                labels={[
                  { brand: "HALAH", valueShare: 0.169, valueShareDYA: 0.042 },
                  { brand: "AFIA", valueShare: 0.161, valueShareDYA: -0.057 },
                  { brand: "ZAHRATI", valueShare: 0.083, valueShareDYA: 0.04 },
                  {
                    brand: "AL ARABI",
                    valueShare: 0.009,
                    valueShareDYA: 0.005,
                  },
                  { brand: "ZAKI", valueShare: 0.001, valueShareDYA: -0.001 },
                  { brand: "Z-LIGHT", valueShare: 0.001, valueShareDYA: 0.001 },
                  { brand: "LEEZA", valueShare: 0, valueShareDYA: 0 },
                  { brand: "SLITE", valueShare: 0, valueShareDYA: 0.0 },
                ]}
              ></ResponsiveDialogG6>
            </Typography>
          </ListItem> */}
        </List>

        <List
          aria-labelledby="decorated-list-demo"
          sx={{ "--ListItemDecorator-size": "32px" }}
        >
          {/* <ListItem>
            <Box
              sx={{
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: "red",
                borderRadius: "50%",
                m: 1,
              }}
            />
            Category
          </ListItem>
          <ListItem>
            <Box
              sx={{
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: "red",
                borderRadius: "50%",
                m: 1,
              }}
            />
            Category
          </ListItem>
          <ListItem>
            <Box
              sx={{
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: "red",
                borderRadius: "50%",
                m: 1,
              }}
            />
            Category
          </ListItem> */}
        </List>
      </Stack>
    </Paper>
  );
};
