import React from "react";
import LabTabs from "../../Components/Tabs";

function Promotions() {
  const tabs = ["Landscape", "Price", "Merchandie", "Insights"];
  const navigateTabs = tabs.map((ele, idx) => {
    if (ele == "Landscape") {
      return <h1>Landscape</h1>;
    }
    if (ele == "Price") {
      return <h1>Price</h1>;
    }
    if (ele == "Merchandie") {
      return <h1>Merchandie</h1>;
    }
    if (ele == "Insights") {
      return <h1>Insights</h1>;
    }
  });
  return (
    <>
      <LabTabs tabs={tabs} navigateTabs={navigateTabs} />
    </>
  );
}

export default Promotions;
