import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Divider } from "@mui/material";

export default function LabTabs({ tabs, navigateTabs }) {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        typography: "body1",
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
            bgcolor: "#eee",
            width: "fit-content",
            margin: "5px auto",
            borderRadius: "10px",
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabs.map((ele, idx) => (
              <Tab
                label={ele}
                value={`${idx + 1}`}
                key={idx + 1}
                sx={{ ":hover": { color: "blue" } }}
              />
            ))}
          </TabList>
        </Box>
        {navigateTabs.map((ele, idx) => (
          <TabPanel value={`${idx + 1}`} key={idx + 1}>
            {ele}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
