import { List, ListItemButton, ListItemIcon } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { Box } from "@mui/system";

import { StyledCustom } from "../Styled/Box";
import Custom from "../Assets/custom.svg";

export const CollapseNotExtend = ({ open, activate, image, label }) => {
  return (
    <>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{ width: "100", maxWidth: 360 }}
        >
          <ListItemButton sx={{ pl: 2 }}>
            <ListItemIcon
              sx={{
                minWidth: "1.5rem",
                mr: 2,
                boxSizing: "border-box",
                border: activate
                  ? "2px solid #77B6EA"
                  : "2px solid transparent",
                backgroundColor: activate ? "#77B6EA" : "transparent",
                color: activate ? "white" : "black",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "2rem",
                height: "2rem",
                fontSize: "1.2rem",
                fontWeight: "bold",
                boxShadow: activate ? "0px 0px 10px blue" : "none",
              }}
            >
              <Box component="img" src={image} sx={{ width: "1.7rem" }} />
            </ListItemIcon>

            <Box
              sx={{
                width: "100%",
                fontWeight: 500,
                fontFamily: "Barlow Semi Condensed",
              }}
            >
              {label}
            </Box>
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

export const CollapseCustom = ({ open, handleClick, image, label }) => {
  return (
    <>
      <Collapse in={open} timeout="auto" unmountOnExit onClick={handleClick}>
        <List
          component="div"
          disablePadding
          sx={{ width: "100", maxWidth: 360 }}
        >
          <ListItemButton sx={{ pl: 2 }}>
            <ListItemIcon sx={{ minWidth: "1.5rem", mr: 2 }}>
              <Box component="img" src={image} sx={{ width: "1.7rem" }} />
            </ListItemIcon>

            <Box sx={{ width: "100%", fontFamily: "Barlow Semi Condensed" }}>
              {label}
            </Box>

            <StyledCustom component="img" src={Custom} />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};
