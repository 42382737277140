import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ProgressBar } from "../../Progress";

function CompetitorsTable({ wotTable }) {
  const [brandSwitchingSku, setBrandSwitchingSku] = useState(
    wotTable.wobTableCell
  );
  const [rowData, setRowData] = useState(wotTable.wobTableData);
  const [skus, setSkus] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);

  useEffect(() => {
    const li = [];
    rowData.forEach((item) => {
      if (brandSwitchingSku.includes(item["index"])) {
        li.push(item);
      }
    });
    setSelectedSkus(li);
  }, []);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      padding: "10px !important",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "left",
      padding: "10px !important",
    },
  }));

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{ minHeight: "350px", maxHeight: "400px" }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {wotTable.wobTableHeader.map((ele, idx) => (
                <StyledTableCell key={idx}>{ele} </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedSkus.map((row, index) => {
              let keys = Object.keys(row);
              return (
                <TableRow key={index}>
                  {keys.map((ele, idx) => (
                    <StyledTableCell key={idx}>
                      {ele === "LY DYA" || ele === "YTD DYA" ? (
                        <ProgressBar
                          bgcolor={row[ele] > 0 ? "#D5D5D5" : "red"}
                          progressChild={Math.ceil(row[ele]) * 10}
                          number={row[ele]}
                        />
                      ) : ele == "DYA" || ele == "Value Share" ? (
                        <ProgressBar
                          bgcolor={"#D5D5D5"}
                          progressChild={Math.ceil(row[ele])}
                          number={row[ele]}
                        />
                      ) : ele == "2019 Share DYA" || ele == "DYAWithColor" ? (
                        <ProgressBar
                          bgcolor={row[ele] > 0 ? "green" : "red"}
                          progressChild={Math.ceil(row[ele])}
                          number={row[ele]}
                        />
                      ) : (
                        <>
                          {row[ele]}
                          {ele === "Value Share" || ele === "DYA" ? "%" : ""}
                        </>
                      )}
                    </StyledTableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CompetitorsTable;
