import React from "react";

import { Grid, Button, Icon, Segment, Header, Popup } from "semantic-ui-react";
function AddRemoveDecimal({ isFetching, square, handleLess, handleMore }) {
  return (
    <Grid.Column>
      <Segment loading={isFetching} style={square}>
        <Popup
          trigger={
            <Header
              as="h5"
              icon="sort numeric up"
              content="Add/Remove Decimal"
            />
          }
          content="Update the decimal spaces of SOP and New SOP"
        />

        <Button.Group className="inc-dec">
          <Button
            basic
            size="mini"
            icon
            onClick={handleLess}
            variant="contained"
            color="red"
            className="decrease"
          >
            <Button.Content>
              <Icon name="minus" />
            </Button.Content>
          </Button>
          <Button.Or />
          <Button
            basic
            size="mini"
            icon
            onClick={handleMore}
            variant="contained"
            color="green"
            className="increased"
          >
            <Button.Content>
              <Icon name="plus" />
            </Button.Content>
          </Button>
        </Button.Group>
      </Segment>
    </Grid.Column>
  );
}

export default AddRemoveDecimal;
