import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import name from "../../Assets/name.png";
import logo from "../../Assets/Marketeers.png";

const Navbar = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ backgroundColor: "white" }}
    >
      <Box component="img" src={logo} sx={{ width: "9rem" }}/>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        gap={3}
        marginRight="1rem"
      >
        <Box
          component="img"
          src={name}
          sx={{ width: "2rem", height: "2rem" }}
        />
        <Typography component="div">Client Name</Typography>
      </Stack>
    </Stack>
  );
};

export default Navbar;
