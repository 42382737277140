import React from "react";
import { List, ListItemButton, ListItemIcon, Box } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { StyledListTitle } from "../../Styled/Box";
import { StyledListTypography } from "../../Styled/Typography";
function GenerateStyledListTitle({ onClick, openCondition, icon, label }) {
  return (
    <>
      <StyledListTitle onClick={onClick} sx={{ display: "flex" }}>
        <List component="div" disablePadding sx={{ width: "100" }}>
          <ListItemButton sx={{ pl: "2px" }}>
            <ListItemIcon
              sx={{
                minWidth: "2.2rem",
                marginLeft: "4px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box component="img" src={icon} sx={{ width: "1.5rem" }} />
            </ListItemIcon>
            <StyledListTypography sx={{ marginLeft: "6px !important" }}>
              {label}
            </StyledListTypography>
            {openCondition ? (
              <ExpandLess sx={{ marginLeft: "-3px" }} />
            ) : (
              <ExpandMore sx={{ marginLeft: "-3px" }} />
            )}
          </ListItemButton>
        </List>
      </StyledListTitle>
    </>
  );
}

export default GenerateStyledListTitle;
