import * as React from "react";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import { Box } from "@mui/material";

import knowledgeInsights from "../../Assets/knowledgeInsights.svg";
import decisionAlternative from "../../Assets/DecisionAlternative.svg";
import landscape from "../../Assets/landscape.svg";
import Assort from "../../Assets/Assort.svg";
import Promotion from "../../Assets/promotion.svg";
import Commercial from "../../Assets/Commercial.svg";
import ContactImage from "../../Assets/Contact.svg";
import BrandSwitching from "../../Assets/BrandSwitching.svg";
import Optimization from "../../Assets/Optimization.svg";
import Scenario from "../../Assets/Strategy.svg";
import branding from "../../Assets/branding.png";
import { theme } from "../../Theme/index";
import ListItem from "./ListItem";
import GenerateStyledListTitle from "./StyledListTitle";

export function NestedList({ open }) {
  const [openKnowledge, setOpenKnowledge] = React.useState(true);
  const [openDecision, setOpenDecision] = React.useState(false);
  const [active, setActive] = React.useState(
    localStorage.getItem("active") || "dashboard"
  );

  const nagivate = useNavigate();

  const handleClickKnowledge = () => {
    setOpenKnowledge(!openKnowledge);
    setOpenDecision(false);
  };

  const handleClickDesicionList2 = () => {
    setOpenDecision(!openDecision);
    setOpenKnowledge(false);
  };

  const handleClick = (link) => {
    setActive(link.split("/")[0]);
    nagivate(`/${link}`);
  };

  React.useEffect(() => {
    localStorage.setItem("active", active);
  }, [active]);

  return (
    <>
      <List
        sx={{ width: "100%", maxWidth: 300, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <GenerateStyledListTitle
          onClick={handleClickKnowledge}
          openCondition={openKnowledge}
          icon={knowledgeInsights}
          label={"knowledge&Insights"}
        />

        <ListItem
          label="Dashboard"
          onClick={handleClick}
          active={active}
          open={openKnowledge}
          subPath={`dashboard`}
          theme={theme}
          activeCondition="dashboard"
          img={landscape}
        />

        <ListItem
          label="Market Landscape"
          onClick={handleClick}
          active={active}
          open={openKnowledge}
          subPath={`landscape`}
          theme={theme}
          activeCondition="landscape"
          img={Optimization}
        />

        <ListItem
          label="Pricing & PPA"
          onClick={handleClick}
          active={active}
          open={openKnowledge}
          subPath={`pricingAndPPA`}
          theme={theme}
          activeCondition="pricingAndPPA"
          img={branding}
        />

        <ListItem
          label="Promotion"
          onClick={handleClick}
          active={active}
          open={openKnowledge}
          subPath={`Promotion`}
          theme={theme}
          activeCondition="Promotion"
          img={Promotion}
        />

        <ListItem
          label="Profile Mix"
          onClick={handleClick}
          active={active}
          open={openKnowledge}
          subPath={`mix`}
          theme={theme}
          activeCondition="mix"
          img={Commercial}
        />

        <ListItem
          label="Brand Value"
          onClick={handleClick}
          active={active}
          open={openKnowledge}
          subPath={`brandValue`}
          theme={theme}
          activeCondition="brandValue"
          img={Assort}
        />
      </List>

      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <GenerateStyledListTitle
          onClick={handleClickDesicionList2}
          openCondition={openDecision}
          icon={decisionAlternative}
          label={"Decision Alternative"}
        />

        <Box>
          <ListItem
            label="Simulator"
            onClick={handleClick}
            active={active}
            open={openDecision}
            subPath={`Simulator`}
            theme={theme}
            activeCondition="Simulator"
            img={BrandSwitching}
          />
          <ListItem
            label="Scenario Builder"
            onClick={handleClick}
            active={active}
            open={openDecision}
            subPath={`Scenario`}
            theme={theme}
            activeCondition="Scenario"
            img={Scenario}
          />

          <ListItem
            label="Scenario Builder"
            onClick={handleClick}
            active={active}
            open={openDecision}
            subPath={`Optimization`}
            theme={theme}
            activeCondition="Optimization"
            img={Optimization}
          />
        </Box>
      </List>

      {open && (
        <Box
          component="img"
          src={ContactImage}
          width="9rem"
          margin="0 auto"
          position="absolute"
          bottom="0"
          right="25px"
        />
      )}
    </>
  );
}
