import React from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Paper,
  Stack,
} from "@mui/material";

import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { StyledTitleTypography } from "../../../Styled/Typography";
import { PriceElasticity } from "../../../Components/Chart";

function PriceElasticityComponent({ sku, selectSkus, setSelectSkus, loading }) {
  return (
    <Paper sx={{ p: 1 }}>
      <Stack direction="row" alignItems="Center" justifyContent="space-between">
        <StyledTitleTypography sx={{ p: "2px" }}>
          Price Elasticity
        </StyledTitleTypography>
        <Box minWidth={150}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: "12px",
                paddingBottom: "10px",
                overflow: "initial",
                lineHeight: ".5",
              }}
              id="demo-simple-select-label"
            >
              Sku
            </InputLabel>
            <Select
              multiple
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectSkus}
              label="Sku"
              onChange={(event) => setSelectSkus(event.target.value)}
              sx={{
                maxWidth: 250,
                "& .MuiSelect-select": {
                  padding: "8px",
                  height: "36px",
                },
                "& .MuiMenuItem-root": {
                  height: "36px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
              }}
            >
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={30} />
                </Box>
              ) : (
                sku.map((row, idx) => (
                  <MenuItem value={row} key={idx}>
                    {row}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Box
        sx={{
          fontSize: "12px",
          mb: "2px",
          color: "#6D6D6D",
          p: "2px",
        }}
      >
        Understand changes in demand by analyzing how consumers react to
        variations in price points.
      </Box>
      <PriceElasticity
        key={selectSkus}
        sku={selectSkus}
        projectName="savola smart shopper oil category traditional"
      />
    </Paper>
  );
}

export default PriceElasticityComponent;
