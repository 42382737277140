import React, { useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import TableData from "../../../Components/Shared/table/TableData";
import DropDown from "../utils/DropDown";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

function PriceIdxTableComponent({ totalMarket }) {
  const tableData = totalMarket?.map((ele) => {
    return { ...ele, totalCategory: "Total category" };
  });

  const tableConfig = [
    {
      label: "Price Idx",
      render: (priceIdxData) => priceIdxData.totalCategory,
    },
    {
      label: "",
      render: (priceIdxData) => priceIdxData.price_idx,
    },
    {
      label: "Growth",
      render: (priceIdxData) => (
        <Box
          sx={{
            color: priceIdxData.price_idx_growth < 1 ? "red" : "green",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {priceIdxData.price_idx_growth < 1 ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {priceIdxData.price_idx_growth}
              <SouthIcon />
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {priceIdxData.price_idx_growth}
              <NorthIcon />
            </Box>
          )}
        </Box>
      ),
      header: () => <DropDown />,
    },
  ];

  const ValueTable = {
    tableData,
    tableConfig,
  };
  return <TableData tableData={ValueTable} />;
}

export default PriceIdxTableComponent;
