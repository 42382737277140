import React from "react";
import { Container, Button, Header, Modal } from "semantic-ui-react";
function BaseDefaultScenario({ hints, setState }) {
  return (
    <Container>
      <Modal
        size="mini"
        open={hints}
        onClose={() => setState({ hints: false })}
        onOpen={() => setState({ hints: true })}
      >
        <Header size="large" color="blue" icon>
          Hints
        </Header>
        <Modal.Content>
          <p style={{ textAlign: "center" }}>
            On this step you can adjust the base scenario as prefered by editing
            the blue colored columns.
          </p>
          <p style={{ textAlign: "center" }}>
            If you made changes to the default scenario please save that
            scenario as the base.
          </p>
          <Button
            fluid
            basic
            color="blue"
            onClick={() => setState({ hints: false })}
          >
            Ok
          </Button>
        </Modal.Content>
      </Modal>
    </Container>
  );
}

export default BaseDefaultScenario;
