export const MappingData = (data) => {
  const dataMapping = {
    base_price: "Price",
    "Market Share": "base_market_share",
    value_sales: "base_value_sales",
    value_share: "base_value_share",
    WD: "base_wd",
    "Group Brand": "Brand",
    sop: "SOP",
    Size: "Base Size",
  };
  const newArray = data.data.map((ele) => {
    const mappedObj = {
      "New Price": ele["Price"],
      new_market_share: ele["Market Share"],
      new_wd: ele["WD"],
      new_value_share: ele["value_share"],
      Changed_SOP: ele["sop"],
      new_value_sales: ele["value_sales"],
      "New Size": ele["Size"],
    };

    for (const key in ele) {
      if (dataMapping.hasOwnProperty(key)) {
        mappedObj[dataMapping[key]] = ele[key];
      } else {
        mappedObj[key] = ele[key];
      }
    }
    return mappedObj;
  });
  return newArray;
};

export const transformObject = (inputObj, keyName) => {
  const outputObj = {};
  for (const key in inputObj) {
    outputObj[key] = { [keyName]: inputObj[key] };
  }
  return outputObj;
};

export const getObjectWantToModified = (data) => {
  let dataObject = {};
  const keys = Object.keys(data);
  if (keys.length >= 2) {
    let secondKey = keys[1];
    if (secondKey !== "segments") {
      for (let key in data[secondKey]) {
        if (Number(data[secondKey][key])) {
          dataObject[key] = { [secondKey]: +data[secondKey][key] };
        } else {
          dataObject[key] = { [secondKey]: data[secondKey][key] };
        }
      }
    }
  } else {
    console.log("Object does not have a second key.");
  }
  return dataObject;
};

export const convertSegmentStringToObject = (currentSegment) => {
  let newSegment = [];
  currentSegment.map((segment) => {
    const convertedSegmentArray = segment.split(":");
    newSegment.push({
      [convertedSegmentArray[0].trimEnd()]:
        convertedSegmentArray[1].trimStart(),
    });
  });
  const outputObject = {};

  newSegment.forEach((obj) => {
    for (const key in obj) {
      if (outputObject[key]) {
        outputObject[key].push(obj[key]);
      } else {
        outputObject[key] = [obj[key]];
      }
    }
  });
  return outputObject;
};

export const checkSkuObject = (finalObject, outputObject, data) => {
  for (let key in finalObject) {
    if (key == "change_dict") {
      const targetObject = finalObject[key]; //change_dict object
      for (let targetKey in targetObject) {
        if (outputObject[targetKey]) {
          outputObject[targetKey] = {
            ...outputObject[targetKey],
            ...getObjectWantToModified(data)[targetKey],
          };
        } else {
          outputObject[targetKey] = targetObject[targetKey];
        }
      }
      return outputObject;
    }
  }
};
