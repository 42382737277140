import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function Circles() {
  const [dataChart, setDataChart] = useState({
    series: [44, 55, 13, 43, 22],
    chart: {
      width: 300,
      type: "pie",
    },
    labels: [
      "Team A (24.9)",
      "Team B (31.1)",
      "Team C (7.3)",
      "Team D (24.3)",
      "Team E (12.4)",
    ],
    responsive: [
      {
        breakpoint: 400,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  return (
    <ReactApexChart
      options={dataChart}
      series={dataChart.series}
      type="pie"
      height={200}
    />
  );
}

export default Circles;
