import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";

import { ChartValueSalesandVolumeSales } from "../../Chart.jsx";
import { StyledTitleTypography } from "../../../Styled/Typography.jsx";
import {
  ValueSalesVolumeSalesMKT,
  ValueSalesVolumeSalesMKTChart,
} from "../../Network/Lib/Sales.js";
import { Loading } from "../../Loading.jsx";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import PriceIndex from "../share/PriceIndex.jsx";
import TableData from "../../Shared/table/TableData.jsx";
import { toastError } from "../../../Utils/Toast.js";
import axios from "axios";
import Skelton from "../../Shared/Skelton.jsx";
import { getUrl } from "../../../Utils/Common.js";

function MarketTrend() {
  let initialized = false;
  //Table
  const [years, setYears] = useState([]);

  //chart
  const [months, setMonths] = useState([]);
  const [valueShareChart, setValueShareChart] = useState();
  const [volumeShareChart, setVolumeShareChart] = useState();

  const [marketTrendData, setMarketTrendData] = useState([]);
  const [loadingMarketTrendData, setLoadingMarketTrendData] = useState(false);

  const getMarketTrendData = async () => {
    try {
      setLoadingMarketTrendData(true);
      const { data } = await axios.get(`${getUrl()}/sales/mkt_trend`);
      console.log(data);
      setMarketTrendData(data);
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingMarketTrendData(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      ValueSalesVolumeSalesMKT().then((response) => {
        setYears(response.data.years);
      });

      ValueSalesVolumeSalesMKTChart().then((response) => {
        setMonths(response.data.months);
        setValueShareChart(
          response.data.value_sales.map((a) => Math.floor(a / 10))
        );
        setVolumeShareChart(
          response.data.volume_sales.map((a) => Math.floor(a / 100))
        );
      });
      getMarketTrendData();
    }
  }, []);

  const tableData = marketTrendData;
  const tableConfig = [
    {
      label: "",
      render: (marketTrendData) => (
        <Box color="#1976DB">{marketTrendData.year}</Box>
      ),
    },
    {
      label: "Value (M SAR)",
      render: (marketTrendData) => marketTrendData.value,
    },
    {
      label: "Value Growth",
      render: (marketTrendData) => marketTrendData.value_growth + " %",
    },
    {
      label: "Volume(M Kg)",
      render: (marketTrendData) => marketTrendData.volume,
    },
    {
      label: "Volume Growth",
      render: (marketTrendData) => marketTrendData.volume_growth + " %",
    },
  ];

  const ValueTable = {
    tableData,
    tableConfig,
  };

  return (
    <Paper
      sx={{
        p: 1.2,
        boxShadow: "none",
      }}
    >
      {loadingMarketTrendData ? (
        <Skelton times={3} />
      ) : (
        <TableData tableData={ValueTable} />
      )}
      {valueShareChart ? (
        <>
          {" "}
          <ChartValueSalesandVolumeSales
            valueSales={valueShareChart}
            volumeSales={volumeShareChart}
            labels={months}
          />
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
      <hr />
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              border: "1px solid #dddd",
              borderRadius: "10px",
              m: "5px",
            }}
          >
            <StyledTitleTypography
              sx={{
                textAlign: "center",
                mb: "0 !important",
                mt: "5px !important",
                fontSize: "20px",
              }}
            >
              Chang in average share by segment, index (percent)
            </StyledTitleTypography>
            <PriceIndex />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default MarketTrend;
