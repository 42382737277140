import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { theme } from "../Theme";
import { StyledTitleTypography } from "../Styled/Typography";
import { StyledHeaderDescription } from "../Styled/Typography";

import { ProgressBar, MuliProgressBar } from "../Components/Progress";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CardDecision } from "./Card";
import ImgTop from "../Assets/ImgTop.png";
import ImgBottom from "../Assets/ImgBottom.png";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import React from "react";

import ImgNoData from "../Assets/noData.png";

export const ValueShare = () => {
  const segmentMKT = [
    {
      brand: "Total Category",
      valueShare: 54.335,
      valueShareDYA: 109,
    },
    {
      brand: "SUNFLOWER OIL",
      valueShare: 77.03,
      valueShareDYA: 117,
    },
    {
      brand: "PALM & VEGETABLE OIL",
      valueShare: 55.31,
      valueShareDYA: 105,
    },
    { brand: "CORN OIL", valueShare: 46.7, valueShareDYA: 110 },
    { brand: " BLEND OIL", valueShare: 29.42, valueShareDYA: 111 },
    { brand: " CANOLA OIL", valueShare: 71.4, valueShareDYA: 100 },
  ];
  const segmentMFC = [
    {
      brand: "Total Category",
      valueShare: 16.029,
      valueShareDYA: 102,
    },
    {
      brand: "SUNFLOWER OIL",
      valueShare: 15.4,
      valueShareDYA: 110,
    },
    {
      brand: "PALM & VEGETABLE OIL",
      valueShare: 21.7,
      valueShareDYA: 107,
    },
    { brand: "CORN OIL", valueShare: 18.77, valueShareDYA: 103 },
    { brand: " BLEND OIL", valueShare: 32.6, valueShareDYA: 96 },
    { brand: " CANOLA OIL", valueShare: 25.1, valueShareDYA: 94 },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",

    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      padding: "10px",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      // textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      textTransform: "capitalize",
      fontSize: 12,
      padding: "10px",

      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Paper
      sx={{
        p: 3,
        mt: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StyledTitleTypography>Market</StyledTitleTypography>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead sx={{ height: "10px" }}>
                <TableRow>
                  <StyledTableCell> Segment </StyledTableCell>
                  <StyledTableCell>Volume Sales(M Kg)</StyledTableCell>
                  <StyledTableCell>% Change IYA</StyledTableCell>
                  {/* <StyledTableCell>Price IX</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {segmentMKT.map((row, id) => {
                  return (
                    <StyledTableRow key={id}>
                      <StyledTableCell component="th" scope="row">
                        {row.brand}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          color: `${theme.palette.primary.main}`,
                          fontWeight: "bold",
                        }}
                      >
                        {/* {row.volume_sales} */}
                        <ProgressBar
                          bgcolor="lightgray"
                          progressChild={Math.floor(row.valueShare)}
                          number={Math.floor(row.valueShare)}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          color: `${theme.palette.primary.main}`,
                          fontWeight: "bold",
                        }}
                      >
                        <Box
                          sx={{
                            color: row.valueShareDYA < 1 ? "red" : "green",
                          }}
                        >
                          {row.valueShareDYA + "%"}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <StyledTitleTypography>Manufacturer</StyledTitleTypography>

          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead sx={{ height: "10px" }}>
                <TableRow>
                  <StyledTableCell> Segment </StyledTableCell>
                  <StyledTableCell>Volume Sales(M Kg)</StyledTableCell>
                  <StyledTableCell>% Change IYA</StyledTableCell>
                  {/* <StyledTableCell>Price IX</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {segmentMFC.map((row, id) => {
                  return (
                    <StyledTableRow key={id}>
                      <StyledTableCell component="th" scope="row">
                        {row.brand}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          color: `${theme.palette.primary.main}`,
                          fontWeight: "bold",
                        }}
                      >
                        <ProgressBar
                          bgcolor="lightgray"
                          progressChild={Math.floor(row.valueShare)}
                          number={Math.floor(row.valueShare)}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          color: `${theme.palette.primary.main}`,
                          fontWeight: "bold",
                        }}
                      >
                        <Box
                          sx={{
                            color:
                              row.valueShareDYA < 100
                                ? "red"
                                : row.valueShareDYA >
                                  segmentMKT[0].valueShareDYA
                                ? "green"
                                : "orange",
                          }}
                        >
                          {row.valueShareDYA + "%"}
                        </Box>
                      </StyledTableCell>

                      {/* <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          color: `${theme.palette.primary.main}`,
                          fontWeight: "bold",
                        }}
                      >
                        {row.price_index}
                      </StyledTableCell> */}

                      {/* <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          color: `${theme.palette.primary.main}`,
                          fontWeight: "bold",
                        }}
                      >
                        {row.price}
                      </StyledTableCell> */}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const PriceArchitecture = ({ ladders }) => {
  useEffect(() => {
    console.log(ladders);
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
      padding: "22px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const list = [
    {
      name: "<10",
      mkt: 7,
      AfiaName: 2,
      lizaName: 1,
      othersName: 4,
      Afia: 0.285714286,
      liza: 0.142857143,
      others: 0.571428571,
      alamieName: 3,
      lamarName: 1,
      others1Name: 3,
      alamie: 0.428571429,
      lamar: 0.142857143,
      others1: 0.428571429,
    },
    {
      name: "10-20",
      mkt: 46.74,
      AfiaName: 18,
      lizaName: 10,
      othersName: 18.74,
      Afia: 0.385109114,
      liza: 0.213949508,
      others: 0.400941378,
      alamieName: 12,
      lamarName: 11,
      others1Name: 23.74,
      alamie: 0.256739409,
      lamar: 0.235344459,
      others1: 0.507916132,
    },
    {
      name: "20-30",
      mkt: 22.1,
      AfiaName: 9,
      lizaName: 4,
      othersName: 9.1,
      Afia: 0.407239819,
      liza: 0.180995475,
      others: 0.411764706,
      alamieName: 7,
      lamarName: 6,
      others1Name: 9.1,
      alamie: 0.316742081,
      lamar: 0.271493213,
      others1: 0.411764706,
    },
    {
      name: "30-40",
      mkt: 19,
      AfiaName: 4,
      lizaName: 1,
      othersName: 14,
      Afia: 0.210526316,
      liza: 0.052631579,
      others: 0.736842105,
      alamieName: 3,
      lamarName: 1,
      others1Name: 15,
      alamie: 0.157894737,
      lamar: 0.052631579,
      others1: 0.789473684,
    },
    {
      name: "40-50",
      mkt: 4.12,
      AfiaName: 0.8,
      lizaName: 0.7,
      othersName: 2.62,
      Afia: 0.194174757,
      liza: 0.169902913,
      others: 0.63592233,
      alamieName: 1,
      lamarName: 0.9,
      others1Name: 2.22,
      alamie: 0.242718447,
      lamar: 0.218446602,
      others1: 0.538834951,
    },
    {
      name: "50<",
      mkt: 1.04,
      AfiaName: 0.2,
      lizaName: 0,
      othersName: 0.84,
      Afia: 0.192307692,
      liza: 0,
      others: 0.807692308,
      alamieName: 0.26,
      lamarName: 0.09,
      others1Name: 0.69,
      alamie: 0.25,
      lamar: 0.086538462,
      others1: 0.663461538,
    },
  ];
  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        pb: 2,
      }}
    >
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Price Ladders </StyledTableCell>
              <StyledTableCell>Volume Share</StyledTableCell>
              <StyledTableCell sx={{ width: "40%    " }}>
                Comparison Across Manufacturer Brands
              </StyledTableCell>
              <StyledTableCell sx={{ width: "30%    " }}>
                Comparison Across Main Competition
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* console.log(val)); */}
            {/* {ladders?.map((val) => {
              return ( */}
            {list.map((a) => {
              return (
                <StyledTableRow
                  sx={{
                    fontWeight: "bold",
                    border: a.name == "30-40" ? "8px solid #FBB8B8" : "",
                  }}
                >
                  <StyledTableCell> {a.name}</StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    <ProgressBar
                      bgcolor="orange"
                      progressChild={a.mkt}
                      number={a.mkt}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    <MuliProgressBar
                      progress1={a.Afia * 100}
                      number1={a.AfiaName}
                      progress2={a.liza * 100}
                      number2={a.lizaName}
                      progress3={a.others * 100}
                      number3={a.othersName}
                      bgcolor1="#567FCA"
                      bgcolor2="#2F5597"
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <MuliProgressBar
                      progress1={a.lamar * 100}
                      number1={a.lamarName}
                      progress2={a.alamie * 100}
                      number2={a.alamieName}
                      progress3={a.others1 * 100}
                      number3={a.others1Name}
                      bgcolor1="#00A097"
                      bgcolor2="#B2DFDC"
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
            <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "50px", display: "flex" }}>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",

                      backgroundColor: "#567FCA",
                    }}
                  ></Box>{" "}
                  AFIA
                </Box>{" "}
                <Box sx={{ width: "50px", display: "flex" }}>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",

                      backgroundColor: "#2F5597",
                    }}
                  ></Box>{" "}
                  LIZA
                </Box>{" "}
                <Box sx={{ width: "50px", display: "flex" }}>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",

                      backgroundColor: "#D9D9D9",
                    }}
                  ></Box>{" "}
                  Other
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "70px", display: "flex" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",

                        backgroundColor: "#00A097",
                      }}
                    ></Box>{" "}
                    LAMAR
                  </Box>{" "}
                  <Box sx={{ width: "70px", display: "flex" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",

                        backgroundColor: "#B2DFDC",
                      }}
                    ></Box>{" "}
                    ALAMIR
                  </Box>{" "}
                  <Box sx={{ width: "50px", display: "flex" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",

                        backgroundColor: "#D9D9D9",
                      }}
                    ></Box>{" "}
                    Other
                  </Box>
                </Box>
              </StyledTableCell>
            </StyledTableRow>

            {/* );
            })} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const PriceArchitectureSize = ({ ladders }) => {
  useEffect(() => {
    console.log(ladders);
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
      padding: "22px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const list = [
    {
      name: "0-500ml",
      mkt: 8,

      AfiaName: 2,
      lizaName: 1,
      othersName: 5,
      Afia: 0.25,
      liza: 0.13,
      others: 0.63,
      lamarName: 1,
      alamieName: 1,
      others1Name: 6,
      lamar: 0.13,
      alamie: 0.13,
      Others1: 0.75,
    },
    {
      name: "501-1000ml",
      mkt: 14,

      AfiaName: 3.2,
      lizaName: 3,
      othersName: 7.8,
      Afia: 0.23,
      liza: 0.21,
      others: 0.56,
      lamarName: 2.6,
      alamieName: 1.9,
      others1Name: 9.5,
      lamar: 0.19,
      alamie: 0.14,
      Others1: 0.68,
    },
    {
      name: "1001-2000ml",
      mkt: 18,

      AfiaName: 4.3,
      lizaName: 3,
      othersName: 10.7,
      Afia: 0.24,
      liza: 0.17,
      others: 0.59,
      lamarName: 6,
      alamieName: 4,
      others1Name: 8,
      lamar: 0.33,
      alamie: 0.22,
      Others1: 0.44,
    },
    {
      name: "2001-3500ml",
      mkt: 30,

      AfiaName: 8.4,
      lizaName: 3.3,
      othersName: 18.3,
      Afia: 0.28,
      liza: 0.11,
      others: 0.61,
      lamarName: 8,
      alamieName: 5,
      others1Name: 17,
      lamar: 0.27,
      alamie: 0.17,
      Others1: 0.57,
    },
    {
      name: "3501-5000ml",
      mkt: 22,

      AfiaName: 6.3,
      lizaName: 3,
      othersName: 12.7,
      Afia: 0.29,
      liza: 0.14,
      others: 0.58,
      lamarName: 5,
      alamieName: 3,
      others1Name: 14,
      lamar: 0.23,
      alamie: 0.14,
      Others1: 0.64,
    },
    {
      name: "5001-10000ml",
      mkt: 6,

      AfiaName: 1.1,
      lizaName: 1,
      othersName: 3.9,
      Afia: 0.18,
      liza: 0.17,
      others: 0.65,
      lamarName: 0.8,
      alamieName: 0.7,
      others1Name: 4.5,
      lamar: 0.13,
      alamie: 0.12,
      Others1: 0.75,
    },
    {
      name: "10001ml+",
      mkt: 2,

      AfiaName: 0.9,
      lizaName: 0.09,
      othersName: 1.01,
      Afia: 0.45,
      liza: 0.05,
      others: 0.51,
      lamarName: 0.1,
      alamieName: 0.2,
      others1Name: 1.7,
      lamar: 0.05,
      alamie: 0.1,
      Others1: 0.85,
    },
  ];
  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        pb: 2,
      }}
    >
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Size Ladders </StyledTableCell>
              <StyledTableCell>Volume Share</StyledTableCell>
              <StyledTableCell sx={{ width: "40%    " }}>
                Comparison Across Manufacturer Brands
              </StyledTableCell>
              <StyledTableCell sx={{ width: "30%    " }}>
                Comparison Across Main Competition
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* console.log(val)); */}
            {/* {ladders?.map((val) => {
              return ( */}
            {list.map((a) => {
              return (
                <StyledTableRow
                  sx={{
                    fontWeight: "bold",
                    border:
                      a.name == "2001-3500ml" || a.name == "50<"
                        ? "8px solid #FBB8B8"
                        : "",
                  }}
                >
                  <StyledTableCell> {a.name}</StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    <ProgressBar
                      bgcolor="orange"
                      progressChild={a.mkt}
                      number={a.mkt}
                      // progressChild={Math.floor(
                      //   val.volume_share * 100
                      // )}
                      // number={`${val.volume_share * 100}%` }
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    <MuliProgressBar
                      progress1={a.Afia * 100}
                      number1={a.AfiaName}
                      progress2={a.liza * 100}
                      number2={a.lizaName}
                      progress3={a.others * 100}
                      number3={a.othersName}
                      bgcolor1="#567FCA"
                      bgcolor2="#2F5597"
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <MuliProgressBar
                      progress1={a.lamar * 100}
                      number1={a.lamarName}
                      progress2={a.alamie * 100}
                      number2={a.alamieName}
                      progress3={a.Others1 * 100}
                      number3={a.others1Name}
                      bgcolor1="#00A097"
                      bgcolor2="#B2DFDC"
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
            <StyledTableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "50px", display: "flex" }}>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",

                      backgroundColor: "#567FCA",
                    }}
                  ></Box>{" "}
                  AFIA
                </Box>{" "}
                <Box sx={{ width: "50px", display: "flex" }}>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",

                      backgroundColor: "#2F5597",
                    }}
                  ></Box>{" "}
                  LIZA
                </Box>{" "}
                <Box sx={{ width: "50px", display: "flex" }}>
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",

                      backgroundColor: "#D9D9D9",
                    }}
                  ></Box>{" "}
                  Other
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "70px", display: "flex" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",

                        backgroundColor: "#00A097",
                      }}
                    ></Box>{" "}
                    LAMAR
                  </Box>{" "}
                  <Box sx={{ width: "70px", display: "flex" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",

                        backgroundColor: "#B2DFDC",
                      }}
                    ></Box>{" "}
                    ALAMIR
                  </Box>{" "}
                  <Box sx={{ width: "50px", display: "flex" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",

                        backgroundColor: "#D9D9D9",
                      }}
                    ></Box>{" "}
                    Other
                  </Box>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
            {/* );
            })} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const SwitchingBehaviour = ({ brandSwitchingSKU }) => {
  // const li = brandSwitchingSKU.map((ele) => ({
  //   priceMin: ele["min Price"],
  //   priceMax: ele["max Price"],
  //   fairMinus: 0.072401548,
  //   fairPlus: 0.073401548,
  //   skuMin: "Momtaz Mix 1 L,",
  //   skuMax: "Helwa Mix 0.7 L",
  //   // numberMin:"80",
  // }));
  const list = [
    {
      priceMin: 0.091401548,
      priceMax: 0.081296084,
      fairMinus: 0.072401548,
      fairPlus: 0.073401548,
      skuMin: "Momtaz Mix 1 L,",
      skuMax: "Helwa Mix 0.7 L",
      // numberMin:"80",
    },
    {
      priceMin: 0.089479475,
      priceMax: 0.076492353,
      fairMinus: 0.095479475,
      fairPlus: 0.072479475,
      skuMin: "Crystal Corn 0.8 L",
      skuMax: "Momtaz Mix 1 L",
    },
    {
      priceMin: 0.075434782,
      priceMax: 0.070576763,
      fairMinus: 0.066434782,
      fairPlus: 0.090434782,
      skuMin: "Helwa Mix 0.7 L,",
      skuMax: "Afia Corn 2.2 L",
    },
    {
      priceMin: 0.069896693,
      priceMax: 0.067192295,
      fairMinus: 0.069896693,
      fairPlus: 0.053896693,
      skuMin: "Sindbad Mottaheda Mix 1 L,",
      skuMax: "Sunny SF 0.7 L",
    },
  ];
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
    },
  }));

  const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#3C3C3C",
      color: "#ffff",
      textAlign: "center",
      lineHeight: "1.1rem",
      padding: "5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCellHead>
                Sourcing vs. Fair Sahre{" "}
              </StyledTableCellHead>

              <StyledTableCell sx={{ width: "30%" }} colSpan={2}>
                Pricing(-25%)
              </StyledTableCell>
              <StyledTableCell sx={{ width: "30%" }} colSpan={2}>
                Pricing(+25%)
              </StyledTableCell>

              <StyledTableCellHead>
                Sourcing vs. Fair Sahre{" "}
              </StyledTableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((a, idx) => {
              return (
                <StyledTableRow key={idx}>
                  <StyledTableCell sx={{ color: "red" }}>
                    {Math.floor(a.fairMinus * 100)}%
                  </StyledTableCell>
                  <StyledTableCell>
                    <ProgressBar
                      bgcolor="gray"
                      progressChild={Math.floor(a.priceMin * 1000)}
                      number={Math.floor(a.priceMin * 100)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{a.skuMin}</StyledTableCell>
                  <StyledTableCell>{a.skuMax}</StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    <ProgressBar
                      bgcolor="gray"
                      progressChild={Math.floor(a.priceMax * 1000)}
                      number={Math.floor(a.priceMax * 100)}
                    />
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: "red" }}>
                    {Math.floor(a.fairPlus * 100)}%
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const Cannibilization = (props) => {
  const [rowData, setRowData] = useState([
    {
      index: "Afia SF 1.5L",
      elasticity: -0.035330368,
      "volume driver": false,
      "value driver": true,
      cannibalization: 68.5016470816,
      "high cannibalization": false,
      "Top SKU in interaction": "Zahrati SF 1.5L",
      "Top Cannibalizer": "Zahrati SF 1.5L",
      Strategy: "Don't Down Price",
      min: 14,
      max: 22.4,
    },
    {
      index: "Halah SF 1.5L",
      elasticity: -0.3437726942,
      "volume driver": false,
      "value driver": true,
      cannibalization: 60.2314911752,
      "high cannibalization": false,
      "Top SKU in interaction": "Zahrati SF 1.5L",
      "Top Cannibalizer": "Zahrati SF 1.5L",
      Strategy: "Follow Competitor",
      min: 10.3,
      max: 15,
    },
    {
      index: "Shams SF 1.5L",
      elasticity: -0.3550619332,
      "volume driver": true,
      "value driver": false,
      cannibalization: 58.2983563896,
      "high cannibalization": false,
      "Top SKU in interaction": "Zahrati SF 1.5L",
      "Top Cannibalizer": "Zahrati SF 1.5L",
      Strategy: "Use To Attract New Customers",
      min: 12.6,
      max: 19,
    },
    {
      index: "Shams SF 1.5x2L",
      elasticity: 0.0,
      "volume driver": false,
      "value driver": true,
      cannibalization: 50.356389292,
      "high cannibalization": false,
      "Top SKU in interaction": "Afia Corn 1.5L",
      "Top Cannibalizer": "Afia Corn 1.5L",
      Strategy: "Value Driver Don't Promote",
      min: 25.5,
      max: 32.5,
    },
    {
      index: "Shams SF 2.9L",
      elasticity: -0.0791582193,
      "volume driver": false,
      "value driver": true,
      cannibalization: 60.6156867911,
      "high cannibalization": false,
      "Top SKU in interaction": "Zahrati SF 1.5L",
      "Top Cannibalizer": "Zahrati SF 1.5L",
      Strategy: "Value Driver Don't Promote",
      min: 21.4,
      max: 29.3,
    },
    {
      index: "Afia SF 1.5x2L",
      elasticity: 0.0,
      "volume driver": false,
      "value driver": true,
      cannibalization: 76.6256560291,
      "high cannibalization": false,
      "Top SKU in interaction": "Afia Corn 1.5L",
      "Top Cannibalizer": "Afia Corn 1.5L",
      Strategy: "Value Driver Don't Promote",
      min: 22.3,
      max: 33,
    },
    {
      index: "Abu Zahra SF 1.8L",
      elasticity: -0.1267950152,
      "volume driver": false,
      "value driver": true,
      cannibalization: 64.5930503171,
      "high cannibalization": false,
      "Top SKU in interaction": "Abu Zahra SF 2.9L",
      "Top Cannibalizer": "Zahrati SF 1.5L",
      Strategy: "Use To Upsize",
      min: 11,
      max: 17.3,
    },
    {
      index: "Afia SF 2.9L",
      elasticity: -0.0097427815,
      "volume driver": true,
      "value driver": false,
      cannibalization: 72.1647560299,
      "high cannibalization": false,
      "Top SKU in interaction": "Afia Corn 1.5L",
      "Top Cannibalizer": "Afia Corn 1.5L",
      Strategy: "Apply Promotions",
      min: 30,
      max: 42,
    },
  ]);
  const [skus, setSkus] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);

  useEffect(() => {
    console.log(props);
    const li = [];
    rowData.forEach((item) => {
      if (props.data.includes(item["index"])) {
        li.push(item);
      }
    });
    setSelectedSkus(li);
  }, []);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
    },
  }));

  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        pb: 2,
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>SKU Name </StyledTableCell>
              <StyledTableCell sx={{ width: "20%    " }}>
                Cannibilization
              </StyledTableCell>
              <StyledTableCell>Top SKU in Interaction</StyledTableCell>
              <StyledTableCell>Strategy</StyledTableCell>
              <StyledTableCell>Volume Driver</StyledTableCell>
              <StyledTableCell>Value Driver</StyledTableCell>
              <StyledTableCell>Min Price</StyledTableCell>
              <StyledTableCell>Max Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedSkus.map((row) => (
              <TableRow>
                <StyledTableCell>{row["index"]} </StyledTableCell>
                <StyledTableCell>
                  {row["cannibalization"].toFixed(2)}
                </StyledTableCell>
                <StyledTableCell>
                  {row["Top SKU in interaction"]}
                </StyledTableCell>
                <StyledTableCell sx={{ backgroundColor: "orange" }}>
                  {row["Strategy"]}
                </StyledTableCell>
                <StyledTableCell>
                  {row["volume driver"] ? <>yes</> : <>no</>}
                </StyledTableCell>
                <StyledTableCell>
                  {row["value driver"] ? <>yes</> : <>no</>}
                </StyledTableCell>
                <StyledTableCell>{row["min"].toFixed(1)}</StyledTableCell>
                <StyledTableCell>{row["max"].toFixed(1)}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const Elasticity = (props) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      textAlign: "center",
      padding: "4px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      textAlign: "center",
      padding: "4px",
    },
  }));

  return (
    <Box
      sx={{
        mr: 1,
      }}
    >
      <TableContainer component={Paper} sx={{ maxHeight: "350px" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell> Negative </StyledTableCell>
              <StyledTableCell>SKU</StyledTableCell>
              <StyledTableCell>Positive</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <TableRow>
                <StyledTableCell>{row.slope.negative} </StyledTableCell>
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell>{row.slope.positive}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const Promotion = (props) => {
  const [rows, setRows] = useState([
    {
      name: "Afia Corn 1.5L",
      oldPrice: 21.88,
      newPrice: 19.05,
      volume: 6.6,
      revenue: "2,107,201",
      penetration: 50.3,
      margin: 22.1,
    },
    {
      name: "Afia Corn 2.9L",
      oldPrice: 37.7,
      newPrice: 33.4,
      volume: 34.4,
      revenue: "170,726",
      penetration: 60.2,
      margin: 31.2,
    },
    {
      name: "Afia Corn 9L",
      oldPrice: 94.5,
      newPrice: 90.2,
      volume: 0.3,
      revenue: "68,947",
      penetration: 90.4,
      margin: 30.5,
    },
    {
      name: "Afia Corn 0.75L",
      oldPrice: 11.63,
      newPrice: 13.2,
      volume: 2,
      revenue: "678,819",
      penetration: 33.6,
      margin: 25.6,
    },
  ]);
  const [bestPromotion, setBestPromotion] = useState([]);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
      padding: "8px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Paper
      sx={{
        p: 3,
        mt: 2,
        mb: 3,
      }}
    >
      <Typography
        sx={{
          color: `${theme.palette.primary.main}`,

          fontSize: "20px",
          fontFamily: "Barlow Semi Condensed",
        }}
      >
        Best Optimization
      </Typography>
      <StyledHeaderDescription sx={{ pt: 0.7 }}>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Temporibus
        eaque, facilis sint minima ducimus est id quam minus quae, deleniti
        reiciendis. Itaque doloribus maiores eius, animi blanditiis asperiores
        corrupti quidem.
      </StyledHeaderDescription>
      <Grid container spacing={3}>
        <Grid item lg={4} md={4} xs={12}>
          <CardDecision title="Market Share" number="45.1%" image={ImgTop} />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <CardDecision title="Value Share" number="49.3%" image={ImgBottom} />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <CardDecision
            title="Revenue (SAR)"
            number="9,402,899"
            image={ImgTop}
          />
        </Grid>
      </Grid>
      <StyledTitleTypography sx={{ mt: 3, mb: 3 }}>
        Outcome{" "}
      </StyledTitleTypography>

      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead sx={{ height: "10px" }}>
            <TableRow>
              <StyledTableCell> SKUs </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Old Price (SAR)
              </StyledTableCell>
              <StyledTableCell>new_price (SAR)</StyledTableCell>
              <StyledTableCell>Volume Share (%)</StyledTableCell>
              <StyledTableCell>Revenue (SAR)</StyledTableCell>
              <StyledTableCell>Penetration (%)</StyledTableCell>
              <StyledTableCell>Margin (%)</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    color: `${theme.palette.primary.main}`,
                    fontWeight: "bold",
                  }}
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>{row.oldPrice}</StyledTableCell>
                <StyledTableCell
                  sx={{
                    color: row.newPrice > row.oldPrice ? "#41DD64" : "red",
                  }}
                >
                  {row.newPrice}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ color: row.volume > 1.5 ? "#41DD64" : "red" }}
                >
                  {row.volume}
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#41DD64" }}>
                  {row.revenue}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ color: row.penetration > 60 ? "#41DD64" : "red" }}
                >
                  {row.penetration}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ color: row.margin > 30 ? "#41DD64" : "red" }}
                >
                  {row.margin}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            <TableRow sx={{ backgroundColor: `${theme.palette.action.hover}` }}>
              <StyledTableCell
                sx={{
                  // backgroundColor: `rgba(0,38,94,0.5)`,
                  borderRadius: "20px",
                  // color: "#ffff",
                }}
              >
                {" "}
                Brand{" "}
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell sx={{ color: "#41DD64" }}>43.3</StyledTableCell>
              <StyledTableCell sx={{ color: "#41DD64" }}>
                3,025,693
              </StyledTableCell>
              <StyledTableCell sx={{ color: "#41DD64" }}>22</StyledTableCell>
              <StyledTableCell sx={{ color: "red" }}>15</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export const ScenarioTable = (props) => {
  const [rows, setRows] = useState([
    {
      name: "Afia Corn 1.5L",
      oldPrice: 21.88,
      newPrice: 19.05,
      volume: 6.6,
      revenue: "2,107,201",
      penetration: 50.3,
      margin: 22.1,
    },
    {
      name: "Afia Corn 2.9L",
      oldPrice: 37.7,
      newPrice: 33.4,
      volume: 34.4,
      revenue: "170,726",
      penetration: 60.2,
      margin: 31.2,
    },
    {
      name: "Afia Corn 9L",
      oldPrice: 94.5,
      newPrice: 90.2,
      volume: 0.3,
      revenue: "68,947",
      penetration: 90.4,
      margin: 30.5,
    },
    {
      name: "Afia Corn 0.75L",
      oldPrice: 11.63,
      newPrice: 13.2,
      volume: 2,
      revenue: "678,819",
      penetration: 33.6,
      margin: 25.6,
    },
  ]);
  const [bestPromotion, setBestPromotion] = useState([]);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData(name, price, baseMS, newMS, baseVS, newVS) {
    return { name, price, baseMS, newMS, baseVS, newVS };
  }

  // useEffect(() => {
  //   const results = [];
  //   props.data.rendered_data.forEach((item) => {
  //     results.push(
  //       createData(
  //         item["SKU Name"],
  //         item["base_price"],
  //         item["base_market_share"],
  //         item["new_market_share"],
  //         item["base_Sales"],
  //         item["new_Sales"]
  //       )
  //     );
  //     setRows(results);
  //   });
  //   setBestPromotion(props.bestPromotion);
  // }, []);

  return (
    <Paper
      sx={{
        p: 3,
        mt: 2,
        mb: 3,
      }}
    >
      <Typography
        sx={{
          color: `${theme.palette.primary.main}`,

          fontSize: "20px",
          fontFamily: "Barlow Semi Condensed",
        }}
      >
        Scenario
      </Typography>
      <StyledHeaderDescription sx={{ pt: 0.7 }}>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Temporibus
        eaque, facilis sint minima ducimus est id quam minus quae, deleniti
        reiciendis. Itaque doloribus maiores eius, animi blanditiis asperiores
        corrupti quidem.
      </StyledHeaderDescription>
      <Grid container spacing={3}>
        <Grid item lg={4} md={4} xs={12}>
          <CardDecision title="Market Share" number="45.1%" image={ImgTop} />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <CardDecision title="Value Share" number="49.3%" image={ImgBottom} />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <CardDecision
            title="Revenue (SAR)"
            number="9,402,899"
            image={ImgTop}
          />
        </Grid>
      </Grid>
      <StyledTitleTypography sx={{ mt: 3, mb: 3 }}>
        Outcome{" "}
      </StyledTitleTypography>

      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead sx={{ height: "10px" }}>
            <TableRow>
              <StyledTableCell> SKUs </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Old Price (SAR)
              </StyledTableCell>
              <StyledTableCell>new_price (SAR)</StyledTableCell>
              <StyledTableCell>Volume Share (%)</StyledTableCell>
              <StyledTableCell>Revenue (SAR)</StyledTableCell>
              <StyledTableCell>Penetration (%)</StyledTableCell>
              <StyledTableCell>Margin (%)</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    color: `${theme.palette.primary.main}`,
                    fontWeight: "bold",
                  }}
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>{row.oldPrice}</StyledTableCell>
                <StyledTableCell
                  sx={{
                    color: row.newPrice > row.oldPrice ? "#41DD64" : "red",
                  }}
                >
                  {row.newPrice}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ color: row.volume > 1.5 ? "#41DD64" : "red" }}
                >
                  {row.volume}
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#41DD64" }}>
                  {row.revenue}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ color: row.penetration > 30 ? "#41DD64" : "red" }}
                >
                  {row.penetration}
                </StyledTableCell>
                <StyledTableCell
                  sx={{ color: row.margin > 30 ? "#41DD64" : "red" }}
                >
                  {row.margin}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            <TableRow sx={{ backgroundColor: `${theme.palette.action.hover}` }}>
              <StyledTableCell> Brand </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }}></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell sx={{ color: "#41DD64" }}>43.3</StyledTableCell>
              <StyledTableCell sx={{ color: "#41DD64" }}>
                3,025,693
              </StyledTableCell>
              <StyledTableCell sx={{ color: "#41DD64" }}>22</StyledTableCell>
              <StyledTableCell sx={{ color: "red" }}>15</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export const GapsAndOpp = ({ labels }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",

    [`&.${tableCellClasses.head}`]: {
      padding: "5px",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      textTransform: "capitalize",
      fontSize: 12,
      padding: "5px",

      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead sx={{ height: "10px" }}>
            <TableRow>
              <StyledTableCell> Segment </StyledTableCell>
              <StyledTableCell>Value Sales</StyledTableCell>
              <StyledTableCell>Value Sales IYA</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {labels.map((row, id) => {
              return (
                <StyledTableRow key={id}>
                  <StyledTableCell component="th" scope="row">
                    {row.brand}
                  </StyledTableCell>

                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      color: `${theme.palette.primary.main}`,
                      fontWeight: "bold",
                    }}
                  >
                    {row.valueShare}
                  </StyledTableCell>

                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      width: "100px",
                      m: 5,

                      backgroundColor:
                        row.valueShareDYA < 100
                          ? `  #FF6565`
                          : row.valueShareDYA < labels[0].valueShareDYA
                          ? "rgba(65,221,100,0.5)"
                          : "orange",

                      fontWeight: "bold",
                    }}
                  >
                    {row.valueShareDYA}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const GapsAndOppTwoTable = ({ labels, MFC }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",

    [`&.${tableCellClasses.head}`]: {
      padding: "3px",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      textTransform: "capitalize",
      fontSize: 12,
      padding: "10px",

      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead sx={{ height: "10px" }}>
                <TableRow>
                  <StyledTableCell> Segment </StyledTableCell>
                  <StyledTableCell>Value Sales</StyledTableCell>
                  <StyledTableCell>Value Sales IYA</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {labels.map((row, id) => {
                  return (
                    <StyledTableRow key={id}>
                      <StyledTableCell component="th" scope="row">
                        {row.brand}
                      </StyledTableCell>

                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          color: `${theme.palette.primary.main}`,
                          fontWeight: "bold",
                        }}
                      >
                        {row.valueShare}
                      </StyledTableCell>

                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          backgroundColor:
                            row.valueShareDYA < 100
                              ? `  #FF6565`
                              : "rgba(65,221,100,0.5)",
                          fontWeight: "bold",
                          color: `${theme.palette.primary.main}`,
                          fontWeight: "bold",
                        }}
                      >
                        {row.valueShareDYA}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead sx={{ height: "10px" }}>
                <TableRow>
                  <StyledTableCell> Segment </StyledTableCell>
                  <StyledTableCell>Value Sales</StyledTableCell>
                  <StyledTableCell>Value Sales IYA</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {MFC.map((row, id) => {
                  return (
                    <StyledTableRow key={id}>
                      <StyledTableCell component="th" scope="row">
                        {row.brand}
                      </StyledTableCell>

                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          color: `${theme.palette.primary.main}`,
                          fontWeight: "bold",
                        }}
                      >
                        {row.valueShare}
                      </StyledTableCell>

                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          backgroundColor:
                            row.valueShareDYA < 100
                              ? `  #FF6565`
                              : row.valueShareDYA <
                                labels.map((a) => a.valueShareDYA)[
                                  MFC.map(function (img) {
                                    return img.valueShareDYA;
                                  }).indexOf(row.valueShareDYA)
                                ]
                              ? // labels.indexOf(row)
                                "orange"
                              : "rgba(65,221,100,0.5)",
                          fontWeight: "bold",
                        }}
                      >
                        {console.log(
                          MFC.map(function (img) {
                            return img.valueShareDYA;
                          }).indexOf(row.valueShareDYA)
                        )}
                        {row.valueShareDYA}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

const rows = [
  {
    name: "Afia Corn 1.5L",
    oldPrice: 21.88,
    newPrice: 22.88,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.110658401,
    revenue: 2107201.536,
    market: 0.066,
  },
  {
    name: "Afia Corn 1.5x2 + 0.5L",
    oldPrice: 54.23,
    newPrice: 52.23,
    oldSize: 3.5,
    newSize: 3.5,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia Corn 2.9L",
    oldPrice: 37.7,
    newPrice: 39.7,
    oldSize: 2.9,
    newSize: 3.9,
    value: 0.008965593,
    revenue: 170726.4,
    market: 0.006,
  },
  {
    name: "Afia Corn 9L",
    oldPrice: 95.5,
    newPrice: 94.5,
    oldSize: 9,
    newSize: 10,
    value: 0.00362072,
    revenue: 68947.2,
    market: 0.003,
  },
  {
    name: "Afia Corn 0.75L",
    oldPrice: 11.63,
    newPrice: 11.63,
    oldSize: 0.75,
    newSize: 0.75,
    value: 0.035647809,
    revenue: 678819.84,
    market: 0.02,
  },
  {
    name: "Afia SF 1.5L",
    oldPrice: 18.54,
    newPrice: 18.54,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.007103508,
    revenue: 135267.84,
    market: 0.005,
  },
  {
    name: "Al Arabi 17L",
    oldPrice: 102,
    newPrice: 102,
    oldSize: 17,
    newSize: 17,
    value: 0.008275932,
    revenue: 157593.6,
    market: 0.012,
  },
  {
    name: "Al Arabi 1.5x2L",
    oldPrice: 30.6,
    newPrice: 32.6,
    oldSize: 3,
    newSize: 4,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Al Arabi 2.9L",
    oldPrice: 28.62,
    newPrice: 28.62,
    oldSize: 2.9,
    newSize: 1.9,
    value: 0.004537494,
    revenue: 86404.7669,
    market: 0.004,
  },
  {
    name: "Dalal 17L",
    oldPrice: 90.1,
    newPrice: 90.1,
    oldSize: 17,
    newSize: 17,
    value: 0.018276016,
    revenue: 348019.2,
    market: 0.03,
  },
  {
    name: "Dalal 1.5L",
    oldPrice: 10.34,
    newPrice: 10.34,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.071310944,
    revenue: 1357931.52,
    market: 0.09,
  },
  {
    name: "Halah SF 1.5L",
    oldPrice: 12.3,
    newPrice: 12.3,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.013195513,
    revenue: 251274.24,
    market: 0.014,
  },
  {
    name: "Nakheel 1.5L",
    oldPrice: 13.06,
    newPrice: 13.06,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.01501162,
    revenue: 285857.28,
    market: 0.015,
  },
  {
    name: "Shams SF 1.5L",
    oldPrice: 16.04,
    newPrice: 16.04,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.045477777,
    revenue: 866006.016,
    market: 0.037,
  },
  {
    name: "Shams SF 1.5x2L",
    oldPrice: 30,
    newPrice: 30,
    oldSize: 3,
    newSize: 3,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Shams SF 2.9L",
    oldPrice: 28.6,
    newPrice: 28.6,
    oldSize: 2.9,
    newSize: 2.9,
    value: 0.007935065,
    revenue: 151102.6759,
    market: 0.007,
  },
  {
    name: "Shams SF 9L",
    oldPrice: 82.8,
    newPrice: 82.8,
    oldSize: 9,
    newSize: 9,
    value: 0.00105748,
    revenue: 20136.96,
    market: 0.001,
  },
  {
    name: "Noor SF 1.5x2 + 0.75L",
    oldPrice: 41.5,
    newPrice: 41.5,
    oldSize: 3.75,
    newSize: 3.75,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Noor SF 3L",
    oldPrice: 31.5,
    newPrice: 31.5,
    oldSize: 3,
    newSize: 3,
    value: 0.002413813,
    revenue: 45964.8,
    market: 0.002,
  },
  {
    name: "Nakhlatain 1.5L",
    oldPrice: 13.86,
    newPrice: 13.86,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.007434545,
    revenue: 141571.584,
    market: 0.007,
  },
  {
    name: "Sunny SF 1.5L",
    oldPrice: 13.23,
    newPrice: 13.23,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.057786692,
    revenue: 1100397.312,
    market: 0.057,
  },
  {
    name: "Hayat 1.5L",
    oldPrice: 10.55,
    newPrice: 10.55,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.03961336,
    revenue: 754333.44,
    market: 0.049,
  },
  {
    name: "Zahrati SF 1.5L",
    oldPrice: 12.66,
    newPrice: 12.66,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.076639724,
    revenue: 1459404.288,
    market: 0.079,
  },
  {
    name: "Shurooq SF 1.5L",
    oldPrice: 13.54,
    newPrice: 13.54,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.014525793,
    revenue: 276605.952,
    market: 0.014,
  },
  {
    name: "Al Arabi 1.5L",
    oldPrice: 16,
    newPrice: 16,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.04659043,
    revenue: 887193.6,
    market: 0.038,
  },
  {
    name: "Noor SF 1.5x2L",
    oldPrice: 38.02,
    newPrice: 38.02,
    oldSize: 3,
    newSize: 3,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Olite Canola 1.5x2L",
    oldPrice: 38.35,
    newPrice: 38.35,
    oldSize: 3,
    newSize: 3,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Noor SF 1.5L",
    oldPrice: 19.08,
    newPrice: 19.08,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.038014113,
    revenue: 723879.936,
    market: 0.026,
  },
  {
    name: "Afia Corn 1.5x2L",
    oldPrice: 50.79,
    newPrice: 50.79,
    oldSize: 3,
    newSize: 3,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia SF 1.5x2L",
    oldPrice: 41.49,
    newPrice: 41.49,
    oldSize: 3,
    newSize: 3,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Abu Zahra SF 1.8L",
    oldPrice: 16.08,
    newPrice: 16.08,
    oldSize: 1.8,
    newSize: 1.8,
    value: 0.004107314,
    revenue: 78213.12,
    market: 0.004,
  },
  {
    name: "Leeza 1.5L",
    oldPrice: 15.84,
    newPrice: 15.84,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0.00728282,
    revenue: 138682.368,
    market: 0.006,
  },
  {
    name: "Afia SF 2.9L",
    oldPrice: 33,
    newPrice: 33,
    oldSize: 2.9,
    newSize: 2.9,
    value: 0.001307978,
    revenue: 24907.03448,
    market: 0.001,
  },
  {
    name: "Leeza 2.9L",
    oldPrice: 28,
    newPrice: 28,
    oldSize: 2.9,
    newSize: 2.9,
    value: 0.003329398,
    revenue: 63399.72414,
    market: 0.003,
  },
  {
    name: "Nasreen 17L",
    oldPrice: 79.9,
    newPrice: 79.9,
    oldSize: 17,
    newSize: 17,
    value: 0.007563282,
    revenue: 144023.04,
    market: 0.014,
  },
  {
    name: "Shurooq SF 3L",
    oldPrice: 27,
    newPrice: 27,
    oldSize: 3,
    newSize: 3,
    value: 0.002068983,
    revenue: 39398.4,
    market: 0.002,
  },
  {
    name: "Mazola Corn 1.5L",
    oldPrice: 19.74,
    newPrice: 19.74,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Noor SF 9L",
    oldPrice: 114.46,
    newPrice: 114.46,
    oldSize: 9,
    newSize: 9,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia Corn Plus 1.5L",
    oldPrice: 29,
    newPrice: 29,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia Corn Plus 1.5x2L",
    oldPrice: 46,
    newPrice: 46,
    oldSize: 3,
    newSize: 3,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia Corn Plus 2.9L",
    oldPrice: 52,
    newPrice: 52,
    oldSize: 2.9,
    newSize: 2.9,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia Corn 1.5L NEW",
    oldPrice: 21.88,
    newPrice: 22.88,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia Corn 1.5x2 + 0.5L NEW",
    oldPrice: 54.23,
    newPrice: 54.23,
    oldSize: 3.5,
    newSize: 3.5,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia Corn 2.9L NEW",
    oldPrice: 37.7,
    newPrice: 37.7,
    oldSize: 2.9,
    newSize: 2.9,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia SF 1.5L NEW",
    oldPrice: 18.54,
    newPrice: 18.54,
    oldSize: 1.5,
    newSize: 1.5,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia Corn 1.5x2L NEW",
    oldPrice: 50.79,
    newPrice: 50.79,
    oldSize: 3,
    newSize: 3,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia SF 1.5x2L NEW",
    oldPrice: 41.49,
    newPrice: 41.49,
    oldSize: 3,
    newSize: 3,
    value: 0,
    revenue: 0,
    market: 0,
  },
  {
    name: "Afia SF 2.9L NEW",
    oldPrice: 37,
    newPrice: 37,
    oldSize: 2.9,
    newSize: 2.9,
    value: 0,
    revenue: 0,
    market: 0,
  },
];

export function SimulatorTable() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.action.hover,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      textAlign: "left",
      fontFamily: "Barlow Semi Condensed",
      padding: "5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
      padding: "5px",
      fontFamily: "Barlow Semi Condensed",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <>
      <Paper
        sx={{
          p: 3,
          mt: 2,
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StyledTitleTypography sx={{ mt: 2, mb: 3, fontSize: "20px" }}>
            Main Simulator{" "}
          </StyledTitleTypography>
          <Box sx={{ display: "flex", m: 1 }}>
            <Box
              sx={{
                border: `1px solid ${theme.palette.primary.light}`,
                borderRadius: "4px",
                margin: "3px",
                p: 1,
              }}
            >
              <FileUploadIcon
                sx={{ color: `${theme.palette.primary.light}` }}
              />
            </Box>

            <Box
              sx={{
                border: `1px solid ${theme.palette.primary.light}`,
                borderRadius: "4px",
                margin: "3px",
                p: 1,
              }}
            >
              <PublishIcon sx={{ color: `${theme.palette.primary.light}` }} />
            </Box>
            <Box
              sx={{
                border: `1px solid ${theme.palette.primary.light}`,
                borderRadius: "4px",
                margin: "3px",
                p: 1,
              }}
            >
              <RefreshIcon sx={{ color: `${theme.palette.primary.light}` }} />
            </Box>

            <Box
              sx={{
                border: `1px solid ${theme.palette.primary.light}`,
                borderRadius: "4px",
                margin: "3px",
                p: 1,
              }}
            >
              <ReplayIcon sx={{ color: `${theme.palette.primary.light}` }} />
            </Box>
            <Box
              sx={{
                border: `1px solid ${theme.palette.primary.light}`,
                borderRadius: "4px",
                margin: "3px",
                p: 1,
              }}
            >
              <SaveOutlinedIcon
                sx={{ color: `${theme.palette.primary.light}` }}
              />
            </Box>
            <Box
              sx={{
                border: `1px solid ${theme.palette.primary.light}`,
                borderRadius: "4px",
                margin: "3px",
                p: 1,
              }}
            >
              <AddShoppingCartOutlinedIcon
                sx={{ color: `${theme.palette.primary.light}` }}
              />
            </Box>
          </Box>
        </Box>

        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead sx={{ height: "10px" }}>
              <TableRow>
                <StyledTableCell> SKUs </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: "bold" }}>
                  base_price (SAR)
                </StyledTableCell>
                <StyledTableCell>new_price (SAR)</StyledTableCell>

                <StyledTableCell>Base Size (L)</StyledTableCell>
                <StyledTableCell>New Size (L)</StyledTableCell>
                <StyledTableCell>Market Share (%)</StyledTableCell>
                <StyledTableCell>Value Share (%)</StyledTableCell>
                <StyledTableCell>Revenue (SAR)</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      color: `${theme.palette.primary.main}`,
                      fontWeight: "bold",
                    }}
                  >
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell>{row.oldPrice}</StyledTableCell>

                  <StyledTableCell
                    sx={{
                      color:
                        row.newPrice > row.oldPrice
                          ? "#41DD64"
                          : row.oldPrice > row.newPrice
                          ? "red"
                          : "black",
                    }}
                  >
                    {row.newPrice}
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>{row.oldSize}</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color:
                        row.newSize > row.oldSize
                          ? "#41DD64"
                          : row.newSize < row.oldSize
                          ? "red"
                          : "black",
                    }}
                  >
                    {row.newSize}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ color: row.market * 100 > 10 ? "#41DD64" : "red" }}
                  >
                    {(row.market * 100).toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ color: row.value * 100 > 10 ? "#41DD64" : "red" }}
                  >
                    {(row.value * 100).toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color:
                        Math.floor(row.revenue) > 100000 ? "#41DD64" : "red",
                    }}
                  >
                    {Math.floor(row.revenue).toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export function SizeShare() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.action.hover,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      textAlign: "left",
      fontFamily: "Barlow Semi Condensed",
      padding: "5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
      padding: "5px",
      fontFamily: "Barlow Semi Condensed",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <>
      <Paper
        sx={{
          p: 3,
          mt: 2,
          mb: 3,
        }}
      >
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "gray",
              mr: 2,
              p: 1,
              writingMode: "vertical-lr",
              textOrientation: "mixed",
            }}
          >
            Value Share
          </Box>
          <Box sx={{ backgroundColor: "red" }}>Value Share</Box>
        </Stack>
        <Stack direction="row">
          <Box
            sx={{
              backgroundColor: "#D0CECE",
              mr: 2,
              mt: 2,
              p: 1,
              writingMode: "vertical-lr",
              textOrientation: "mixed",
              fontWeight: "bold",
            }}
          >
            Value Sales IYA
          </Box>
          <Box sx={{ backgroundColor: "red", mt: 2 }}>Value Share</Box>
        </Stack>

        <Stack direction="row" sx={{ position: "relative" }}>
          <Box
            sx={{
              backgroundColor: "#D0CECE",
              fontWeight: "bold",
              mr: 2,
              mt: 2,
              p: 1,
              writingMode: "vertical-lr",
              textOrientation: "mixed",
            }}
          >
            Unit Sales IYA
          </Box>
          <Box
            sx={{
              backgroundColor: "red",
              mt: 2,
              height: "50px",
              width: "80px",
              // position: "absolute",
              bottom: 0,
            }}
          ></Box>
        </Stack>
      </Paper>
    </>
  );
}

export function TablePopUp({ data }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "1px",
    fontSize: "1rem",
    [`&.${tableCellClasses.head}`]: {
      fontWeight: "bold",
      backgroundColor: "#F8F9FA",
      textAlign: "center",
      padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
      padding: "5px",
      // fontFamily: "Barlow Semi Condensed",
    },
  }));

  return (
    <TableContainer sx={{ maxHeight: 250, marginTop: "15px" }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <StyledTableCell>SKU</StyledTableCell>

            <StyledTableCell>Old price(SAR)</StyledTableCell>
            <StyledTableCell>new_price(SAR)</StyledTableCell>

            <StyledTableCell>Old Volume share(SAR)</StyledTableCell>
            <StyledTableCell>New Volume share(SAR)</StyledTableCell>

            <StyledTableCell>Old Revenue</StyledTableCell>
            <StyledTableCell>New Revenue</StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {data?.data?.map((a) => {
            return (
              <TableRow>
                {/* sku */}
                <StyledTableCell sx={{ fontWeight: "bold" }}>
                  {a["SKU Name"]}
                </StyledTableCell>
                {/* price */}
                <StyledTableCell>{a["base_price"].toFixed(2)}</StyledTableCell>

                <StyledTableCell>
                  <Box
                    sx={{
                      width: "40%",
                      margin: "auto",
                      backgroundColor:
                        a["base_price"] > a["new_price"]
                          ? "#F4C0C0"
                          : a["base_price"] === a["new_price"]
                          ? ""
                          : "#C6F5D1",
                      color:
                        a["base_price"] > a["new_price"]
                          ? "#DA3535"
                          : a["base_price"] === a["new_price"]
                          ? ""
                          : "#6ED485",
                      borderRadius: "20px",
                    }}
                  >
                    {a["new_price"].toFixed(2)}
                  </Box>
                </StyledTableCell>
                {/* market share */}
                <StyledTableCell>
                  <Box>{a["base_market_share"].toFixed(2)}</Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box
                    sx={{
                      width: "40%",
                      margin: "auto",
                      backgroundColor:
                        a["base_market_share"] > a["new_market_share"]
                          ? "#F4C0C0"
                          : a["base_market_share"] === a["new_market_share"]
                          ? ""
                          : "#C6F5D1",
                      color:
                        a["base_market_share"] > a["new_market_share"]
                          ? "#DA3535"
                          : a["base_market_share"] === a["new_market_share"]
                          ? ""
                          : "#6ED485",
                      borderRadius: "20px",
                    }}
                  >
                    {a["new_market_share"].toFixed(2)}
                  </Box>
                </StyledTableCell>

                {/*revenue */}
                <StyledTableCell>
                  {a["base_Sales"].toFixed(2)}
                </StyledTableCell>
                <StyledTableCell>
                  <Box
                    sx={{
                      width: "80%",
                      margin: "auto",
                      backgroundColor:
                        a["base_Sales"] > a["new_Sales"]
                          ? "#F4C0C0"
                          : a["base_Sales"] === a["new_Sales"]
                          ? ""
                          : "#C6F5D1",
                      color:
                        a["base_Sales"] > a["new_Sales"]
                          ? "#DA3535"
                          : a["base_Sales"] === a["new_Sales"]
                          ? ""
                          : "#6ED485",
                      borderRadius: "20px",
                    }}
                  >
                    {a["new_Sales"].toFixed(2)}
                  </Box>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
