import { Box, Container, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import Navbar from "../Components/Login/Navbar";
import {
  StyledModulesDescription,
  StyledModulesTitle,
} from "../Styled/Typography";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { StyledButtonModules } from "../Styled/Button";
import { StyledModules } from "../Styled/Stack";
import DecisionImage from "../Assets/Decision.png";
import KnowledgeImage from "../Assets/Knowledge.png";
import { useNavigate } from "react-router-dom";
import CardModule from "../Components/Modules/Card";

const Modules = () => {
  const navigate = useNavigate();
  const handleKnowledge = () => {
    navigate(localStorage.getItem("active") || "dashboard");
  };
  return (
    <>
      <Navbar />
      <StyledModules>
        <Container>
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Box>
              <StyledModulesTitle
                component="h1"
                sx={{ margin: "10px 0px 10px 0px" }}
              >
                Smart Shopper
              </StyledModulesTitle>
              <StyledModulesDescription component="p">
                We help consumer product companies manage and optimist their
                customer pricing and investments​ to make better decisions and
                deliver ROI , Select from 2 Options:{" "}
              </StyledModulesDescription>
            </Box>
            <Box>
              <StyledButtonModules
                variant="outlined"
                startIcon={<BackupOutlinedIcon />}
              >
                Upload
              </StyledButtonModules>
            </Box>
          </Stack>

          <Grid
            container
            spacing={2}
            align="center"
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={6}>
              <CardModule
                title="Knowledge and Insight"
                description="By integrating data from various sources, you can gain a 
                comprehensive view of market performance. This enables you to identify gaps
                 in your strategy and uncover new opportunities with 
                meaningful insights that contribute to decisions for business growth."
                img={KnowledgeImage}
                handleClick={handleKnowledge}
              />
            </Grid>

            <Grid item md={6}>
              <CardModule
                title="Decision Alternative"
                description="Reduce decision risks by predicting consumer behavior through testing 
                different decision alternatives and market scenarios to optimize growth 
                and make informed choices that drive business success."
                img={DecisionImage}
                handleClick={handleKnowledge}
              />
            </Grid>
          </Grid>
        </Container>
      </StyledModules>
    </>
  );
};

export default Modules;
