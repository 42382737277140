import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ChartValueSalesandVolumeSales } from "../Chart.jsx";
import { StyledTitleTypography } from "../../Styled/Typography.jsx";
import { useEffect } from "react";
import {
  ValueSalesVolumeSalesMKT,
  ValueSalesVolumeSalesMKTChart,
} from "../Network/Lib/Sales.js";
import { useState } from "react";
import { Fragment } from "react";
import {Loading} from "../../Components/Loading.jsx"
import {theme} from '../../Theme'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.main,
    padding: "5px",
    textAlign: "center"
   
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "2px",
    textAlign: "center",

    fontSize: 12,
  },
}));

const ValueSalesandVolumeSales = () => {
  //Table
  const [years, setYears] = useState([]);
  // const [valueShare, setValueShare] = useState([]);
  const valueShare=["1,261","1,322","1,442"]
  // const [volumeShare, setVolumeShare] = useState([]);
  const volumeShare=[294,295,178]
  // const [valueGrowth, setValueGrowth] = useState([]);
  const valueGrowth=['','1.04 %','1.09 %']
  const volumeGrowth = ['-','1.004','0.84'];

  //chart
  const [months, setMonths] = useState([]);
  const [valueShareChart, setValueShareChart] = useState();
  const [volumeShareChart, setVolumeShareChart] = useState();
  useEffect(() => {
    ValueSalesVolumeSalesMKT().then((response) => {
      setYears(response.data.years);
      // setValueShare(response.data.value_sales);
      // setVolumeShare(response.data.volume_sales);
      // setValueGrowth(response.data.value_growth);
      // setVolumeGrowth(response.data.volume_growth);
    });

    ValueSalesVolumeSalesMKTChart().then((response) => {
      setMonths(response.data.months);
      setValueShareChart(response.data.value_sales.map((a) => Math.floor(a/10)));
      setVolumeShareChart(response.data.volume_sales.map((a) =>Math.floor(a/100)));
      console.log(valueShareChart);
    });
  }, []);

  return (
    <Paper
      sx={{
        p: 1.2,
        mt: 2,
        boxShadow:'none'
      }}
    >
      <StyledTitleTypography>
      Sales trend on value vs volume
      </StyledTitleTypography>
      <TableContainer
        sx={{ mt: 3, borderRadius: "10px" }}
      >
        <Table sx={{ minWidth: 600 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>

              {years.map((year) => {
                return (
                  <Fragment key={year}>
                    <StyledTableCell sx={{borderLeft:"1px dashed gray"}}>FY{year}</StyledTableCell>
                  </Fragment>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell sx={{width:"100px"}}>Value (M SAR)</StyledTableCell>

              {valueShare.map((value) => {
                return (
                  <Fragment key={value}>
                    <StyledTableCell sx={{borderLeft:"1px dashed gray",paddingLeft:"20px"}}>{value}</StyledTableCell>
                  </Fragment>
                );
              })}
            </TableRow>
            <TableRow sx={{ backgroundColor: "ButtonFace" }}>
                    
              <StyledTableCell sx={{width:"10px",padding:0,color:`${theme.palette.primary.light}`}}>Value Growth</StyledTableCell>

              {valueGrowth.map((value) => {
                return (
                  <Fragment key={value}>
                    <StyledTableCell sx={{borderLeft:"1px dashed gray",color:`${theme.palette.primary.light}`}}>
                      {value == "-" ? " " : `${(value)}`}
                    </StyledTableCell>
                  </Fragment>
                );
              })}
            </TableRow>
            <TableRow>
              <StyledTableCell>Volume(M Kg) </StyledTableCell>

              {volumeShare.map((volume) => {
                return (
                  <Fragment key={volume}>
                    <StyledTableCell sx={{borderLeft:"1px dashed gray"}}>{(volume)}</StyledTableCell>
                  </Fragment>
                );
              })}
            </TableRow>

            <TableRow sx={{ backgroundColor: "ButtonFace" }}>
              <StyledTableCell sx={{color:`${theme.palette.primary.light}`}}>Volume Growth</StyledTableCell>

              {volumeGrowth.map((value) => {
                return (
                  <Fragment key={value}>
                    <StyledTableCell sx={{borderLeft:"1px dashed gray",color:`${theme.palette.primary.light}`}}>
                      {value == "-" ? " " : `${parseFloat(value).toFixed(2)}%`}{" "}
                    </StyledTableCell>
                  </Fragment>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {valueShareChart ? (
        <>
          {" "}
          <ChartValueSalesandVolumeSales
            valueSales={valueShareChart}
            volumeSales={volumeShareChart}
            labels={months}
          />
        </>
      ) : (
       <>
       <Loading/>
       </>
      )}
    </Paper>
  );
};

export default ValueSalesandVolumeSales;
