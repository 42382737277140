import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import {
  bestOptimization,
  brandOptimization,
  skuOptimization,
} from "../Components/Network/Lib/Simulator";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField/TextField";
import deleteEdit from "../Assets/delete.svg";
import ImgNoData from "../Assets/noData.png";
import { toastError, toastSuccess } from "../Utils/Toast";
import { ToastContainer } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Loading } from "../Components/Loading";
import {Grid} from "@mui/material";
import { Loading2 } from "../Components/Loading";


import {
  ChartDountMarketShare,
  ChartDountValueShare,
  ChartDountRevenue,
} from "../Components/Chart";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import { TablePopUp } from "../Components/Table";
import { useRef } from "react";

import AddIcon from '@mui/icons-material/Add';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Optimization = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "1px",
    fontSize: "1.2rem",
    [`&.${tableCellClasses.head}`]: {
      fontWeight: "bold",
      backgroundColor: theme.palette.action.hover,
      textAlign: "center",
      padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      fontSize: "1rem",

      textAlign: "center",
      padding: "0px",
      // border: "5px solid gray"
      // fontFamily: "Barlow Semi Condensed",
    },
  }));

  const [valueBrand, setValueBrand] = useState("");
  const [objective, setObjective] = useState("");
  const [brand, setBrand] = useState([]);
  const [skus, setSKUs] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);

  const inputRef = useRef([]);
  const [flag, setFlag] = useState(true);
  const [open, setOpen] = useState(false);
  const [tableDataList, setTableDataList] = useState([]);
  const [resultBestOptimization, setResultBestOptimization] = useState({});
  const [id, setID] = useState(false);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  var i = 1;
  useEffect(() => {
    brandOptimization().then((response) => {
      setBrand(response.data.brands);
    });
  }, []);

  const handleBrand = (e) => {
    var arr = [];
    setTableDataList([]);
    setValueBrand(e.target.value);

    skuOptimization(e.target.value).then((response) => {
      response.data.map((a) => {
        arr.push({
          id: i++,
          sku: a[0],
          price_decrease: Number(0),
          interval: Number(0),
        });
      });

      setSKUs(arr);
      setSelectedSkus(arr)
      inputRef.current = arr;
    });
  };

  const ref = useRef("");

  const handleChange = (e, row, key) => {
    e.preventDefault();
    let newArr = [...selectedSkus];
    if(key === "price_decrease"){
      for (const i in newArr) {
        if (newArr[i]["id"] === row) {
          if(parseInt(e.target.value) > 0) {
            newArr[i]["price_decrease"] = Number(e.target.value)*-1;
          }
          else{
            console.log("negative")
            newArr[i]["price_decrease"] =  Number(e.target.value);
          }
        }
      }
      
    }
    else{
      for (const i in newArr) {
        if (newArr[i]["id"] === row) {
            newArr[i]["interval"] = Number(e.target.value);
        }
      }
    }
    console.log(newArr);
  };

  const handleObjective = (e) => {
    setObjective(e.target.value);
    console.log(e.target.value);
  };
  const handleDelete = (row) => {
    console.log(selectedSkus, row)
    toastSuccess("You have successfully deleted");
    setSelectedSkus(selectedSkus.filter((item) => item.id !== row));
    console.log(selectedSkus);
  };
  const handleOptimization = () => {
    // const lastChangedValues = {};
    if (valueBrand == "") {
      toastError("you Must choose brand");
    } else {
      if (objective == "") {
        toastError("you Must choose objective");

      } else {
        console.log(selectedSkus);
        bestOptimization(selectedSkus, objective).then((response) => {
          setResultBestOptimization(response.data);
          console.log(response.data);
          setFlag(false);
        });
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFlag(true);

    setResultBestOptimization({});
    console.log(resultBestOptimization);
  };

  return (
    <>
      <ToastContainer />
      <Typography
        sx={{
          color: `${theme.palette.primary.main}`,
          pt: 3,
        
          fontSize: "20px",
          fontWeight: 700,
        }}
      >
        Optimization
      </Typography>

      <Box
        sx={{
          fontSize: "1.1rem",
          marginBottom: "15px",
          pb: 1,
          color: "#6D6D6D",
        }}
      >
        Turn your data into a competitive advantage by making actionable
        decisions that optimize your business operations.
      </Box>
      <Paper>
        <Grid container spacing={2}>
        <Grid  item xs={6}>
        <Item elevation={4} style={{marginBottom: "20px", marginLeft: "20px"}}>
        <Stack direction="row">
          <Box sx={{ m: 1, ml: 3 }}>
            <Typography sx={{ fontWeight: "bold", p: 1, color: "#757575" }}>
              Brand
            </Typography>
            <FormControl sx={{ minWidth: 200, ml: 1 }} size="small">
              <Select
                value={valueBrand}
                onChange={handleBrand}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {brand.map((brand, index) => {
                  return (
                    <MenuItem key={index} value={brand}>
                      {brand}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ m: 1, ml: 3 }}>
            <Typography sx={{ fontWeight: "bold", p: 1, color: "#757575" }}>
              Objective
            </Typography>
            <FormControl sx={{ minWidth: 200, ml: 1 }} size="small">
              <Select
                value={objective}
                onChange={(e) => handleObjective(e)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="new_market_share">Market Share</MenuItem>
                <MenuItem value="new_value_share">Value Share</MenuItem>
                <MenuItem value="new_value_sales">Penetration</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>

        <TableContainer sx={{ marginTop: "15px",}}>
          <Table sx={{ minWidth: 650, height: 420}} aria-label="simple table">
            <TableHead>
              <TableRow sx={{fontSize: "0.9rem"}}>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>SKU</StyledTableCell>

                <StyledTableCell> PRICE CHANGE </StyledTableCell>
                <StyledTableCell> INTERVAL</StyledTableCell>

                <StyledTableCell>ACTIONS</StyledTableCell>
              </TableRow>
            </TableHead>
            {skus?.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                    <Box component="img" src={ImgNoData} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {selectedSkus?.map((row) => (
                  <>
                    <TableBody>
                      <TableRow
                        key={row?.id}
                        sx={{ borderBottom: `1px solid #E4E4E4` }}
                      >
                        <StyledTableCell align="right">
                          {row?.id}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontWeight: "bold" }}
                        >
                          {row?.sku}
                        </StyledTableCell>

                        <StyledTableCell>
                          <Box
                            component="span"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "5px",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              size="small"
                              type="number"
                              sx={{
                                width: "80px",
                                ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                                  {
                                    height: "0.6rem",
                                  },
                              }}
                              // value={row.price_decrease}
                              name="price_decrease"
                              onChange={(e) =>
                                handleChange(e, row.id, "price_decrease")
                              }
                            />
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box
                            component="span"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "5px",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              type="number"
                              size="small"
                              sx={{
                                width: "80px",
                                ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                                  {
                                    height: "0.6rem",
                                  },
                              }}
                              // value={row?.interval}
                              ref={ref}
                              onChange={(e) =>
                                handleChange(e, row.id, "interval")
                              }
                            />
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <div>
                            <Box
                              component="img"
                              sx={{cursor:"pointer"}}
                              src={deleteEdit}
                              onClick={() => {
                                handleDelete(row.id);
                              }}
                            />
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </>
                ))}
              </>
            )}
          </Table>
        </TableContainer>
        {objective !== "" ? (
            <>
              <Typography
                variant="outlined"
                onClick={() => {
                  handleClickOpen();
                  handleOptimization();
                }}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  
                  },
                }}
              >
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize", fontWeight: "bold", m: 3 , justifyContent: "center"}}
                >
                  Apply{" "}
                </Button>
              </Typography>
            </>
          ) : (
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize", fontWeight: "bold", m: 3 }}
              onClick={() => handleOptimization()}
            >
              Apply{" "}
            </Button>
          )}
          </Item>
        </Grid>
        <Grid item xs={6}>
        <Typography
                  sx={{
                    color: `${theme.palette.primary.main}`,
                    fontSize: "20px",
                    fontWeight: 700,
                   
                  }}
                >
                  Company Performance
                </Typography>
                {loading?
                (<Item elevation={4} style={{marginBottom: "10px", marginRight:"20px", height:"335px", justifyContent:"center"}}>
                    <Typography>
                      Please apply a scenario to show the results
                    </Typography>
                  </Item>):
                  (
                    <>
                    <Item elevation={4} style={{marginBottom: "10px", marginRight:"20px"}}>
                    {flag ? (
                        <>
                          <Loading2 />
                        </>
                      ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <ChartDountMarketShare
                              marketShare={(
                                resultBestOptimization?.new_vol * 100
                              )?.toFixed(2)}
                              currentmarketShare={Math.floor(
                                resultBestOptimization?.current_vol * 100
                              )}
                            />
                        </Grid>
                      <Grid item xs={6}>
                          <ChartDountValueShare
                            valueShare={(
                              resultBestOptimization?.new_val * 100
                            )?.toFixed(2)}
                            currentvalueShare={Math.floor(
                              resultBestOptimization?.current_val * 100
                            )}
                          />
                      </Grid>
                    </Grid>
                      )}
                    </Item>
                  
                    <Item elevation={4} style={{marginBottom: "20px", marginRight:"20px"}}>
                    {flag ? (
                        <>
                          <Loading2 />
                        </>
                      ) : (
                          <ChartDountRevenue
                            revenue={resultBestOptimization?.new_rev?.toFixed(2)}
                            currentrevenue={Math.floor(
                              resultBestOptimization?.curr_rev * 100
                            )}
                            revenueperc={resultBestOptimization?.new_rev_perc}
                            currentrevenueperc={resultBestOptimization?.curr_rev_perc}
                          />
                      )}

                    </Item>
                    </>
                    )}
          <Typography
                  sx={{
                    color: `${theme.palette.primary.main}`,
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                >
                  Main Competitor
            </Typography>
            {loading ?
                (<Item elevation={4} style={{marginBottom: "10px", marginRight:"20px", height:"225px", justifyContent:"center"}}>
                    <Typography>
                      Please apply a scenario to show the results
                    </Typography>
                  </Item>):
                  (
          <Item elevation={4} style={{marginBottom: "20px", marginRight:"20px"}}>
          {flag ? (
                        <>
                          <Loading2 />
                        </>
                      ) : (
                        <>
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "15px",
                            fontWeight: 500
                          }}
                        style={{textAlign: "left"}}>
                          Samsung
                        </Typography>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Brand</TableCell>
                                <TableCell align="right">Sales</TableCell>
                                <TableCell align="right">Market Share</TableCell>
                                <TableCell align="right">Volume Share</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                  key={0}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    Samsung
                                  </TableCell>
                                  <TableCell align="right">100M</TableCell>
                                  <TableCell align="right">50%</TableCell>
                                  <TableCell align="right">60%</TableCell>
                                </TableRow>
                                <TableRow
                                  key={1}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    Samsung
                                  </TableCell>
                                  <TableCell align="right">100M</TableCell>
                                  <TableCell align="right">50%</TableCell>
                                  <TableCell align="right">60%</TableCell>
                                </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button disabled={flag} onClick={()=>{setShow(true)}}>More Details</Button>
                        </>
                      )}
                
                  </Item>
                  )}
          
        </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          

          <Dialog
            fullScreen={fullScreen}
            open={open}
            maxWidth="xl"
            aria-labelledby="responsive-dialog-title"
          >
            {flag ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                <DialogContent>
                  <Stack
                    direction="row"
                    gap={2}
                    sx={{
                      flexWrap: "wrap",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <ChartDountMarketShare
                      marketShare={(
                        resultBestOptimization?.new_vol * 100
                      )?.toFixed(2)}
                      currentmarketShare={Math.floor(
                        resultBestOptimization?.current_vol * 100
                      )}
                    />
                    <ChartDountValueShare
                      valueShare={(
                        resultBestOptimization?.new_val * 100
                      )?.toFixed(2)}
                      currentvalueShare={Math.floor(
                        resultBestOptimization?.current_val * 100
                      )}
                    />
                    <ChartDountRevenue
                      revenue={resultBestOptimization?.new_rev?.toFixed(2)}
                      currentrevenue={Math.floor(
                        resultBestOptimization?.curr_rev * 100
                      )}
                      revenueperc={resultBestOptimization?.new_rev_perc}
                      currentrevenueperc={resultBestOptimization?.curr_rev_perc}
                    />
                  </Stack>
                  <TablePopUp data={resultBestOptimization} />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    sx={{
                      border: "1px solid gary",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      mt: 1,
                      mb: 1,
                      color: "gray",
                      border: "1ps solid red",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      m: 1,
                    }}
                    startIcon={<FileDownloadOutlinedIcon />}
                  >
                    Download
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </Box>
      </Paper>
    </>
  );
};

export default Optimization;
