import axiosClient from "../apiClient";

export async function getMarketKPIs() {
  const response = await axiosClient.get("/landscape/mkt_kpis");
  return response;
}

export async function getMFCKPIs() {
  const response = await axiosClient.get("/landscape/mfc_kpis");
  return response;
}
export async function ValueSalesVolumeSalesMKT() {
  const response = await axiosClient.get("/landscape/mkt_over_years");
  return response;
}

export async function ValueSalesVolumeSalesMKTChart() {
  const response = await axiosClient.get("/landscape/mkt_over_months");
  return response;
}

export async function RGMIndex() {
  const response = await axiosClient.get("/landscape/brands_rgm");
  return response;
}

export async function AssortmentApi() {
  const response = await axiosClient.get("/landscape/assortment");
  return response;
}

export async function MarketSegment() {
  const response = await axiosClient.get("/landscape/mkt_segments");
  return response;
}

export async function MFCSegment() {
  const response = await axiosClient.get("/landscape/mfc_segments");
  return response;
}

export async function PPALadders() {
  const response = await axiosClient.get("/landscape/ppa");
  return response;
}

export async function PPAMFC() {
  const response = await axiosClient.get("/landscape/mfc_ppa");
  return response;
}

export async function priceIndexApi() {
  const response = await axiosClient.get("/landscape/brands_price_change");
  return response;
}
