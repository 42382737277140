import React from "react";
import {
  Grid,
  Dropdown,
  Button,
  Segment,
  Header,
  Popup,
} from "semantic-ui-react";
function SelectSegment({
  isFetching,
  segments,
  currentSegment,
  renderLabel1,
  handleSearchChange,
  handleSegmentChange,
  handleApplySegment,
}) {
  return (
    <Grid.Column className="select-segment-container">
      <Segment loading={isFetching} className="select-segment">
        <Popup
          trigger={
            <Header
              as="h5"
              icon="sitemap"
              content="Select Segment"
              className="select-segment"
            />
          }
          content="Select Segment"
        />
        <Dropdown
          multiple
          search
          floating
          selection
          options={segments}
          value={currentSegment}
          placeholder="Select Segment"
          disabled={isFetching}
          labeled={renderLabel1}
          onSearchChange={handleSearchChange}
          onChange={handleSegmentChange}
          className="db-select-segment"
        />
        <Button
          variant="contained"
          basic
          color="blue"
          value="Apply Segment"
          onClick={handleApplySegment}
          className="btn-select-segment"
          style={{
            padding: 10,
            opacity: currentSegment ? 1 : 0.5,
            cursor: currentSegment ? "pointer" : "not-allowed",
          }}
          disabled={!currentSegment.length}
        >
          Apply
        </Button>
      </Segment>
    </Grid.Column>
  );
}

export default SelectSegment;
