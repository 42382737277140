import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import chroma from "chroma-js";
import React from "react";

function PieChartComponent({ data }) {
  console.log(data);
  const size = {
    width: 400,
    height: 200,
  };
  // Function to generate a gradient between two colors
  const generateGradient = (color1, color2, steps) => {
    const scale = chroma.scale([color1, color2]);
    return scale.colors(steps);
  };

  // Usage in your component
  const palette = generateGradient("#9edcfe", "#00265e", data.length); // Adjust the number of steps as needed

  return (
    <PieChart
      colors={palette}
      series={[
        {
          data,
          // arcLabel: (item) => `${item.label}`,
          innerRadius: 1,
          outerRadius: 95,
          paddingAngle: 1,
          cornerRadius: 5,
          startAngle: -180,
          endAngle: 180,
          cx: 150,
          cy: 95,
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
          fontWeight: "bold",
          backgroundColor: "lightblue", // Change the background color here
        },
      }}
      {...size}
    />
  );
}

export default PieChartComponent;
