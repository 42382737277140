import React from "react";
import Dropzone from "react-dropzone";
import {
  Container,
  Grid,
  Button,
  Icon,
  Segment,
  Header,
  Modal,
  Step,
  Dimmer,
  Loader,
} from "semantic-ui-react";

function AddScenario({
  addScenario,
  setState,
  isFileready,
  isUploading,
  onDrop,
  files,
  handleScenario,
}) {
  return (
    <Container>
      <Modal
        centered={true}
        open={addScenario}
        onClose={() => setState({ addScenario: false })}
        onOpen={() => setState({ addScenario: true })}
      >
        <Modal.Header>Add Scenario</Modal.Header>
        <Modal.Content>
          <Grid centered>
            <Step.Group size="small">
              <Step>
                <Icon name="file excel" />
                <Step.Content>
                  <Step.Title>Scenario</Step.Title>
                  <Step.Description>Add your scenario file</Step.Description>
                </Step.Content>
              </Step>

              <Step disabled={!isFileready}>
                <Icon name="upload" />
                <Step.Content>
                  <Step.Title>Uploading</Step.Title>
                  <Step.Description>Uploading the scenario</Step.Description>
                </Step.Content>
              </Step>
            </Step.Group>
          </Grid>
          <Segment placeholder>
            <Dimmer active={isUploading} inverted>
              <Loader indeterminate></Loader>
            </Dimmer>
            <Segment>
              {!isFileready ? (
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="ContainerDrag">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        {files?.length === 0 ? (
                          <p>
                            Drag 'n' drop Scenario file here, or click to select
                            the Scenario file
                          </p>
                        ) : (
                          <p>{files[0]?.name}</p>
                        )}
                        <Button inverted color="blue">
                          Select Scenario file
                        </Button>
                      </div>
                      <aside></aside>
                    </section>
                  )}
                </Dropzone>
              ) : (
                <Header textAlign="center" style={{ color: "blue" }}>
                  <Icon name="settings" />
                  <Header.Content>
                    Scenario Name
                    <Header.Subheader>{files[0]?.name}</Header.Subheader>
                  </Header.Content>
                </Header>
              )}
            </Segment>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={!isFileready} color="blue" onClick={handleScenario}>
            Next
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}

export default AddScenario;
