import React from "react";
import {
  Grid,
  Icon,
  Segment,
  Header,
  Accordion,
  Transition,
  Button,
} from "semantic-ui-react";
import { RevoGrid } from "@revolist/revogrid-react";
import { Box } from "@mui/material";
import BUTTONMUI from "@mui/material/Button";

function SummaryGrid({
  summaryToggle,
  isFetching,
  summaryClick,
  columnsSummary,
  columnTypes,
  rowData1,
}) {
  const revoSummary = {
    height: "500px",
    width: "auto",
  };
  return (
    <Grid.Column width={7}>
      {summaryToggle ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
          <BUTTONMUI
            basic={summaryToggle}
            disabled={isFetching}
            loading={isFetching}
            active={summaryToggle}
            onClick={summaryClick}
            variant="contained"
            disableElevation
            sx={{
              width: "50%",
              bgcolor: "#A7D0F1",
              color: "#000000DE",
              fontWeight: "bold",
            }}
          >
            Summary
          </BUTTONMUI>
        </Box>
      ) : (
        <Box sx={{ width: "50%", margin: "0 auto", mt: "20px" }}>
          <Accordion>
            <Accordion.Content active={!summaryToggle}>
              <Transition
                visible={!summaryToggle}
                animation="scale"
                duration={500}
              >
                <Segment tertiary loading={isFetching}>
                  <Grid columns={2}>
                    <Grid.Column>
                      <Header style={{ color: "#00265e" }} as="h2">
                        <Icon name="chart bar" />
                        <Header.Content>Summary</Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        basic
                        color="blue"
                        size="mini"
                        icon
                        floated="right"
                        onClick={summaryClick}
                      >
                        <Icon name="window minimize" />
                      </Button>
                    </Grid.Column>
                  </Grid>
                  <RevoGrid
                    resize="true"
                    style={revoSummary}
                    colSize="100"
                    theme="compact"
                    range="true"
                    columns={columnsSummary}
                    columnTypes={columnTypes}
                    source={rowData1}
                  />
                </Segment>
              </Transition>
            </Accordion.Content>
          </Accordion>
        </Box>
      )}
    </Grid.Column>
  );
}

export default SummaryGrid;
