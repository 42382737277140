import TableData from "../../../Shared/table/TableData";
import React, { useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import DropDown from "../../utils/DropDown";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

function PriceIdxTable({ companyPerformance }) {
  const tableData = companyPerformance;
  const tableConfig = [
    {
      label: "Price Idx",
      render: (priceIdxData) => priceIdxData.price_idx,
    },
    {
      label: "Growth",
      render: (priceIdxData) => (
        <Box
          sx={{
            color: priceIdxData.price_idx_growth < 1 ? "red" : "green",
            display: "flex",
            justifyContent: "center ",
            alignItems: "center",
          }}
        >
          {priceIdxData.price_idx_growth < 1 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {priceIdxData.price_idx_growth}
              <SouthIcon />
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {priceIdxData.price_idx_growth}
              <NorthIcon />
            </Box>
          )}
        </Box>
      ),
      header: () => <DropDown />,
    },
  ];

  const ValueTable = {
    tableData,
    tableConfig,
  };
  return <TableData tableData={ValueTable} />;
}

export default PriceIdxTable;
