import { Box } from "@mui/material";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import chroma from "chroma-js";

function ValueShareStackChart({ data }) {
  // Function to generate a gradient between two colors
  const generateGradient = (color1, color2, steps) => {
    const scale = chroma.scale([color1, color2]);
    return scale.colors(steps);
  };

  // Usage in your component
  const palette = generateGradient("#9edcfe", "#00265e", data.length); // Adjust the number of steps as needed

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 400,
      stacked: true,
      stackType: "100%",
    },
    colors: palette,

    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: ["2011 Q1", "2011 Q2", "2011 Q3"],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "right",
      offsetX: 0,
      offsetY: 50,
    },
  });

  return (
    <Box sx={{ p: 2 }}>
      <ReactApexChart
        options={options}
        series={data}
        type="bar"
        height={300}
        width={300}
      />
    </Box>
  );
}

export default ValueShareStackChart;
