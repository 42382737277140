import React from "react";

import { Container, Button, Segment, Modal, Input } from "semantic-ui-react";

function ScenarioName({
  scenarioInput,
  setState,
  currentscenario,
  savingScenario,
  saveScenario,
}) {
  return (
    <Container>
      <Modal
        size="mini"
        centered={true}
        open={scenarioInput}
        onClose={() => setState({ scenarioInput: false })}
        onOpen={() => setState({ scenarioInput: true })}
      >
        <Modal.Header>Scenario Name</Modal.Header>
        <Modal.Content>
          <Segment>
            <Input
              size="small"
              placeholder="Type Your Scenario Name"
              onChange={(value) =>
                setState({
                  currentscenario: value.nativeEvent.target.value,
                })
              }
              fluid
              defaultValue={currentscenario}
            />
            <Button
              loading={savingScenario}
              disabled={!currentscenario || savingScenario}
              color="blue"
              onClick={saveScenario}
              style={{ display: "block", margin: "0 auto", marginTop: "5px" }}
            >
              Save
            </Button>
          </Segment>
        </Modal.Content>
      </Modal>
    </Container>
  );
}

export default ScenarioName;
