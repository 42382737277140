import React, { useState } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";

function InputSkusBox({ sku, rowData1, skus, setSku }) {
  const [selectedSku, setSelectedSku] = useState("");
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSku(selectedValue);
    const selectedItem = rowData1.find(
      (item) => item["SKU Name"] === selectedValue
    );
    setSku(selectedItem);
  };

  return (
    <Box sx={{ mt: 2, ml: 3 }}>
      <Typography sx={{ fontWeight: "bold", p: 1, color: "#757575" }}>
        Skus
      </Typography>
      <FormControl sx={{ minWidth: 100, ml: 1 }} size="small">
        <Select
          value={selectedSku}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {skus.map((a) => {
            const uniqueKey = `${a["SKU Name"]}_${a["Base Price"]}`;
            return (
              <MenuItem key={uniqueKey} value={a["SKU Name"]}>
                {a["SKU Name"]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default InputSkusBox;
