import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import tayeb from "../../../Assets/tayeb.jpg";
import araby from "../../../Assets/araby.jpg";
import afia from "../../../Assets/afia.png";

function RelativePriceIndexChart() {
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "scatter",
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: true,
      },
    },
    colors: ["#056BF6", "#D2376A", "yellow"],
    xaxis: {
      tickAmount: 10,
      min: 0,
      max: 30,
      title: {
        text: "Price / Cell",
      },
    },
    yaxis: {
      tickAmount: 8,
      title: {
        text: "Relative Price Index",
      },
    },
    markers: {
      size: 25,
    },
    fill: {
      type: "image",
      image: {
        src: [afia, araby, tayeb],
        width: 45,
        height: 45,
      },
    },
    legend: {
      labels: {
        useSeriesColors: false,
      },
      markers: {
        customHTML: [
          function () {
            return "";
          },
          function () {
            return "";
          },
        ],
      },
    },
  });

  const [series, setSeries] = useState([
    {
      name: "afia",
      data: [[4, 0.5]],
    },
    {
      name: "araby",
      data: [[12, 2.1]],
    },
    {
      name: "tayeb",
      data: [[19, 3.7]],
    },
  ]);

  return (
    <Paper sx={{ p: 2 }}>
      <ReactApexChart
        options={options}
        series={series}
        type="scatter"
        height={300}
      />
    </Paper>
  );
}

export default RelativePriceIndexChart;
