// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem("token") || null;
};

export const getAuthLevel = () => {
  return localStorage.getItem("level") || null;
};

export const getUserProject = () => {
  return localStorage.getItem("project") || null;
};

export const getUserName = () => {
  return localStorage.getItem("name") || null;
};
// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("project");
  localStorage.removeItem("name");
};

export const removeUserName = () => {
  localStorage.removeItem("name");
};

export const removeUserProject = () => {
  localStorage.removeItem("project");
};

export const setUserNameCashe = (name) => {
  localStorage.setItem("name", name);
};

export const setUserProject = (project) => {
  localStorage.setItem("project", project);
};

// set the token and user from the session storage
export const setUserSession = (token) => {
  localStorage.setItem("token", token);
};

export const setUserAuthLevel = (level) => {
  localStorage.setItem("level", level);
};
export const getUrl = () => {
  return `https://corsproxy.io/?http://simulatorbackend-env.eba-3itakpmk.eu-west-3.elasticbeanstalk.com`;
  //return "http://127.0.0.1:5000";
  //return "http://simulator-env.eba-v2p3p26w.eu-west-3.elasticbeanstalk.com";
};
export const checkToken = (message) => {
  if (message === "Token is invalid !!") {
    removeUserSession();
  }
};
