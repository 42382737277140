import { Box, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import CompetitorsTable from "./CompetitorsTable";
import SyncChart from "../share/SyncChart";
import PriceIndex from "../share/PriceIndex";
import { StyledTitleTypography } from "../../../Styled/Typography";
import { getUrl } from "../../../Utils/Common";
import Skelton from "../../Shared/Skelton";
import TableData from "../../Shared/table/TableData";
import axios from "axios";
import { toastError } from "../../../Utils/Toast";
import { ToastContainer } from "react-toastify";

const BASEURL = getUrl();

function Competitors() {
  const wobTableCell = [
    "Retailer 1",
    "Retailer 2",
    "Retailer 3",
    "Retailer 4",
    "Retailer 5",
    "Retailer 6",
    "Retailer 7",
    "Retailer 8",
  ];
  const yearShareTable = {
    wobTableCell,
    wobTableData: [
      {
        index: "Retailer 1",
        "2019 Share DYA": -0.3,
        "Value Share": 12.8,
        DYAWithColor: 0.2,
        "Price Index": 161,
      },
      {
        index: "Retailer 2",
        "2019 Share DYA": 0.2,
        "Value Share": 9.5,
        DYAWithColor: -0.4,
        "Price Index": 112,
      },
      {
        index: "Retailer 3",
        "2019 Share DYA": 0.2,
        "Value Share": 4.8,
        DYAWithColor: -0.6,
        "Price Index": 120,
      },
      {
        index: "Retailer 4",
        "2019 Share DYA": -0.6,
        "Value Share": 4.8,
        DYAWithColor: 1.5,
        "Price Index": 70,
      },
      {
        index: "Retailer 5",
        "2019 Share DYA": -1.0,
        "Value Share": 2.2,
        DYAWithColor: 0.6,
        "Price Index": 142,
      },
      {
        index: "Retailer 6",
        "2019 Share DYA": 0.0,
        "Value Share": 1.2,
        DYAWithColor: -0.7,
        "Price Index": 179,
      },
      {
        index: "Retailer 7",
        "2019 Share DYA": -1.0,
        "Value Share": 0.6,
        DYAWithColor: -0.1,
        "Price Index": 243,
      },
      {
        index: "Retailer 8",
        "2019 Share DYA": -0.5,
        "Value Share": 0.8,
        DYAWithColor: -0.5,
        "Price Index": 241,
      },
    ],
    wobTableHeader: [
      "",
      "2019 Share DYA (%)",
      "Value Share (%)",
      "DYA (%)",
      "Price Index",
    ],
  };
  const [topManuf, setTopManuf] = useState([]);
  const [loadingTopManuf, setLoadingTopManuf] = useState(false);

  const [topBrands, setTopBrands] = useState([]);
  const [loadingTopBrands, setLoadingTopBrands] = useState(false);

  const getTopManuf = async () => {
    try {
      setLoadingTopManuf(true);
      const { data } = await axios.get(`${BASEURL}/sales/mfc_competition`);
      setTopManuf(data);
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingTopManuf(false);
    }
  };
  const getTopBrands = async () => {
    try {
      setLoadingTopBrands(true);
      const { data } = await axios.get(`${BASEURL}/sales/brand_competition`);
      setTopBrands(data);
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingTopBrands(false);
    }
  };

  useEffect(() => {
    getTopManuf();
    getTopBrands();
  }, []);

  const topManufTableConfig = [
    {
      label: "Top Manuf",
      render: (valueData) => valueData.top_mfc,
    },
    {
      label: "LY DYA (%)",
      render: (valueData) => valueData.LY_DYA,
    },
    {
      label: "Value Share (%)",
      render: (valueData) => valueData.value_share,
    },
    {
      label: "YTD DYA (%)",
      render: (valueData) => valueData.YTD_DYA,
    },
    {
      label: "Price Index",
      render: (valueData) => valueData.price_idx,
    },
  ];
  const topManufTable = {
    tableData: topManuf,
    tableConfig: topManufTableConfig,
  };

  const topBrandTableConfig = [
    {
      label: "Top Brand",
      render: (valueData) => valueData.top_brand,
    },
    {
      label: "LY DYA (%)",
      render: (valueData) => valueData.LY_DYA,
    },
    {
      label: "Value Share (%)",
      render: (valueData) => valueData.value_share,
    },
    {
      label: "YTD DYA (%)",
      render: (valueData) => valueData.YTD_DYA,
    },
    {
      label: "Price Index",
      render: (valueData) => valueData.price_idx,
    },
  ];
  const topBrandsTable = {
    tableData: topBrands,
    tableConfig: topBrandTableConfig,
  };

  return (
    <>
      <ToastContainer />
      <Grid container spacing={1}>
        {loadingTopManuf ? (
          <Grid item xs={6}>
            <Skelton times={3} />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TableData tableData={topManufTable} />
          </Grid>
        )}

        {loadingTopBrands ? (
          <Grid item xs={6}>
            <Skelton times={3} />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TableData tableData={topBrandsTable} />
          </Grid>
        )}

        <Grid item xs={6}>
          <Paper>
            <CompetitorsTable wotTable={yearShareTable} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <SyncChart />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              border: "1px solid #dddd",
              borderRadius: "10px",
              mx: "2px",
            }}
          >
            <StyledTitleTypography
              sx={{
                textAlign: "center",
                mb: "0 !important",
                mt: "5px !important",
                fontSize: "20px",
              }}
            >
              Chang in average share by segment, index (percent)
            </StyledTitleTypography>
            <PriceIndex />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Competitors;
