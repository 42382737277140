import React from "react";
import TopSkusTable from "./TopSkusTable";
import { Grid, Paper } from "@mui/material";
import SubBrandElascityChart from "./RevenueVolumeChart";
import TimeLineChart from "./TimeLineChart";

function Insights() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={{ mx: 1 }}>
          <TopSkusTable />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <SubBrandElascityChart />
      </Grid>
      <Grid item xs={6}>
        <TimeLineChart />
      </Grid>
    </Grid>
  );
}

export default Insights;
