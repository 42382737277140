import React, { Component } from "react";
import { Grid, Button, Segment, Header, Popup } from "semantic-ui-react";

function DefaultScenario({
  isFetching,
  square,
  restoreDefault,
  setState,
  currentscenario,
}) {
  return (
    <Grid.Column>
      <Segment loading={isFetching} attached style={square}>
        <Popup
          trigger={<Header as="h5" icon="erase" content="Default Scenario" />}
          content="Restore the default base scenario"
        />
        <Button
          variant="contained"
          basic
          color="blue"
          onClick={() => {
            restoreDefault();
            setState({
              scenario_state_redo: [],
              scenario_state_undo: [],
            });
          }}
          value="handle upload"
          style={{ padding: 10 }}
          className="btn-default-scenario"
          disabled={currentscenario ? false : true}
        >
          Default Scenario
        </Button>
      </Segment>
    </Grid.Column>
  );
}
export default DefaultScenario;
