import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

import catergoryIcon from "../Assets/categoryIcon.png";
import { theme } from "../Theme";
import name from "../Assets/name.png";
import logo from "../Assets/Marketeers.png";
import { NestedList } from "./List/List";
import ButtonsNavigation from "./ButtonsNavigation";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 180;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    overflow: "hidden",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden ",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(5.5),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(5.5),
      },
    }),
  },
}));

const mdTheme = createTheme();
export default function SideBar() {
  const nagivate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const location = useLocation();

  // Check the pathname in the location to decide whether to render the ButtonsNavigation component
  const excludedPaths = ["/Simulator", "/Scenario", "/Optimization"];

  // Check if the current route matches any excluded paths
  const isExcluded = excludedPaths.some((path) => location.pathname === path);

  const handleModule = () => {
    nagivate("/");
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          sx={{ boxShadow: "none", minHeight: "45px !important" }}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              justifyContent: "space-between",
              backgroundColor: "white",
              minHeight: "45px !important",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                color: `${theme.palette.primary.main}`,
              }}
            >
              <Box
                component="img"
                src={catergoryIcon}
                sx={{ width: "1.5rem", height: "1.5rem" }}
              />
            </IconButton>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-around"
              gap={3}
              marginRight="1rem"
            >
              <Box
                component="img"
                src={name}
                sx={{ width: "2rem", height: "2rem" }}
              />
              <Typography
                component="div"
                sx={{
                  color: `${theme.palette.primary.main}`,
                  fontFamily: "Barlow Semi Condensed",
                  fontWeight: 700,
                }}
              >
                Client Name
              </Typography>
            </Stack>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Box
            component="img"
            src={logo}
            sx={{
              width: "9.5rem",
              "&:hover": {
                cursor: "pointer",
              },
              margin: "0 auto",
            }}
            onClick={handleModule}
          />
          <NestedList open={open} />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            overflowY: "scroll",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl">
            {/* {!isExcluded && <ButtonsNavigation location={location} />} */}
            <Outlet />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
