import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({ state, functions }) {
  const [selectedColumn, setSelectedColumn] = React.useState(state.columns);
  const names = state.columns
    .filter((item) => item["prop"] != undefined)
    .map((ele) => ele.prop);

  const handleChange = (event) => {
    const selectedItems = event.target.value;
    // console.log(selectedItems);
    setSelectedColumn(selectedItems);
    functions.setState({ columns: selectedItems });
  };

  // React.useEffect(() => {
  //   setSelectedColumn(state.columns);
  // }, [state.columns]);

  return (
    <div>
      <FormControl sx={{ m: 1, ml: "-15px", width: 275, height: 40 }}>
        <InputLabel
          id="demo-multiple-checkbox-label"
          sx={{ fontWeight: "bold" }}
        >
          Select Specie Columns
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedColumn}
          onChange={handleChange}
          input={<OutlinedInput label="Select Specie Columns" />}
          renderValue={(selected) =>
            selected
              .filter((item) => item["prop"] != undefined)
              .map((item) => item.name + " , ")
          }
          MenuProps={MenuProps}
          sx={{
            "& .MuiSelect-select": {
              padding: "12px 10px",
            },
          }}
        >
          {state.newSelectedColumns
            .filter((item) => item["prop"] != undefined)
            .map((column) => (
              <MenuItem key={column.prop} value={column}>
                <Checkbox checked={names.includes(column.prop)} />
                <ListItemText primary={column.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
