import React, { useState } from "react";
import { AssortmentChart } from "../Components/Chart";
import { AssortmentApi } from "../Components/Network/Lib/Sales";
import { Box } from "@mui/material";

const Assortment = () => {
  const [WD, setWD] = useState([]);
  const [SKUS, setSKUS] = useState(0);
  React.useEffect(() => {
    AssortmentApi().then((response) => {
      console.log(response.data);
      setWD(response.data.wd);
      setSKUS(response.data.skus);
    });
  }, []);
  return (
    <>
      <AssortmentChart WD={WD} SKUS={SKUS} />
    </>
  );
};

export default Assortment;
