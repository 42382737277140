import styled from "@emotion/styled";
import { Box } from "@mui/material";
import backgroundService from "../Assets/ServiceBackground.png";

export const StyledServiceCover = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${backgroundService})`,
  // height: "auto",
  // margin: 'auto',
  maxWidth: "100%",
  // padding: '0px',
  padding: "50px",
  textAlign: "center",
}));

//list

export const StyledListTitle = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(0,38,94,8%)",
  borderRadius: "10px",
  cursor: "pointer",
}));

export const StyledCustom = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(255,102,104,51%)",
  padding: "3px 5px 3px 5px",
  borderRadius: "5px",
  display:"inline",
  marginLeft: "5px"
}));
