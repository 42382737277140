import React, { useState } from "react";
import { Paper } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import chroma from "chroma-js";
function PriceIndex() {
  const [series] = useState([
    {
      name: "AL ARABI",
      data: [100, 111, 113, 121],
    },
    {
      name: "AFIA",
      data: [100, 108, 109, 113],
    },
    {
      name: "AL TAYEB",
      data: [100, 106, 108, 113],
    },
    {
      name: "LIZA",
      data: [100, 100, 100, 104],
    },
    {
      name: "SLITE",
      data: [100, 101, 97, 101],
    },
  ]);

  // Function to generate a gradient between two colors
  const generateGradient = (color1, color2, steps) => {
    const scale = chroma.scale([color1, color2]);
    return scale.colors(steps);
  };

  // Usage in your component
  const palette = generateGradient("#9edcfe", "#00265e", series.length); // Adjust the number of steps as needed

  const [options] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: palette,
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2, 3, 4, 5],
    },
    stroke: {
      curve: "smooth", // Use "smooth" for a smoother line
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [2019, 2020, 2021, 2022],
    },
  });

  return (
    <Paper sx={{ p: 1 }}>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={300}
      />
    </Paper>
  );
}

export default PriceIndex;
