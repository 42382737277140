import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  TextField,
} from "@mui/material";
import deleteEdit from "../../Assets/delete.svg";
import ImgNoData from "../../Assets/noData.png";
import { Typography } from '@mui/material';
function TableContainerTemp({ skuOptions, handleRowChange, handleRowDelete }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "1px",
    fontSize: "0.9rem",
    [`&.${tableCellClasses.head}`]: {
      fontWeight: "bold",
      backgroundColor: theme.palette.action.hover,
      textAlign: "center",
      padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      fontSize: "0.8rem",

      textAlign: "center",
      padding: "0px",
      // border: "5px solid gray"
      // fontFamily: "Barlow Semi Condensed",
    },
  }));
  return (
    <TableContainer sx={{ marginTop: "15px", maxHeight:430}}>
      <Table sx={{ minWidth: 650, height: 430, maxHeight:430 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>NAME</StyledTableCell>
            <StyledTableCell>OLD PRICE</StyledTableCell>
            <StyledTableCell>NEW PRICE</StyledTableCell>
            <StyledTableCell>OLD SIZE</StyledTableCell>
            <StyledTableCell>NEW SIZE</StyledTableCell>
            <StyledTableCell>Old WD</StyledTableCell>
            <StyledTableCell>NEW WD</StyledTableCell>
            <StyledTableCell>ACTIONS</StyledTableCell>
          </TableRow>
        </TableHead>
        {skuOptions.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9} sx={{ textAlign: "center" }}>
                <Typography fontWeight={300} color={"#8C8C8C"}>No SKUs Added.</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <>
            {skuOptions?.map((row) => (
              <TableBody key={row?.index}>
                <TableRow sx={{ borderBottom: `1px solid #E4E4E4` }}>
                  <StyledTableCell align="right">{row?.index}</StyledTableCell>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    {row["SKU Name"]}
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    {row["base_price"].toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        size="small"
                        type="number"
                        sx={{
                          width: "80px",
                          ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                            {
                              height: "0.6rem",
                            },
                        }}
                        value={row["new_price"].toFixed(2)}
                        name="new price"
                        onChange={(e) => handleRowChange(e, row["SKU Name"], "price")}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    {row["base_size"]}
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px",
                      }}
                    >
                      <TextField
                        disabled
                        id="outlined-basic"
                        size="small"
                        type="number"
                        sx={{
                          width: "80px",
                          ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                            {
                              height: "0.6rem",
                            },
                        }}
                        value={row["new_size"].toFixed(2)}
                        name="price_decrease"
                        onChange={(e) => handleRowChange(e, row.index, "size")}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    {row["base_wd"].toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px",
                      }}
                    >
                      <TextField
                        disabled
                        id="outlined-basic"
                        size="small"
                        type="number"
                        sx={{
                          width: "80px",
                          ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input ":
                            {
                              height: "0.6rem",
                            },
                        }}
                        value={row.new_wd}
                        name="price_decrease"
                        onChange={(e) => handleRowChange(e, row.index, "wd")}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <div>
                      <Box
                        component="img"
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        src={deleteEdit}
                        onClick={() => {
                          handleRowDelete(row.index);
                        }}
                      />
                    </div>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            ))}
          </>
        )}
      </Table>
    </TableContainer>
  );
}

export default TableContainerTemp;
