import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiGrid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Paper, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import PieChartComponent from "../../Shared/PieChart";
import { StyledTitleTypography } from "../../../Styled/Typography";
import { getUrl } from "../../../Utils/Common";
import { toastError } from "../../../Utils/Toast";
import Skelton from "../../../Components/Shared/Skelton";

const Grid = styled(MuiGrid)(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

const headerStyle = {
  backgroundColor: "#1976D2",
  color: "white !important",
  width: "fit-content",
  margin: "0 auto",
  borderRadius: "10px",
  padding: "5px 10px",
  mt: "-10px !important",
  mb: "20px !important",
  fontSize: "18px !important",
};

function TableDividerPieChart({ expanded }) {
  const [volumeDataChart, setVolumeDataChart] = useState([]);
  const [valueDataChart, setValueDataChart] = useState([]);

  const [loadingPieChartData, setLoadingPieChartData] = useState(false);

  let initialized = false;
  let totalMarket = localStorage.getItem("totalMarket")
    ? JSON.parse(localStorage.getItem("totalMarket"))[0]
    : {};

  const getPieChartData = async () => {
    try {
      setLoadingPieChartData(true);
      const { data } = await axios.get(`${getUrl()}/sales/segment`);
      setVolumeDataChart(
        data.map((item) => ({
          label: item.segment,
          value: item.volume_share,
        }))
      );
      setValueDataChart(
        data.map((item) => ({
          label: item.segment,
          value: item.value_share,
        }))
      );
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingPieChartData(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      getPieChartData();
    }
  }, []);

  const totalMarketShare = (value = "", shape) => (
    <Box
      sx={{
        fontWeight: "bold",
        fontSize: "14px",
        mb: "20px",
        textAlign: "center",
      }}
    >
      Total Market Share:{" "}
      <Box component="span" color="#1976D2">
        {value} ({shape})
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "row",
        height: "auto",
        justifyContent: "space-around",
        flexWrap: "wrap",
        "&:hover": {
          cursor: "pointer",
        },
        backgroundColor: `${expanded ? "#E5E9F0" : "white"}`,
      }}
    >
      <Grid container>
        <Grid item xs>
          <StyledTitleTypography sx={{ ...headerStyle }}>
            Value
          </StyledTitleTypography>
          {totalMarketShare(totalMarket?.value_sales, "$")}
          {loadingPieChartData ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skelton variant="circular" />
            </Box>
          ) : (
            <PieChartComponent data={valueDataChart} />
          )}
        </Grid>
        <Divider orientation="vertical" flexItem></Divider>
        <Grid item xs>
          <StyledTitleTypography sx={{ ...headerStyle }}>
            Volume
          </StyledTitleTypography>
          {totalMarketShare(totalMarket?.volume_sales, "%")}
          {loadingPieChartData ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skelton variant="circular" />
            </Box>
          ) : (
            <PieChartComponent data={volumeDataChart} />
          )}
        </Grid>
      </Grid>
      <ToastContainer />
    </Box>
  );
}

export default TableDividerPieChart;
