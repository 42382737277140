import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ProgressBar } from "../../Progress";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

function PriceIndexTable({ wotTable }) {
  const [brandSwitchingSku, setBrandSwitchingSku] = useState(
    wotTable.wobTableCell
  );
  const [rowData, setRowData] = useState(wotTable.wobTableData);
  const [skus, setSkus] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);

  useEffect(() => {
    const li = [];
    rowData.forEach((item) => {
      if (brandSwitchingSku.includes(item["index"])) {
        li.push(item);
      }
    });
    setSelectedSkus(li);
  }, []);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      padding: "6px !important",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "left",
      padding: "10px !important",
    },
  }));

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{ minHeight: "350px", maxHeight: "400px", overflowX: "hidden" }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {wotTable.wobTableHeader.map((ele, idx) => (
                <StyledTableCell key={idx}>{ele} </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedSkus.map((row, index) => {
              const maxSales = Math.max(
                ...selectedSkus.map((sku) => sku["Value Sales"])
              );
              const isMaxSales = row["Value Sales"] === maxSales;

              const minSales = Math.min(
                ...selectedSkus.map((sku) => sku["Value Sales"])
              );
              const isMinSales = row["Value Sales"] === minSales;

              return (
                <TableRow key={index}>
                  <StyledTableCell width="100px">
                    {row["index"]}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "left !important" }}>
                    <ProgressBar
                      bgcolor="lightgray"
                      progressChild={Math.ceil(row["Value Share"]).toFixed(2)}
                      number={row["Value Share"]}
                      icon="%"
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color: isMaxSales ? "green" : isMinSales ? "red" : "",
                    }}
                  >
                    {row["DYA"]}%
                  </StyledTableCell>
                  <StyledTableCell>{row["Price Index"]}</StyledTableCell>
                  {row["Price VS YA"] && (
                    <StyledTableCell>{row["Price VS YA"]}</StyledTableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PriceIndexTable;
