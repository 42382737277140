import { Paper, Stack, Button } from "@mui/material";

import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { StyledTitleTypography } from "../Styled/Typography";
import { PriceElasticity } from "../Components/Chart";
import {
  Cannibilization,
  Elasticity,
  SwitchingBehaviour,
} from "../Components/Table";
import { getProjectData } from "../Components/Network/Lib/Simulator";
const ValuePricing = () => {
  const [rowData, setRowData] = React.useState([]);
  const [sku, setSku] = React.useState(["Afia corn 0.8 L"]);
  const [brandSwitchingSKU, setBrandSwitchingSKU] = React.useState([
    "Afia corn 0.8 L",
  ]);
  const [selectSkus, setSelectSkus] = React.useState([]);
  const [check, setCheck] = React.useState(false);
  const [brandSwitchingSku, setBrandSwitchingSku] = React.useState([
    "Afia SF 1.5L",
    "Halah SF 1.5L",
    ,
    "Shams SF 1.5L",
    "Shams SF 1.5x2L",
    "Shams SF 2.9L",
    "Afia SF 1.5x2L",
    "Abu Zahra SF 1.8L",
    "Afia SF 2.9L",
  ]);
  const [brandSwitchingSkuList, setBrandSwitchingSkuList] = React.useState([
    "Afia SF 1.5L",
    "Halah SF 1.5L",
    ,
    "Shams SF 1.5L",
    "Shams SF 1.5x2L",
    "Shams SF 2.9L",
    "Afia SF 1.5x2L",
    "Abu Zahra SF 1.8L",
    "Afia SF 2.9L",
  ]);
  const [brandSwitchingPrice, setBrandSwitchingPrice] = React.useState("");
  const [brandSwitchingPriceList, setBrandSwitchingPriceList] = React.useState([
    "+10%",
    "+15%",
    "+20%",
  ]);

  const handleBrandSwitchingPriceChange = (event) => {
    setBrandSwitchingPrice(event.target.value);
  };
  const handleBrandSwtichingSkus = (event) => {
    setBrandSwitchingSku(event.target.value);
  };

  const handleChangeElasticity = (event) => {
    setCheck(false);
    setSelectSkus(event.target.value);
    setCheck(true);
  };

  const handleChange = (event) => {
    setCheck(false);
    setBrandSwitchingSKU(event.target.value);
    setCheck(true);
  };

  const handleClick = () => {
    console.log(brandSwitchingPrice, brandSwitchingSku);
  };

  React.useEffect(() => {
    setCheck(false);
    getProjectData({
      name: "savola smart shopper oil category traditional",
    }).then((response) => {
      console.log(response.data);
      const skus = [];
      response.data.data.forEach((item) => {
        skus.push(item["SKU Name"]);
      });
      setRowData(response.data.data);
      setSku(skus);
      setCheck(true);
    });
  }, []);

  return (
    <>
      <Paper sx={{ mt: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="Center"
          sx={{ pl: 3, pt: 3, px: 3 }}
        >
          <StyledTitleTypography>Price Elasticity</StyledTitleTypography>

          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Sku</InputLabel>
              <Select
                multiple
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectSkus}
                label="Sku"
                onChange={handleChangeElasticity}
              >
                {sku.map((row) => (
                  <MenuItem value={row}>{row}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Box
          sx={{
             fontSize: "1.1rem",
            pl: 3,
            color: "#6D6D6D",
          }}
        >
          Understand changes in demand by analyzing how consumers react to
          variations in price points.
        </Box>
        <PriceElasticity
          key={selectSkus}
          sku={selectSkus}
          projectName="savola smart shopper oil category traditional"
        />
      </Paper>

      <Paper sx={{ mt: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="Center"
          sx={{ pl: 3, pt: 3, px: 3 }}
        >
          <StyledTitleTypography>Brand Switching</StyledTitleTypography>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">SKUs</InputLabel>
              <Select
                multiple
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={brandSwitchingSKU}
                label="Sku"
                onChange={handleChange}
              >
                {sku.map((row) => (
                  <MenuItem value={row}>{row}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Box
          sx={{
             fontSize: "1.1rem",
            pl: 3,
            pb: 3,
            color: "#6D6D6D",
          }}
        >
          Discover the key to building a loyal customer base by learning the
          reasons behind changes in consumer buying habits.
        </Box>
        <SwitchingBehaviour />
      </Paper>

      <Paper sx={{ mt: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="Center"
          sx={{ p: 2 }}
        >
          <StyledTitleTypography>SKUs Strategy</StyledTitleTypography>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">SKUs</InputLabel>
              <Select
                multiple
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={brandSwitchingSku}
                label="Sku"
                onChange={handleBrandSwtichingSkus}
              >
                {brandSwitchingSkuList.map((row) => (
                  <MenuItem value={row}>{row}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Box
          sx={{
             fontSize: "1.1rem",
            pl: 3,
            pb: 3,
            color: "#6D6D6D",
          }}
        >
         Estimate cannibalization impact by gauging how new products can replace existing product sales, and safeguard your market share.

        </Box>
        <Cannibilization key={brandSwitchingSku} data={brandSwitchingSku} />
      </Paper>
    </>
  );
};

export default ValuePricing;
