import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function DialogSelect() {
  const [growth, setGrowth] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setGrowth(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      console.log("ok");
    }
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        sx={{ fontWeight: "bold", textTransform: "unset" }}
      >
        Growth <ArrowDropDownIcon />
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Choose your preferred period</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 160 }}>
              <InputLabel htmlFor="demo-dialog-native">Time</InputLabel>
              <Select
                native
                value={growth}
                onChange={handleChange}
                input={<OutlinedInput label="Time" id="demo-dialog-native" />}
              >
                <option aria-label="None" disabled value="" />
                <option value={"LY"}>Last Year</option>
                <option value={"L3M"}>Last Three Months</option>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
