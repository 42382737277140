import React, { useRef, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import TableData from "../../../../Components/Shared/table/TableData";
import DropDown from "../../utils/DropDown";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

function BrandValueKPIsTable({ brandValueKpi }) {
  const tableData = brandValueKpi;
  const tableConfig = [
    {
      label: "Brand",
      render: (BrandValueKPIsData) => BrandValueKPIsData.top_brand,
    },
    {
      label: "Brand Power",
      render: (BrandValueKPIsData) => BrandValueKPIsData.brand_power,
    },
    {
      label: "Brand Share",
      render: (BrandValueKPIsData) => BrandValueKPIsData.brand_share,
    },
    {
      label: "Actual Share",
      render: (BrandValueKPIsData) => BrandValueKPIsData.actual_share,
    },
    {
      label: "Growth",
      render: (BrandValueKPIsData) => (
        <Box
          sx={{
            color: BrandValueKPIsData.growth < 1 ? "red" : "green",
            display: "flex",
            justifyContent: "center ",
            alignItems: "center",
          }}
        >
          {BrandValueKPIsData.growth < 1 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {BrandValueKPIsData.growth}
              <SouthIcon />
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {BrandValueKPIsData.growth}
              <NorthIcon />
            </Box>
          )}
        </Box>
      ),
      header: () => <DropDown />,
    },
    {
      label: "Comment",
      render: (BrandValueKPIsData) => (
        <Box sx={{ backgroundColor: "#3cb9fc" }}>
          {BrandValueKPIsData.comment}
        </Box>
      ),
    },
  ];

  const ValueTable = {
    tableData,
    tableConfig,
  };

  return <TableData tableData={ValueTable} />;
}

export default BrandValueKPIsTable;
