import React from "react";
import { Grid, Dropdown, Segment, Header, Popup } from "semantic-ui-react";

function GroupBySection({
  square,
  search,
  multiple,
  groups,
  currentGroup,
  handlegroups,
  handleSearchChange,
  renderLabel,
}) {
  return (
    <Grid.Column>
      <Segment style={square}>
        <Popup
          trigger={<Header as="h5" icon="filter" content="Group by" />}
          content="You can group by different parameter"
        />
        <Dropdown
          compact
          fluid
          floating
          search={search}
          multiple={multiple}
          selection
          options={groups}
          value={currentGroup}
          placeholder="Select Group"
          onChange={handlegroups}
          onSearchChange={handleSearchChange}
          // renderLabel={renderLabel}
          className="group-by"
        />
      </Segment>
    </Grid.Column>
  );
}

export default GroupBySection;
