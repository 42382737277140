import * as React from "react";
import {  Paper, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { StyledTitleTypography } from "../Styled/Typography";
import { PriceArchitecture, PriceArchitectureSize } from "../Components/Table";
import { PPALadders } from "../Components/Network/Lib/Sales";
import { InterSizeChart } from "../Components/Chart";

const PPA = () => {
  const [age, setAge] = React.useState("");
  const [ladders, setLadders] = React.useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  React.useEffect(()=>
  {
    PPALadders().then((response) => {
      setLadders(response.data)
      // console.log((response.data[1].price_bracket));

      
    })
    
  },[])
  return (
    <>
     {/* <Paper sx={{ mt: 1 }}>
      <InterSizeChart/>
     </Paper> */}
      <Paper sx={{ mt: 1 }}>
        <Stack
         
          sx={{ p: 2 }}
        >
          <StyledTitleTypography>Price</StyledTitleTypography>
          <Box
            sx={{
               fontSize: "1.1rem",
              mb: 1,
              color: "#6D6D6D",
            }}
          >
            Pack Price Architecture is one of the main areas where growth opportunities can arise for innovation

          </Box>
        </Stack>
        <PriceArchitecture ladders={ladders}/>

        <Stack
         
         sx={{ p: 2 }}
       >
         <StyledTitleTypography>Size</StyledTitleTypography>
         <Box
           sx={{
              fontSize: "1.1rem",
             mb: 1,
             color: "#6D6D6D",
           }}
         >
           Pack Price Architecture is one of the main areas where growth opportunities can arise for innovation

         </Box>
       </Stack>
        <PriceArchitectureSize ladders={ladders}/>
      </Paper>
    </>
  );
};

export default PPA;
