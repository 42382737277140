import React, { useEffect, useState } from "react";
import TradeTable from "./TradeTable";
import { Box, Grid, Paper } from "@mui/material";
import { StyledTitleTypography } from "../../../Styled/Typography";
import ValueShareStackChart from "../share/ValueShareStackChart";
import ContributorChart from "../share/ContributorChart";
import TableColumnsWithMarket from "../share/TableColumnsWithMarket";
import PriceIndex from "../share/PriceIndex";
import SegmentColumnsWithMarket from "../share/SegmentColumnsWithMarket";
import SyncChart from "../share/SyncChart";
import PriceIndexTable from "./PriceIndexTable";
import P3mValues from "../share/P3mValues";
import axios from "axios";
import { toastError } from "../../../Utils/Toast";
import Skelton from "../../../Components/Shared/Skelton";
import { ToastContainer } from "react-toastify";
import { getUrl } from "../../../Utils/Common";
import TableData from "../../Shared/table/TableData";

const BASEURL = getUrl();

function Trade() {
  let initialized = false;
  const [wobTableData, setWobTableData] = useState([]);
  const [loadingWobTable, setLoadingWobTable] = useState(false);

  const [priceVsYaTableData, setPriceVsYa] = useState([]);
  const [loadingPriceVsYaTable, setLoadingPriceVsYaTable] = useState(false);

  const [priceWithNotVsYaTableData, setPriceWithNotVsYaTableData] = useState(
    []
  );
  const [loadingPriceWithNotVsYaTableData, setLoadingWithNotPriceVsYaTable] =
    useState(false);

  const [priceSegment, setPriceSegment] = useState([]);
  const [loadingPriceSegment, setLoadingPriceSegment] = useState(false);

  const [sizeSegment, setSizeSegment] = useState([]);
  const [loadingSizeSegment, setLoadingSizeSegment] = useState(false);

  const [valueSalesInCat, setValueSalesInCat] = useState([]);
  const [volumeSalesInCat, setVolumeSalesInCat] = useState([]);

  const [contributionData, setContributionData] = useState([]);

  const getWobTableData = async () => {
    try {
      setLoadingWobTable(true);
      const { data } = await axios.get(`${BASEURL}/sales/trade_first_table`);
      setWobTableData(data);
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingWobTable(false);
    }
  };
  const getPriceVsYa = async () => {
    try {
      setLoadingPriceVsYaTable(true);
      const { data } = await axios.get(`${BASEURL}/sales/trade_third_table`);
      setPriceVsYa(data);
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingPriceVsYaTable(false);
    }
  };
  const getPriceWithNotVsYa = async () => {
    try {
      setLoadingWithNotPriceVsYaTable(true);
      const { data } = await axios.get(`${BASEURL}/sales/trade_third_table`);
      setPriceWithNotVsYaTableData(data);
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingWithNotPriceVsYaTable(false);
    }
  };
  const getPriceSegment = async () => {
    try {
      setLoadingPriceSegment(true);
      const { data } = await axios.get(`${BASEURL}/sales/trade_fifth_table`);
      setPriceSegment(data);
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingPriceSegment(false);
    }
  };
  const getSizeSegment = async () => {
    try {
      setLoadingSizeSegment(true);
      const { data } = await axios.get(`${BASEURL}/sales/trade_sixth_table`);
      setSizeSegment(data);
    } catch (err) {
      toastError("something went wrong");
    } finally {
      setLoadingSizeSegment(false);
    }
  };
  const getContributionData = async () => {
    try {
      const { data } = await axios.get(`${BASEURL}/sales/contribution`);
      setContributionData(data);
    } catch (err) {
      toastError("something went wrong");
    }
  };

  const getValueSalesInCat = async () => {
    try {
      const { data } = await axios.get(
        `${BASEURL}/sales/value_sales_in_category`
      );
      const transformedData = data.reduce((accumulator, current) => {
        const existingProduct = accumulator.find(
          (item) => item.name === current.product
        );

        if (existingProduct) {
          existingProduct.data.push(current.value_share);
        } else {
          accumulator.push({
            name: current.product,
            data: [current.value_share],
          });
        }

        return accumulator;
      }, []);
      setValueSalesInCat(transformedData);
    } catch (err) {
      toastError("something went wrong");
    }
  };
  const getVolumeSalesInCat = async () => {
    try {
      const { data } = await axios.get(
        `${BASEURL}/sales/volume_sales_in_category`
      );
      const transformedData = data.reduce((accumulator, current) => {
        const existingProduct = accumulator.find(
          (item) => item.name === current.product
        );

        if (existingProduct) {
          existingProduct.data.push(current.volume_share);
        } else {
          accumulator.push({
            name: current.product,
            data: [current.volume_share],
          });
        }

        return accumulator;
      }, []);
      setVolumeSalesInCat(transformedData);
    } catch (err) {
      toastError("something went wrong");
    }
  };

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      getWobTableData();
      getPriceVsYa();
      getPriceWithNotVsYa();
      getPriceSegment();
      getSizeSegment();
      getValueSalesInCat();
      getVolumeSalesInCat();
      getContributionData();
    }
  }, []);

  const wobTableConfig = [
    {
      label: "retailer",
      render: (valueData) => valueData.retailer,
    },
    {
      label: "Value Sales",
      render: (valueData) => valueData.value_sales,
    },
    {
      label: "Wob (%)",
      render: (valueData) => valueData.WoB,
    },
    {
      label: "IYA (%)",
      render: (valueData) => valueData.iya,
    },
    {
      label: "Price Index",
      render: (valueData) => valueData.price_idx,
    },
    {
      label: "Price IYA",
      render: (valueData) => valueData.price_iya,
    },
  ];
  const WobTable = {
    tableData: wobTableData,
    tableConfig: wobTableConfig,
  };

  const priceVSYaTableConfig = [
    {
      label: "retailer",
      render: (valueData) => valueData.retailer,
    },
    {
      label: "Price Index",
      render: (valueData) => valueData.price_idx,
    },
    {
      label: "Price VS YA",
      render: (valueData) => valueData.price_vs_ya,
    },
    {
      label: "Value Share",
      render: (valueData) => valueData.value_share,
    },
    {
      label: "Value Share Dya",
      render: (valueData) => valueData.value_share_dya,
    },
  ];
  const priceVSYaTable = {
    tableData: priceVsYaTableData,
    tableConfig: priceVSYaTableConfig,
  };

  const priceWithNotVSYaTableConfig = [
    {
      label: "retailer",
      render: (valueData) => valueData.retailer,
    },
    {
      label: "Price Index",
      render: (valueData) => valueData.price_idx,
    },
    {
      label: "Value Share",
      render: (valueData) => valueData.value_share,
    },
    {
      label: "Value Share Dya",
      render: (valueData) => valueData.value_share_dya,
    },
  ];
  const priceWithNotVSYaTable = {
    tableData: priceWithNotVsYaTableData,
    tableConfig: priceWithNotVSYaTableConfig,
  };

  const priceSegmentTableConfig = [
    {
      label: "Price Segment",
      render: (valueData) => valueData.price_segment,
    },
    {
      label: "Price Index",
      render: (valueData) => valueData.price_idx,
    },
    {
      label: "P3m Value Share (%)",
      render: (valueData) => valueData.p3m_value_share,
    },
    {
      label: "Duracell (%)",
      render: (valueData) => valueData.duracell,
    },
  ];
  const priceSegmentTable = {
    tableData: priceSegment,
    tableConfig: priceSegmentTableConfig,
  };

  const sizeSegmentTableConfig = [
    {
      label: "Size Segment",
      render: (valueData) => valueData.size_segment,
    },
    {
      label: "Price Index",
      render: (valueData) => valueData.price_idx,
    },
    {
      label: "P3m Value Share (%)",
      render: (valueData) => valueData.p3m_value_share,
    },
    {
      label: "Duracell (%)",
      render: (valueData) => valueData.duracell,
    },
  ];
  const sizeSegmentTable = {
    tableData: sizeSegment,
    tableConfig: sizeSegmentTableConfig,
  };

  const contributionTableConfig = [
    {
      label: "Seg. Share",
      render: (valueData) => valueData.size_segment,
    },
    {
      label: "Price Index",
      render: (valueData) => valueData.price_idx,
    },
  ];
  const contributionTable = {
    tableData: contributionData,
    tableConfig: contributionTableConfig,
  };

  return (
    <Paper>
      <ToastContainer />
      <Grid container spacing={1} sx={{ mt: "1px" }}>
        {loadingWobTable ? (
          <Grid item xs={6}>
            <Skelton times={3} />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TableData tableData={WobTable} />
          </Grid>
        )}

        {loadingWobTable ? (
          <Grid item xs={6}>
            <Skelton times={3} />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TableData tableData={WobTable} />
          </Grid>
        )}

        <Grid item xs={7}>
          <Box
            sx={{
              border: "1px solid #dddd",
              mx: "2px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ borderRight: "1px solid #ccc" }}>
              <StyledTitleTypography
                sx={{
                  textAlign: "center",
                  mb: "0 !important",
                  mt: "5px !important",
                }}
              >
                Value of sales in category
              </StyledTitleTypography>
              <ValueShareStackChart data={valueSalesInCat} />
            </Box>
            <Box>
              <StyledTitleTypography
                sx={{
                  textAlign: "center",
                  mb: "0 !important",
                  mt: "5px !important",
                }}
              >
                Volume of sales in category
              </StyledTitleTypography>
              <ValueShareStackChart data={volumeSalesInCat} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              border: "1px solid #dddd",
              borderRadius: "10px",
              mx: "2px",
            }}
          >
            <ContributorChart data={contributionData} />
            <TableColumnsWithMarket />
          </Box>
        </Grid>

        {loadingPriceVsYaTable ? (
          <Grid item xs={6}>
            <Skelton times={3} />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TableData tableData={priceVSYaTable} />
          </Grid>
        )}

        {loadingPriceWithNotVsYaTableData ? (
          <Grid item xs={6}>
            <Skelton times={3} />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TableData tableData={priceWithNotVSYaTable} />
          </Grid>
        )}

        <Grid item xs={7}>
          <Box
            sx={{
              border: "1px solid #dddd",
              borderRadius: "10px",
              mx: "2px",
            }}
          >
            <StyledTitleTypography
              sx={{
                textAlign: "center",
                mb: "0 !important",
                mt: "5px !important",
                fontSize: "20px",
              }}
            >
              Chang in average share by segment, index (percent)
            </StyledTitleTypography>
            <PriceIndex />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              border: "1px solid #dddd",
              borderRadius: "10px",
              mx: "2px",
            }}
          >
            <SegmentColumnsWithMarket />
            <TableColumnsWithMarket />
          </Box>
        </Grid>

        {/* <Grid item xs={5}>
          <Box
            sx={{
              border: "1px solid #dddd",
              borderRadius: "10px",
            }}
          >
            <SyncChart />
          </Box>
        </Grid> */}

        {loadingPriceSegment ? (
          <Grid item xs={6}>
            <Skelton times={3} />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TableData tableData={priceSegmentTable} />
          </Grid>
        )}

        {loadingSizeSegment ? (
          <Grid item xs={6}>
            <Skelton times={3} />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <TableData tableData={sizeSegmentTable} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

export default Trade;
