import React, { useState } from "react";
import TableData from "../../../Shared/table/TableData";

function SegmentsTable({ companyPerformance }) {
  const tableData = companyPerformance;
  const tableConfig = [
    {
      label: "segments",
      render: (segmentsData) => segmentsData.segment,
    },
  ];

  const ValueTable = {
    tableData,
    tableConfig,
  };
  return <TableData tableData={ValueTable} />;
}

export default SegmentsTable;
