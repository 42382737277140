import React from "react";
import {
  Container,
  Dropdown,
  Button,
  Segment,
  Modal,
  Input,
  Search,
} from "semantic-ui-react";
function AttributesChanges({ renderLabel, state, functions }) {
  const numeric = state.numeric_columns.map((ele) => {
    return {
      key: ele,
      value: ele,
      text: ele.replace("new_", " "),
    };
  });

  let checkAllInputNotNull =
    !state.currentGroupAttrModal ||
    !state.currentSkusAttrModal.length ||
    !state.attrPercentage;

  return (
    <Container>
      <Modal
        size="medium"
        centered={true}
        open={state.editGroupAttrFlag}
        onClose={() => functions.setState({ editGroupAttrFlag: false })}
        onOpen={() => functions.setState({ editGroupAttrFlag: true })}
      >
        <Modal.Header>Apply Attributes Changes</Modal.Header>
        <Modal.Content>
          <Segment className="attributesChanges">
            <Dropdown
              compact
              floating
              selection
              options={state.groups}
              value={state.currentGroupAttrModal}
              placeholder="Select Group"
              onChange={functions.handleGroupsAttrModal}
              className="selectGroupAttributeChange"
            />
            <Dropdown
              search={state.search}
              multiple={state.multiple}
              compact
              floating
              selection
              options={state.skusAttrModal}
              value={state.currentSkusAttrModal}
              placeholder="Select SKU"
              onChange={functions.handleSKUAttrModal}
              onSearchChange={functions.handleSearchChange}
              // renderLabel={renderLabel}
              className="selectSkuAttributeChange"
            />
            <Dropdown
              compact
              floating
              selection
              options={numeric}
              value={state.currentAttrModal}
              placeholder="Select Attribute"
              onChange={functions.handleAttrAttrModal}
              renderLabel={renderLabel}
              className="selectAttributeAttributeChange"
            />
            <Input
              size="small"
              type="number"
              placeholder="Percentage (%)"
              onChange={(value) => {
                functions.setState({
                  attrPercentage:
                    parseFloat(value.nativeEvent.target.value) || 0,
                });
              }}
              defaultValue={state.attrPercentage || ""}
              className="inputAttributeChange"
            />
            {/* state.isFetching */}
            <Button
              loading={state.isFetching}
              disabled={checkAllInputNotNull || state.isFetching}
              color="blue"
              onClick={functions.applyChangedAttrPercentage}
            >
              Apply
            </Button>
          </Segment>
        </Modal.Content>
      </Modal>
    </Container>
  );
}

export default AttributesChanges;
