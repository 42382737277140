export function hierarchyTree(input, key, result, level = 0) {
    const childs = input[key];
    // check if node has children
    if (childs === undefined) {
      return;
    }

    // go through sorted array and check if there is any items
    for (let i = 0; i < childs.length; i++) {
      const children = input[childs[i]._id];
      // add item to result and check if it’s a parent for someone
      result.push({
        ...childs[i],
        children: children ? children.map((c) => c._id) : undefined,
        level,
      });
      // recursion for multilevel tree
      hierarchyTree(input, childs[i]._id, result, level + 1);
    }

    return result;
  }