import React from "react";
import { Grid, Dropdown, Segment, Header, Popup } from "semantic-ui-react";

function SelectScenario({
  isFetching,
  square,
  scenarios,
  currentscenario,
  setScenario,
}) {
  return (
    <Grid.Column>
      <Segment loading={isFetching} style={square}>
        <Popup
          trigger={
            <Header as="h5" icon="pencil alternate" content="Select Scenario" />
          }
          content="Select scenario to apply to the calculations"
        />
        <Dropdown
          compact
          floating
          fluid
          selection
          options={[
            {
              key: "placeholder",
              text: "Select Scenario",
              value: "",
              disabled: true,
            }, // First option is disabled
            ...scenarios,
          ]}
          value={currentscenario}
          placeholder="Select Scenario"
          onChange={setScenario}
          disabled={isFetching}
        />
      </Segment>
    </Grid.Column>
  );
}

export default SelectScenario;
