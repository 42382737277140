import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function Skelton({ times, variant }) {
  return (
    <Stack spacing={1}>
      {Array(times)
        .fill(0)
        .map((ele) => (
          <Skeleton
            variant={variant ? "circular" : "rounded"}
            sx={{
              width: variant ? "200px" : "100%",
              height: variant ? "200px" : "50px",
            }}
          />
        ))}
    </Stack>
  );
}
