import React, { useEffect, useState } from "react";
import PriceElasticityComponent from "./PriceElasticity";
import BrandSwitching from "../../../Pages/pricingAndPPA/BrandSwitching";
import { Grid, Paper } from "@mui/material";
import { getProjectData } from "../../Network/Lib/Simulator";

function Consumer() {
  const [sku, setSku] = useState([]);
  const [selectSkus, setSelectSkus] = useState([]);
  const [selectBrandSkus, setSelectBrandSkus] = useState([]);
  const [brandSwitchingSKU, setBrandSwitchingSKU] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const { data } = await getProjectData({
          name: "savola smart shopper oil category traditional",
        });
        const skus = data.data.map((item) => item["SKU Name"]);
        setSku(skus);
      } catch (error) {
        console.log("Error fetching project data:", error);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, []);
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sx={{ minHeight: "100%" }}>
        <PriceElasticityComponent
          sku={sku}
          selectSkus={selectSkus}
          setSelectSkus={setSelectSkus}
          loading={loading}
        />
      </Grid>
      <Grid item xs={6}>
        <BrandSwitching
          sku={sku}
          setSku={setSku}
          selectSkus={selectBrandSkus}
          setSelectSkus={setSelectBrandSkus}
          brandSwitchingSKU={brandSwitchingSKU}
        />
      </Grid>
    </Grid>
  );
}

export default Consumer;
