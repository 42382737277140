export function states(
  createColumnSummary,
  createColumns,
  createColumnsFinance,
  increased,
  decreased,
  increasedAttr,
  decreasedAttr,
  initial_columns
) {
  return {
    hints: false,
    multiple: true,
    search: true,
    searchQuery: null,
    scenarioName: "",
    files: [],
    file1check: true,
    addScenario: false,
    addFinancial: false,
    expandedAll: false,
    introduction: true,
    projectname: "",
    options: [],
    type: [],
    isFileready: false,
    isUploading: false,
    isFetching: false,
    currentGroup: [],
    groups: [],
    scenarios: [],
    segments: [],
    currentSegment: [],
    currentscenario: [],
    grouping: {},
    trimmedRows: {},
    trimmedRowsEdited: false,
    companyName: "",
    scenarioInput: false,
    editGroupAttrFlag: false,
    currentGroupAttrModal: "",
    skusAttrModal: [],
    currentSkusAttrModal: [],
    attrsAttrModal: [],
    currentAttrModal: "",
    attrPercentage: 0,
    inputInvalid: false,
    invalidSKU: "",
    invalidMin: 0,
    invalidMax: 0,
    wd: "",
    savingScenario: false,
    firstVal: 0,
    summaryToggle: true,
    financeToggle: true,
    summaryToggleCol: 10,
    financeToggleCol: 8,
    finance_available: true,
    addFinancialModal: false,
    scenario_state_undo: [],
    scenario_state_redo: [],
    tree: [],
    grouping: {
      props: ["Co"],
      expandedAll: true,
    },
    columnsSummary: createColumnSummary(increased, decreased),
    columns: initial_columns,
    columnsFinance: createColumnsFinance(increasedAttr, decreasedAttr),
    rowData: [],
    rowData1: [],
    inputColumns: [],
    changedData: {},
    newSelectedColumns: initial_columns,
  };
}
