import React from "react";
import { Container, Button, Icon, Header, Modal } from "semantic-ui-react";
function SelectedRow({
  setState,
  inputInvalid,
  invalidSKU,
  invalidMin,
  invalidMax,
  wd,
}) {
  return (
    <Container>
      <Modal
        size="mini"
        open={inputInvalid}
        onClose={() => setState({ inputInvalid: false })}
        onOpen={() => setState({ inputInvalid: true })}
      >
        <Header color="red" icon>
          <Icon name="exclamation" />
          Invalid Input
        </Header>
        <Modal.Content>
          <p style={{ textAlign: "center" }}>
            For the selected &nbsp;
            <span style={{ color: "red", fontWeight: "bold" }}>
              {invalidSKU} &nbsp;
            </span>
            please insert a {wd} value between &nbsp;
            <span style={{ color: "green", fontWeight: "bold" }}>
              {invalidMin} &nbsp;
            </span>
            and &nbsp;
            <span style={{ color: "green", fontWeight: "bold" }}>
              {invalidMax}
            </span>
          </p>
          <Button
            fluid
            basic
            color="red"
            onClick={() => setState({ inputInvalid: false, wd: "" })}
          >
            Ok
          </Button>
        </Modal.Content>
      </Modal>
    </Container>
  );
}

export default SelectedRow;
