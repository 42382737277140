import React from "react";
import { Container, Button, Modal, Message } from "semantic-ui-react";
function FinancialData({
  addFinancialModal,
  setState,
  exportFinancialTemplate,
}) {
  return (
    <Container>
      <Modal
        size="small"
        open={addFinancialModal}
        onClose={() => setState({ addFinancialModal: false })}
        onOpen={() => setState({ addFinancialModal: true })}
      >
        <Modal.Content>
          <Container textAlign="center">
            <Message
              header="Financial Data"
              content="There were no financial data found in the design file"
            />
            <Button
              variant="contained"
              basic
              color="blue"
              value="Add financial data"
              onClick={() => {
                setState({ addFinancial: true });
              }}
              style={{ padding: 10 }}
            >
              Add Financial Data
            </Button>
            <Button
              variant="contained"
              basic
              color="blue"
              value="get financial template"
              onClick={exportFinancialTemplate}
              style={{ padding: 10 }}
            >
              Get Financial Template
            </Button>
          </Container>
        </Modal.Content>
      </Modal>
    </Container>
  );
}

export default FinancialData;
