import NumberColumnType from "@revolist/revogrid-column-numeral";
import { myJsxCellTemplate } from "../utils/cellTemplate";
import SelectTypePlugin from "@revolist/revogrid-column-select";

export function createColumns(increasedMarket, decreasedMarket) {
  return [
    {
      pin: "colPinStart",
      size: 50,
      readonly: "true",
      // defining checkbox template
      cellTemplate: (createElement, props) => {
        props.model["checked"] = props.model["Market Share"];
        const input = createElement("input", {
          type: "checkbox",
          // store is selected in rows "checked" prop
          checked: props.model["checked"],
          onChange(e) {
            props.model["Market Share"] = e.target.checked;
            props.model["checked"] = e.target.checked;
            if (input) {
              input.$attrs$.checked = e.target.checked;
            }
          },
        });
        return input;
      },
    },
    {
      prop: "SKU Name",
      name: "SKUs",
      readonly: "true",
      pin: "colPinStart",
      autoSizeColumn: "true",
      cellTemplate: myJsxCellTemplate,
    },
    {
      readonly: "true",
      prop: "base_market_share",
      name: "Base Market Share",
      columnType: "three",
    },
    {
      readonly: "true",
      prop: "new_market_share",
      name: "New Market Share",
      columnType: "three",
      cellProperties: ({ prop, model, data, column }) => {
        if (increasedMarket.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#1fc173",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
        if (decreasedMarket.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#b60002",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
      },
    },
  ];
}

export function createColumnsFinance(increasedAttr, decreasedAttr) {
  return [
    {
      readonly: "true",
      prop: "base_vat",
      name: "Base VAT",
    },
    {
      prop: "new_vat",
      name: "New VAT",
      cellProperties: ({ prop, model, data, column }) => {
        return {
          style: {
            backgroundColor: "#A7D0F1",
          },
        };
      },
    },
    {
      readonly: "true",
      prop: "base_consumer_price",
      name: "Base Consumer Price",
      columnType: "two_without_percentage_1",
    },
    {
      readonly: "true",
      prop: "new_consumer_price",
      name: "New Consumer Price",
      columnType: "two_without_percentage_1",
      cellProperties: ({ prop, model, data, column }) => {
        if (increasedAttr.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#1fc173",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
        if (decreasedAttr.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#b60002",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
      },
    },
    {
      readonly: "true",
      prop: "base_retailer",
      name: "Base Retailer Margin",
    },
    {
      prop: "new_retailer",
      name: "New Retailer Margin",
      cellProperties: ({ prop, model, data, column }) => {
        return {
          style: {
            backgroundColor: "#A7D0F1",
          },
        };
      },
    },
    {
      readonly: "true",
      prop: "base_price_to_retailer",
      name: "Base Price To Retailer",
      columnType: "two_without_percentage_1",
    },
    {
      readonly: "true",
      prop: "new_price_to_retailer",
      name: "New Price To retailer",
      columnType: "two_without_percentage_1",
      cellProperties: ({ prop, model, data, column }) => {
        if (increasedAttr.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#1fc173",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
        if (decreasedAttr.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#b60002",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
      },
    },
    {
      readonly: "true",
      prop: "base_distributor",
      name: "Base Distributor Margin",
    },
    {
      prop: "new_distributor",
      name: "New Distributor Margin",
      cellProperties: ({ prop, model, data, column }) => {
        return {
          style: {
            backgroundColor: "#A7D0F1",
            color: "white",
          },
        };
      },
    },
    {
      readonly: "true",
      prop: "base_distributor_margin",
      name: "Base Gross Sales",
      columnType: "two_without_percentage_1",
    },
    {
      readonly: "true",
      prop: "new_distributor_margin",
      name: "New Gross Sales",
      columnType: "two_without_percentage_1",
      cellProperties: ({ prop, model, data, column }) => {
        if (increasedAttr.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#1fc173",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
        if (decreasedAttr.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#b60002",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
      },
    },
    {
      readonly: "true",
      prop: "base_gross",
      name: "Base Gross to Net",
    },
    {
      prop: "new_gross",
      name: "New Gross to Net",
      cellProperties: ({ prop, model, data, column }) => {
        return {
          style: {
            backgroundColor: "#A7D0F1",
          },
        };
      },
    },
    {
      readonly: "true",
      prop: "base_net_sales",
      name: "Base Net Sales",
      columnType: "two_without_percentage_1",
    },
    {
      readonly: "true",
      prop: "new_net_sales",
      name: "New Net Sales",
      columnType: "two_without_percentage_1",
      cellProperties: ({ prop, model, data, column }) => {
        if (increasedAttr.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#1fc173",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
        if (decreasedAttr.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#b60002",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
      },
    },
    {
      readonly: "true",
      prop: "base_cogs",
      name: "Base COGS per SKU",
    },
    {
      prop: "new_cogs",
      name: "Base COGS per SKU",
      cellProperties: ({ prop, model, data, column }) => {
        return {
          style: {
            backgroundColor: "#A7D0F1",
          },
        };
      },
    },
    {
      readonly: "true",
      prop: "base_sales_margin",
      name: "Base Sales Margin",
      columnType: "two_without_percentage_1",
    },
    {
      readonly: "true",
      prop: "new_sales_margin",
      name: "New Sales Margin",
      columnType: "two_without_percentage_1",
      cellProperties: ({ prop, model, data, column }) => {
        if (increasedAttr.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#1fc173",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
        if (decreasedAttr.includes(model["SKU Name"])) {
          return {
            style: {
              color: "#b60002",
              fontWeight: "bold",
            },
            class: {
              bank: true,
            },
          };
        }
      },
    },
  ];
}
/** */

/** ***************** columns ************/
export const columnsGridTypes = {
  one: new NumberColumnType("0.0"),
  two: new NumberColumnType("0.00"),
  three: new NumberColumnType("0.000"),
  four: new NumberColumnType("0.0000"),
  five: new NumberColumnType("0.00000"),
  six: new NumberColumnType("0.000000"),
  seven: new NumberColumnType("0.0000000"),
  eight: new NumberColumnType("0.00000000"),
  two_without_percentage: new NumberColumnType("0.00"),
  two_without_percentage_1: new NumberColumnType("0.00"),
  select: new SelectTypePlugin(),
  price: new NumberColumnType("0.00"),
};

const increasedDecreasedByMarketShare = (rowData, model, coloring) => {
  const decreasedMarket = rowData
    .filter((item) => item["base_market_share"] > item["new_market_share"])
    .map((item) => item["SKU Name"]);

  const increasedMarket = rowData
    .filter((item) => item["base_market_share"] < item["new_market_share"])
    .map((item) => item["SKU Name"]);

  // const isBrand = model.hasOwnProperty("Brand");
  // if (isBrand) {
  //   const ele = document.querySelector(
  //     "revogr-edit.edit-input-wrapper.hydrated > input"
  //   );
  //   ele.disabled;
  //   return {
  //     style: {
  //       backgroundColor: coloring && "red",
  //     },
  //     disabled: true,
  //     readonly: true,
  //     input: "disabled",
  //   };
  // } else

  if (increasedMarket.includes(model["SKU Name"])) {
    return {
      style: {
        color: "#1fc173",
        fontWeight: "bold",
        backgroundColor: coloring && "#A7D0F1",
      },
      class: {
        bank: true,
      },
    };
  } else if (decreasedMarket.includes(model["SKU Name"])) {
    return {
      style: {
        color: "#b60002",
        fontWeight: "bold",
        backgroundColor: coloring && "#A7D0F1",
      },
      class: {
        bank: true,
      },
    };
  } else {
    return {
      style: {
        backgroundColor: coloring && "#A7D0F1",
      },
      class: {
        bank: true,
      },
    };
  }
};
//summary columns
export function createColumnSummary(increased, decreased) {
  return [
    {
      pin: "colPinStart",
      prop: "Brand",
      name: "Name",
      size: 150,
      cellProperties: ({ model }) => {
        return {
          style: {
            color: "black",
          },
        };
      },
    },
    {
      prop: "base_market_share",
      name: "Base Market Share",
      columnType: "two_without_percentage",
      size: 150,
      cellProperties: ({ model }) => {
        return {
          style: {
            color: "black",
          },
        };
      },
    },
    {
      prop: "new_market_share",
      name: "New Market Share",
      columnType: "two_without_percentage",
      size: 100,
      cellProperties: ({ prop, model, data, column }) => {
        return increasedDecreasedByMarketShare(data, model);
      },
    },
  ];
}

function ColoringColumnsStartWithNew(columnName) {
  return {
    prop: columnName,
    size: 150,
    readonly: true,
    name:
      columnName == "new_value_sales"
        ? "revenue"
        : columnName.replace(/_/g, " "),
    columnType:
      columnName == "new_value_sales"
        ? "two_without_percentage_1"
        : columnName == "new_size"
        ? ""
        : "three",
    cellProperties: ({ prop, model, data, column }) => {
      return increasedDecreasedByMarketShare(data, model, "");
    },
  };
}
function NumericColumnsCanModify(columnName) {
  return {
    prop: columnName,
    name: columnName.replace(/_/g, " "),
    size: 125,
    columnType: columnName == "new_price" ? "price" : "",
    cellProperties: ({ prop, model, data, column }) => {
      if (model.hasOwnProperty("Brand")) {
        return {
          style: {
            backgroundColor: "#A7D0F1",
            opacity: 0.7,
            cursor: "not-allowed",
          },
        };
      }
      return increasedDecreasedByMarketShare(data, model, "coloring");
    },
  };
}
function OptionsColumnsCanModify(columnName, columnsOptions) {
  const dropdown = {
    source: columnsOptions[columnName].map((item) => item),
  };
  return {
    prop: columnName,
    ...dropdown,
    name: columnName.replace(/_/g, " "),
    columnType: "select",
    size: 100,
    cellProperties: ({ prop, model, data, column }) => {
      if (model.hasOwnProperty("Brand")) {
        return {
          style: {
            backgroundColor: "#A7D0F1",
            opacity: 0.7,
            cursor: "not-allowed",
          },
        };
      }
      return {
        style: {
          backgroundColor: "#A7D0F1",
        },
      };
    },
  };
}
function skuNameColumn(columnName, toggleRow) {
  return {
    name: columnName,
    readonly: "true",
    size: 150,
    pin: "colPinStart",
    cellTemplate: (createElement, props) => {
      const expandIcon = props.model.expanded ? "-" : "+";
      if (props.model.children) {
        const butt = createElement("input", {
          type: "button",
          dataid: props.model._id,
          onClick: toggleRow,
          value: expandIcon,
        });
        const output = createElement(
          "div",
          {
            style: {
              textAlign: "left",
              backgroundColor: "#A7D0F1",
            },
          },
          butt,
          `${props.model["SKU Name"]}`
        );

        return output;
      } else {
        const output = createElement(
          "div",
          {
            style: {
              textAlign: "left",
              marginLeft: `${props.model.level * 30}px`,
            },
          },
          `${props.model["SKU Name"]}`
        );

        return output;
      }
    },
  };
}
//revo grid columns
export function createCheckboxCellTemplate(
  state,
  setState,
  ChangedSOPRequest,
  toggleRow,
  increasedAttr,
  decreasedAttr,
  props
) {
  //remove columns that related to grouping
  const itemsNotShow = props.groups.map((item) => item.key);
  const itemsToSHow = props.columns.filter(
    (item) => !itemsNotShow.includes(item)
  );

  const renderColumns = itemsToSHow.map((column) => {
    const numericColumns = props.numeric_columns;
    const columnsOptions = props.options;
    const financialColumnsToApply = [
      "base_market_share",
      "base_value_share",
      "base_value_sales",
      "base_sop",
    ];
    if (numericColumns.includes(column)) {
      //first deal with numeric options
      return NumericColumnsCanModify(column);
    } else if (Object.keys(columnsOptions).includes(column)) {
      //second deal with options columns
      return OptionsColumnsCanModify(column, columnsOptions);
    } else {
      //third deal with other column check if sku first
      if (column == "SKU Name") {
        return skuNameColumn(column, toggleRow);
      } else if (column.startsWith("new_")) {
        //second if deal with columns start with new then coloring it
        return ColoringColumnsStartWithNew(column);
      } else {
        // finally deal with other data such start with base
        return {
          readonly: "true",
          prop: column,
          columnType:
            column === "base_size"
              ? ""
              : column === "base_price"
              ? "price"
              : column === "Company"
              ? ""
              : "three",
          name: column.replace(/_/g, " "),
          size: financialColumnsToApply.includes(column) ? 150 : 100,
        };
      }
    }
  });
  const firstCell = {
    pin: "colPinStart",
    size: 50,
    readonly: "true",
    // defining checkbox template
    cellTemplate: (createElement, props) => {
      props.model["checked"] = props.model["Market Share"];
      const input = createElement("input", {
        type: "checkbox",
        // store is selected in rows "checked" prop
        checked: props.model["checked"],
        onChange: (e) => {
          if (props.model["new_wd"] > 0) {
            props.model["Market Share"] = e.target.checked;
            props.model["checked"] = e.target.checked;
            const data = {};
            data["name"] = state.projectname;
            data["SKU"] = props.model["SKU Name"];
            data["check"] = props.model["checked"];
            setState({ isFetching: true });
            setState({
              scenario_state_undo: [...state.scenario_state_undo, data],
            });
            ChangedSOPRequest(data);
            if (input) {
              input.$attrs$.checked = e.target.checked;
            }
          } else {
            props.model["Market Share"] = 0;
            props.model["checked"] = false;
            setState({
              wd: "Weighted Distribution",
              inputInvalid: true,
              invalidSKU: props.model["SKU Name"],
              invalidMin: 0,
              invalidMax: 100,
            });
          }
        },
      });
      return input;
    },
  };

  return [firstCell, ...renderColumns];
  // return [
  //   {
  //     pin: "colPinStart",
  //     size: 50,
  //     readonly: "true",
  //     // defining checkbox template
  //     cellTemplate: (createElement, props) => {
  //       // console.log(props, props.model["Market Share"]);
  //       props.model["checked"] = props.model["Market Share"];
  //       const input = createElement("input", {
  //         type: "checkbox",
  //         // store is selected in rows "checked" prop
  //         checked: props.model["checked"],
  //         onChange: (e) => {
  //           if (props.model["new_wd"] > 0) {
  //             props.model["Market Share"] = e.target.checked;
  //             props.model["checked"] = e.target.checked;
  //             const data = {};
  //             data["name"] = state.projectname;
  //             data["SKU"] = props.model["SKU Name"];
  //             data["check"] = props.model["checked"];
  //             setState({ isFetching: true });
  //             setState({
  //               scenario_state_undo: [...state.scenario_state_undo, data],
  //             });
  //             ChangedSOPRequest(data);
  //             if (input) {
  //               input.$attrs$.checked = e.target.checked;
  //             }
  //           } else {
  //             props.model["Market Share"] = 0;
  //             props.model["checked"] = false;
  //             setState({
  //               wd: "Weighted Distribution",
  //               inputInvalid: true,
  //               invalidSKU: props.model["SKU Name"],
  //               invalidMin: 0,
  //               invalidMax: 100,
  //             });
  //           }
  //         },
  //       });
  //       return input;
  //     },
  //   },
  //   {
  //     name: "SKUs",
  //     readonly: "true",
  //     size: 150,
  //     pin: "colPinStart",
  //     cellTemplate: (createElement, props) => {
  //       const expandIcon = props.model.expanded ? "-" : "+";
  //       if (props.model.children) {
  //         const butt = createElement("input", {
  //           type: "button",
  //           dataid: props.model._id,
  //           onClick: toggleRow,
  //           value: expandIcon,
  //         });
  //         const output = createElement(
  //           "div",
  //           {
  //             style: {
  //               textAlign: "left",
  //               backgroundColor: "#A7D0F1",
  //             },
  //           },
  //           butt,
  //           `${props.model["SKU Name"]}`
  //         );

  //         return output;
  //       } else {
  //         const output = createElement(
  //           "div",
  //           {
  //             style: {
  //               textAlign: "left",
  //               marginLeft: `${props.model.level * 30}px`,
  //             },
  //           },
  //           `${props.model["SKU Name"]}`
  //         );

  //         return output;
  //       }
  //     },
  //   },
  //   {
  //     readonly: "true",
  //     prop: "base_market_share",
  //     name: "Base Market Share",
  //     columnType: "three",
  //   },
  //   {
  //     readonly: "true",
  //     prop: "new_market_share",
  //     name: "New Market Share",
  //     columnType: "three",
  //     cellProperties: ({ prop, model, data, column }) => {
  //       if (increasedMarket.includes(model["SKU Name"])) {
  //         return {
  //           style: {
  //             color: "#1fc173",
  //             fontWeight: "bold",
  //             textAlign: "right",
  //           },
  //           class: {
  //             bank: true,
  //           },
  //         };
  //       }
  //       if (decreasedMarket.includes(model["SKU Name"])) {
  //         return {
  //           style: {
  //             color: "#b60002",
  //             fontWeight: "bold",
  //           },
  //           class: {
  //             bank: true,
  //           },
  //         };
  //       }
  //     },
  //   },
  //   {
  //     readonly: "true",
  //     prop: "base_value_share",
  //     name: "Base Value Share",
  //     columnType: "three",
  //   },
  //   {
  //     readonly: "true",
  //     prop: "new_value_share",
  //     name: "New Value Share",
  //     columnType: "three",
  //     cellProperties: ({ prop, model, data, column }) => {
  //       if (increasedMarket.includes(model["SKU Name"])) {
  //         return {
  //           style: {
  //             color: "#1fc173",
  //             fontWeight: "bold",
  //           },
  //           class: {
  //             bank: true,
  //           },
  //         };
  //       }
  //       if (decreasedMarket.includes(model["SKU Name"])) {
  //         return {
  //           style: {
  //             color: "#b60002",
  //             fontWeight: "bold",
  //           },
  //           class: {
  //             bank: true,
  //           },
  //         };
  //       }
  //     },
  //   },
  //   {
  //     readonly: "true",
  //     prop: "new_value_sales",
  //     name: "Revenue",
  //     columnType: "two_without_percentage_1",
  //     cellProperties: ({ prop, model, data, column }) => {
  //       if (increasedMarket.includes(model["SKU Name"])) {
  //         return {
  //           style: {
  //             color: "#1fc173",
  //             fontWeight: "bold",
  //           },
  //           class: {
  //             bank: true,
  //           },
  //         };
  //       }
  //       if (decreasedMarket.includes(model["SKU Name"])) {
  //         return {
  //           style: {
  //             color: "#b60002",
  //             fontWeight: "bold",
  //           },
  //           class: {
  //             bank: true,
  //           },
  //         };
  //       }
  //     },
  //   },
  //   {
  //     readonly: "true",
  //     prop: "base_wd",
  //     name: "Base WD",
  //   },
  //   {
  //     prop: "new_wd",
  //     name: "New WD",
  //     cellProperties: ({ prop, model, data, column }) => {
  //       if (increasedMarket.includes(model["SKU Name"])) {
  //         return {
  //           style: {
  //             backgroundColor: "#A7D0F1",
  //             color: "#1fc173",
  //             fontWeight: "bold",
  //           },
  //           class: {
  //             bank: true,
  //           },
  //         };
  //       } else if (decreasedMarket.includes(model["SKU Name"])) {
  //         return {
  //           style: {
  //             backgroundColor: "#A7D0F1",
  //             color: "#b60002",
  //             fontWeight: "bold",
  //           },
  //           class: {
  //             bank: true,
  //           },
  //         };
  //       } else {
  //         return {
  //           style: {
  //             backgroundColor: "#A7D0F1",
  //           },
  //         };
  //       }
  //     },
  //   },
  // ];
}
