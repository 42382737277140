import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import arrowTop from "../Assets/ImgTop.png";
import arrowBottom from "../Assets/ImgBottom.png";
import volumeSalesImage from "../Assets/Volume.png";
import price from "../Assets/price.svg";
import rgm from "../Assets/ImgTop.png";
import valueShareImage from "../Assets/valueShare.png";
import { theme } from "../Theme/index";
import {
  CardLandscape,
  CardMarket,
  CardLandscapeWithout,
} from "../Components/Card";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import ValueSalesandVolumeSales from "../Components/Landscape/ValueSalesandVolumeSales";
import {
  ChartbrandValue,
  ChartGRMIndex,
  ChartPriceIndex,
  ChartPriceValue,
} from "../Components/Chart";
import {
  getMarketKPIs,
  getMFCKPIs,
  RGMIndex,
  MarketSegment,
  MFCSegment,
  priceIndexApi,
} from "../Components/Network/Lib/Sales";
import {
  Gaps,
  Opportunities,
} from "../Components/Landscape/GapandOpportunities";
import { SizeShare, ValueShare } from "../Components/Table";
import { CircularProgress, Divider, Paper } from "@mui/material";

const mdTheme = createTheme();

function Landscape() {
  const [show, toggleShow] = React.useState({ active: 0 });
  const [key, setKey] = React.useState(0);

  //Market
  const [valueSales, setValueSales] = React.useState(0);
  const [volumeSales, setVolumeSales] = React.useState(0);
  const [priceIndex, setPriceIndex] = React.useState(0);

  const [valueSalesIYA, setValueSalesIYA] = React.useState(0);
  const [volumeSalesIYA, setVolumeSalesIYA] = React.useState(0);
  const [priceIndexIYA, setPriceIndexIYA] = React.useState(0);

  //Manufacturer
  const [valueShare, setValueShare] = React.useState(0);
  const [volumeShare, setVolumeShare] = React.useState(0);
  const [RGM, setRGM] = React.useState(0);

  const [valueShareIYA, setValueShareIYA] = React.useState(0);
  const [volumeShareIYA, setVolumeShareIYA] = React.useState(0);
  const [RGMIYA, setRGMIYA] = React.useState(0);

  //RGM
  const [valueShareRGM, setValueShareRGM] = React.useState();
  const [volumeShareRGM, setVolumeShareRGM] = React.useState();
  const [RGMChart, setRGMChart] = React.useState();
  const [brands, setBrands] = React.useState();

  //Value Share and Volume Share
  const [segmentMKT, setSegmentMKT] = React.useState([]);
  const [segmentMFC, setSegmentMFC] = React.useState([]);

  //Price index chart in MKT
  const [priceIndexChart, setPriceIndexChart] = React.useState([]);
  const [years, setYears] = React.useState([]);

  const toggleChart = (id) => {
    toggleShow({ active: id });
    toggleShow({ active: 0 });
    setKey(id);
  };

  React.useEffect(() => {
    //MKT
    getMarketKPIs().then((response) => {
      setValueSales(Math.floor(response.data.value_sales));
      setVolumeSales(Math.floor(response.data.volume_sales));
      setPriceIndex(response.data.price_iya.toFixed(2));

      setPriceIndexIYA(response.data.price_index_iya);
      setValueSalesIYA(response.data.value_sales_iya.toFixed(3));
      setVolumeSalesIYA(response.data.volume_sales_iya.toFixed(3));
    });

    //MFC
    getMFCKPIs().then((response) => {
      setValueShare(response.data.value_share.toFixed(3)); //%
      setVolumeShare(response.data.volume_share.toFixed(3));
      setRGM(response.data.rgm.toFixed(2));

      setRGMIYA(response.data.rgm.toFixed(3));
      setValueShareIYA(response.data.value_share_iya.toFixed(3));
      setVolumeShareIYA(response.data.volume_share_iya.toFixed(3));
    });

    //RGM
    RGMIndex().then((response) => {
      // console.log(typeof response.data.value_share);
      setValueShareRGM(
        response.data.value_share.map((value) => value.toFixed(2))
      );
      setVolumeShareRGM(
        response.data.volume_share.map((value) => value.toFixed(2))
      );
      setRGMChart(response.data.rgm.map((value) => value.toFixed(2)));
      setBrands(response.data.brands);
    });
    //value share and volume share (MKT)
    MarketSegment().then((response) => {
      setSegmentMKT(response.data);
    });

    //value share and volume share (MFC)

    MFCSegment().then((response) => {
      setSegmentMFC(response.data);
    });

    //price Index
    priceIndexApi().then((response) => {
      response.data.brands_lines.map((val) => {
        return (
          (val["name"] = val["brand"]),
          (val["data"] = val["line"]),
          delete val["brand"],
          delete val["line"]
        );
      });
      setPriceIndexChart(response.data.brands_lines);
      setYears(response.data.years);
      console.log(response.data.brands_lines);
    });
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        {/* <Toolbar /> */}

        <Box maxWidth="lg">
          <Typography
            sx={{
              color: `${theme.palette.primary.main}`,
              mt: 2,

              fontWeight: "bold",
              fontSize: "25px",
              fontFamily: "Barlow Semi Condensed",
            }}
          >
            Market
          </Typography>
          <Box
            sx={{
              fontSize: "1.1rem",
              mb: 1,
              color: "#6D6D6D",
            }}
          >
            It always starts with market KPIs reflecting performance of
            manufacturers vs. the market on national level and across product
            segments, channels, and retailers. Clicking on KPIs provides more
            details.
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={8} sx={{ margin: "0px" }}>
              <CardMarket
                title1="Value  Sales (M SAR)"
                title2="Volume  Sales (M Kg)"
                number1={
                  valueSales ? (
                    <>{`1,442`}</>
                  ) : (
                    <>
                      <CircularProgress />
                    </>
                  )
                }
                number2={
                  volumeSales ? (
                    <>{`178`}</>
                  ) : (
                    <>
                      <CircularProgress />
                    </>
                  )
                }
                IYA1="1.09"
                IYA2="0.84"
                image1={arrowTop}
                image2={arrowBottom}
                fun={() => toggleChart(1)}
                backgroundColor={`${
                  show && key === 1 ? "rgba(214,222,236,0.5)" : ""
                }`}
                icon={
                  show && key === 1 ? (
                    <>
                      <KeyboardArrowUpOutlinedIcon
                        sx={{
                          color: "blue",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <KeyboardArrowDownOutlinedIcon
                        sx={{
                          color: "gray",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                    </>
                  )
                }
              />
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <CardLandscapeWithout
                title="Price  Index YA"
                number={
                  priceIndex ? (
                    <>{priceIndex}</>
                  ) : (
                    <>
                      <CircularProgress />
                    </>
                  )
                }
                IYA={priceIndexIYA}
                image={arrowTop}
                id="box"
                fun={() => toggleChart(2)}
                backgroundColor={`${
                  show && key === 2 ? "rgba(214,222,236,0.5)" : ""
                }`}
                icon={
                  show && key === 2 ? (
                    <>
                      <KeyboardArrowUpOutlinedIcon
                        sx={{
                          color: "blue",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <KeyboardArrowDownOutlinedIcon
                        sx={{
                          color: "gray",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                    </>
                  )
                }
              />
            </Grid>
          </Grid>
          {key === 1 && show && <ValueSalesandVolumeSales />}
          {key === 2 && show && (
            <ChartPriceIndex
            // names={priceIndexChart&& priceIndexChart}
            // years={years}
            />
          )}
          {/* <SizeShare/> */}
          <Typography
            sx={{
              color: `${theme.palette.primary.main}`,
              m: 2,
              fontWeight: "bold",
              fontSize: "25px",
              fontFamily: "Barlow Semi Condensed",
            }}
          >
            Manufacturer
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={4}>
              <CardLandscapeWithout
                title="RGM Index"
                format="(Value Share/ Volume Share)"
                number={
                  RGM ? (
                    <>{RGM}</>
                  ) : (
                    <>
                      <CircularProgress />
                    </>
                  )
                }
                image={rgm}
                fun={() => toggleChart(5)}
                backgroundColor={`${
                  show && key === 5 ? "rgba(214,222,236,0.5)" : ""
                }`}
                icon={
                  show && key === 5 ? (
                    <>
                      <KeyboardArrowUpOutlinedIcon
                        sx={{
                          color: "blue",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <KeyboardArrowDownOutlinedIcon
                        sx={{
                          color: "gray",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                    </>
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={12} lg={8} sx={{ margin: "0px" }}>
              <CardMarket
                title1="Value  Share"
                title2="Volume  Share"
                number1={
                  valueShare ? (
                    <>{`${valueShare * 100}%`}</>
                  ) : (
                    <>
                      <CircularProgress />
                    </>
                  )
                }
                number2={
                  volumeShare ? (
                    <>{`${volumeShare * 100}%`}</>
                  ) : (
                    <>
                      <CircularProgress />
                    </>
                  )
                }
                IYA1={Math.floor(valueShareIYA)}
                IYA2="0.84"
                image1={arrowTop}
                image2={arrowBottom}
                fun={() => toggleChart(4)}
                backgroundColor={`${
                  show && key === 4 ? "rgba(214,222,236,0.5)" : ""
                }`}
                icon={
                  show && key === 4 ? (
                    <>
                      <KeyboardArrowUpOutlinedIcon
                        sx={{
                          color: "blue",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <KeyboardArrowDownOutlinedIcon
                        sx={{
                          color: "gray",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                    </>
                  )
                }
              />
            </Grid>

            <Grid item xs={12}></Grid>
          </Grid>
          {key === 4 && show && (
            <>
              <ValueShare />
            </>
          )}
          {key === 5 && show && (
            <ChartGRMIndex
              titleY="Market"
              titleX="Volume Share"
              titleLine="RGM Health Index"
              dataX={volumeShareRGM}
              dataY={valueShareRGM}
              line={RGMChart}
              labels={brands}
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper sx={{ p: 4 }}>
                <ChartbrandValue />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 4 }}>
                <ChartPriceValue />
              </Paper>
            </Grid>
          </Grid>
          <Typography
            sx={{
              color: `${theme.palette.primary.main}`,
              mt: 2,
              fontWeight: "bold",
              fontSize: "25px",

              fontFamily: "Barlow Semi Condensed",
            }}
          >
            Insights
          </Typography>
          <Box
            sx={{
              fontSize: "1.1rem",
              mb: 2,

              color: "#6D6D6D",
            }}
          >
            As per your criteria, our Robots go through your data to pinpoint
            where are the exact gaps the opportunities, to directly identity
            areas where interventions are needed, without losing searching time
            within data.
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Opportunities />
            </Grid>
            <Grid item xs={6}>
              <Gaps />
            </Grid>
          </Grid>{" "}
          {/* Oppun */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Landscape;
