import React from "react";
import { Box } from "@mui/material";

import LabTabs from "../../Components/Tabs";
import MarketTrend from "../../Components/MarketLandscape/marketTrend/MarketTrend";
import Segment from "../../Components/MarketLandscape/segment/Segment";
import Trade from "../../Components/MarketLandscape/trade/Trade";
import Competitors from "../../Components/MarketLandscape/competitors/Competitors";

function MarketLandscape() {
  const tabs = ["Market Trend", "Segment", "Trade", "Competitors"];
  const navigateTabs = tabs.map((ele, idx) => {
    if (ele == "Market Trend") {
      return <MarketTrend />;
    }
    if (ele == "Segment") {
      return <Segment />;
    }
    if (ele == "Trade") {
      return <Trade />;
    }
    if (ele == "Competitors") {
      return <Competitors />;
    }
  });
  return (
    <>
      <LabTabs tabs={tabs} navigateTabs={navigateTabs} />
    </>
  );
}

export default MarketLandscape;
