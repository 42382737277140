import { Box, Paper } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function TimeLineChart() {
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "rangeBar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        dataLabels: {
          hideOverflowingLabels: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        var label = opts.w.globals.labels[opts.dataPointIndex];
        return label;
      },
      style: {
        colors: ["#f3f4f5", "#fff"],
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      show: false,
    },
    grid: {
      row: {
        colors: ["#f3f4f5", "#fff"],
        opacity: 1,
      },
    },
  });

  const [series, setSeries] = useState([
    {
      data: [
        {
          x: "afia",
          y: [
            new Date("2019-02-27").getTime(),
            new Date("2019-03-04").getTime(),
          ],
          fillColor: "#008FFB",
        },
        {
          x: "araby",
          y: [
            new Date("2019-03-04").getTime(),
            new Date("2019-03-08").getTime(),
          ],
          fillColor: "#00E396",
        },
        {
          x: "tayeb",
          y: [
            new Date("2019-03-07").getTime(),
            new Date("2019-03-10").getTime(),
          ],
          fillColor: "#775DD0",
        },
        {
          x: "shams",
          y: [
            new Date("2019-03-08").getTime(),
            new Date("2019-03-12").getTime(),
          ],
          fillColor: "#FEB019",
        },
        {
          x: "abu zahra",
          y: [
            new Date("2019-03-12").getTime(),
            new Date("2019-03-17").getTime(),
          ],
          fillColor: "#FF4560",
        },
      ],
    },
  ]);

  return (
    <Paper>
      <ReactApexChart
        options={options}
        series={series}
        type="rangeBar"
        height={300}
      />
    </Paper>
  );
}

export default TimeLineChart;
