import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box } from "@mui/material";
import TableDividerPieChart from "./TableDividerPieChart";
import SegmentTable from "./SegmentTable";

function TableAccordion() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const iconStyle = {
    color: "white",
    fontSize: "22px",
    position: "absolute",
    right: "25px",
    top: "25px",
    borderRadius: "50%",
    backgroundColor: "#1976D2",
  };

  return (
    <Box>
      <Accordion onChange={handleChange}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box sx={{ width: "100%" }}>
            {!expanded ? (
              <ExpandMoreIcon
                sx={{
                  ...iconStyle,
                }}
              />
            ) : (
              <ExpandLessIcon
                sx={{
                  ...iconStyle,
                }}
              />
            )}
            <TableDividerPieChart expanded={expanded} />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <SegmentTable />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default TableAccordion;
