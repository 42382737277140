import axios from "axios";
import { getToken, getUrl } from "./imports";

/* start Create a reusable Axios with headers */
export var apiInstance = axios.create({
  baseURL: getUrl(),
  headers: {
    "x-access-token": getToken(),
    "x-cors-api-key": "temp_30b78faf43d6cf3223cb3734dbc68066",
  },
});
/* end Create a reusable Axios with headers*/

export function groupDataByField(data, group) {
  let grouped = [];
  data.forEach(function (o) {
    if (!this[o[group[0]]]) {
      this[o[group[0]]] = {
        Brand: o[group[0]],
        base_sop: 0,
        new_sop: 0,
        base_market_share: 0,
        new_market_share: 0,
        base_value_share: 0,
        new_value_share: 0,
        base_value_sales: 0,
        new_value_sales: 0,
        new_sales: 0,
        base_sales: 0,
        base_penetration: 0,
        new_penetration: 0,
      };
      grouped.push(this[o[group[0]]]);
    }
    this[o[group[0]]].base_sop += o.base_sop;
    this[o[group[0]]].new_sop += o.new_sop;
    this[o[group[0]]].base_market_share += o.base_market_share;
    this[o[group[0]]].new_market_share += o.new_market_share;
    this[o[group[0]]].base_value_share += o.base_value_share;
    this[o[group[0]]].new_value_share += o.new_value_share;
    this[o[group[0]]].base_value_sales += o.base_value_sales;
    this[o[group[0]]].new_value_sales += o.new_value_sales;
    this[o[group[0]]].new_sales += o.new_sales;
    this[o[group[0]]].base_sales += o.base_sales;
    this[o[group[0]]].base_penetration += o.base_penetration;
    this[o[group[0]]].new_penetration += o.new_penetration;
  }, Object.create(null));

  return grouped;
}

export function groupDataByField2(data, group, parentId) {
  let grouped = [];
  data.forEach(function (o) {
    if (!this[o[group]]) {
      this[o[group]] = {
        parentId: parentId,
        Brand: o[group],
        base_sop: 0,
        new_sop: 0,
        base_market_share: 0,
        new_market_share: 0,
        base_value_sales: 0,
        new_value_sales: 0,
        base_value_share: 0,
        new_value_share: 0,
        new_sales: 0,
        base_sales: 0,
        base_penetration: 0,
        new_penetration: 0,
      };
      grouped.push(this[o[group]]);
    }
    this[o[group]].base_sop += o.base_sop;
    this[o[group]].new_sop += o.new_sop;
    this[o[group]].base_market_share += o.base_market_share;
    this[o[group]].new_market_share += o.new_market_share;
    this[o[group]].base_value_share += o.base_value_share;
    this[o[group]].new_value_share += o.new_value_share;
    this[o[group]].base_value_sales += o.base_value_sales;
    this[o[group]].new_value_sales += o.new_value_sales;
    this[o[group]].new_sales += o.new_sales;
    this[o[group]].base_sales += o.base_sales;
    this[o[group]].base_penetration += o.base_penetration;
    this[o[group]].new_penetration += o.new_penetration;
  }, Object.create(null));

  return grouped;
}

/*start handle grouping */
export function updateTreeState(tree, expandedAll, setState) {
  let trimmedRows = {};
  for (let i = 0; i < tree.length; i++) {
    const row = tree[i];
    row.expanded = expandedAll;
    row.visible = row.parentId === 0 || expandedAll;
    if (!row.visible) {
      trimmedRows[i] = true;
    }
  }
  setState({
    trimmedRows: trimmedRows,
    tree: tree,
    trimmedRowsEdited: true,
  });
}

export function updateSummaryDataAndRowData(group, summaryData, rowData) {
  for (const i in summaryData) {
    summaryData[i]["SKU Name"] = summaryData[i]["Brand"];
    summaryData[i]["parentId"] = 0;
    summaryData[i]["_id"] = parseInt(i) + 1;
    for (const k in rowData) {
      if (rowData[k][group] === summaryData[i]["Brand"]) {
        rowData[k]["parentId"] = parseInt(i) + 1;
      }
    }
  }

  for (const i in rowData) {
    rowData[i]["_id"] = parseInt(i) + summaryData.length + 1;
  }
}

export function updateParentIds(group, rowData, summaryData) {
  for (const k in rowData) {
    for (const i in summaryData) {
      let parentId = 0;
      let count = 0;
      let searchIndex = 0;
      for (let j = group.length - 1; j >= 0; j--) {
        if (rowData[k][group[j]] === summaryData[i]["Brand"]) {
          if (searchIndex === 0 && summaryData[i]["parentId"] !== 0) {
            searchIndex = summaryData.findIndex(
              (el) => el._id == summaryData[i]["parentId"]
            );
            count = count + 1;
            parentId = summaryData[i]["_id"];
          }
        } else if (
          rowData[k][group[j]] === summaryData[searchIndex]["Brand"] &&
          searchIndex !== 0
        ) {
          count = count + 1;
        }
      }
      if (count === 2) {
        rowData[k]["parentId"] = parentId;
        break;
      }
    }
  }
}

export function summaryConcatRowData(summaryData, rowData) {
  const inputData = summaryData.concat(rowData);
  const hashArr = {};
  // go through all items in data source
  for (let i = 0; i < inputData.length; i++) {
    // get parent id
    // if parent id is not present create new array with childs for this parent
    // it’s a key to store elements based on their parent ids in a hashmap
    if (hashArr[inputData[i].parentId] === undefined) {
      hashArr[inputData[i].parentId] = [];
    }
    // childs per parent
    hashArr[inputData[i].parentId].push(inputData[i]);
  }
  return hashArr;
}

export function processGroupData(group, summaryData, rowData, handleSummary2) {
  let sumData = [];

  for (const g in group) {
    let temp_data = [];
    let new_data = [];
    if (parseInt(g) === 0) {
      for (const i in summaryData) {
        summaryData[i]["SKU Name"] = summaryData[i]["Brand"];
        summaryData[i]["parentId"] = 0;
        summaryData[i]["_id"] = parseInt(i) + 1;
        for (const k in rowData) {
          if (rowData[k][group[g]] === summaryData[i]["Brand"]) {
            temp_data.push(rowData[k]);
          }
        }
        new_data = handleSummary2(
          group[parseInt(g) + 1],
          temp_data,
          parseInt(i) + 1,
          summaryData.length + sumData.length
        );
        sumData = sumData.concat(new_data);
      }
      summaryData = sumData.concat(summaryData);
    }
  }
  return summaryData;
}
/*end handle grouping */

/* start handle add and remove digit */
export function handleLessHandler(columns) {
  const columnTypeMapping = {
    two: "one",
    three: "two",
    four: "three",
    five: "four",
    six: "five",
    seven: "six",
    eight: "seven",
  };

  return mapToColumns(columns, columnTypeMapping);
}
export function handleMoreDigit(columns) {
  // Define the mapping of columnType values
  const columnTypeMapping = {
    one: "two",
    two: "three",
    three: "four",
    four: "five",
    five: "six",
    six: "seven",
    seven: "eight",
  };

  return mapToColumns(columns, columnTypeMapping);
}
function mapToColumns(columns, columnTypeMapping) {
  // loop to columns, then spread the object to clone it then modify columnTypes
  // set columnType by the next digit from columnTypeMapping
  // if the digit greater than it then return the fall back value => column.columnType
  const updatedColumns = columns.map((column) => {
    return {
      ...column,
      columnType: columnTypeMapping[column.columnType] || column.columnType,
    };
  });
  return updatedColumns;
}
/* end handle add and remove digit */

/* start UpdateColumns */

export function buildNewColumn(data, key, increasedAttr, decreasedAttr) {
  return {
    prop: Object.keys(data["type"][key])[0].replace("base", "new"),
    name: Object.keys(data["type"][key])[0].replace("base_", "new "),
    columnType: "three",
    cellProperties: ({ prop, model, data, column }) => {
      if (increasedAttr.includes(model["SKU Name"])) {
        return {
          style: {
            backgroundColor: "#A7D0F1",
            color: "#1fc173",
            fontWeight: "bold",
          },
          class: {
            bank: true,
          },
        };
      } else if (decreasedAttr.includes(model["SKU Name"])) {
        return {
          style: {
            backgroundColor: "#A7D0F1",
            color: "#b60002",
            fontWeight: "bold",
          },
          class: {
            bank: true,
          },
        };
      } else {
        return {
          style: {
            backgroundColor: "#A7D0F1",
          },
        };
      }
    },
  };
}
/* end UpdateColumns */

/* start after edit */

export function afterEditInitialData(
  event,
  changed,
  changed1,
  data,
  setState,
  inputInvalid,
  handleChangedAttr,
  ChangedSOPRequest,
  scenario_state_undo
) {
  for (const key in event.detail.data) {
    let a = Object.keys(event.detail.data[key]);
    changed = a[0];
  }
  changed1 = changed.replace("Base ", "");
  changed1 = changed1.replace("New ", "");
  data[changed1] = {};
  for (const key in event.detail.data) {
    const skuname = `${event.detail.models[key]["SKU Name"]}`;
    const value = `${event.detail.data[key][changed]}`;
    const min = event.detail.models[key]["min " + changed1];
    const max = event.detail.models[key]["max " + changed1];
    data[changed1][skuname] = value;
    if (max <= parseFloat(value) || parseFloat(value) <= min) {
      setState({
        invalidSKU: skuname,
        invalidMax: max,
        invalidMin: min,
      });
      setState({
        isFetching: false,
      });
      setState({
        inputInvalid: true,
      });
    }
  }

  if (!inputInvalid) {
    handleChangedAttr(changed1);
    setState({
      scenario_state_undo: [...scenario_state_undo, data],
    });
    ChangedSOPRequest(data);
  }
}

export function afterEditModifiedData(
  event,
  changed1,
  firstVal,
  projectname,
  setState,
  handleChangedAttr,
  ChangedSOPRequest,
  scenario_state_undo,
  SKUs
) {
  const changed = event.detail.prop;
  changed1 = changed.replace("base ", "");
  changed1 = changed1.replace("new ", "");
  let value = event.detail.val;
  const skuname = event.detail.model["SKU Name"];
  if (value.includes("+")) {
    value = firstVal + firstVal * (parseFloat(value) / 100);
    event.detail.model[changed] = value;
  } else {
    if (value.includes("-")) {
      value = firstVal + firstVal * (parseFloat(value) / 100);
      event.detail.model[changed] = value;
    }
  }
  if (event.detail.model["max " + changed1]) {
    const min = event.detail.model["min " + changed1];
    const max = event.detail.model["max " + changed1];

    if (max >= parseFloat(value) && parseFloat(value) >= min) {
      SKUs.push(skuname);
      const data = {};
      data["name"] = projectname;
      data[changed1] = {};
      data[changed1][skuname] = value;
      setState({
        scenario_state_undo: [...scenario_state_undo, data],
      });
      ChangedSOPRequest(data);
      handleChangedAttr(changed1);
    } else {
      setState({
        invalidSKU: skuname,
        invalidMax: max,
        invalidMin: min,
      });
      setState({
        isFetching: false,
      });
      setState({
        inputInvalid: true,
      });
    }
  } else {
    SKUs.push(skuname);
    const data = {};
    data["name"] = projectname;
    data[changed1] = {};
    data[changed1][skuname] = value;
    setState({
      scenario_state_undo: [...scenario_state_undo, data],
    });
    ChangedSOPRequest(data);
    handleChangedAttr(changed1);
  }
}
/* end after edit*/

/* start applyChangedAttrPercentage */
export function applyChangedAttrPercentage(
  rowData,
  currentSkusAttrModal,
  currentGroup,
  currentAttrModal,
  attrPercentage,
  setState,
  data
) {
  for (let x in rowData) {
    for (let y in currentSkusAttrModal) {
      if (rowData[x][currentGroup] === currentSkusAttrModal[y]) {
        let oldVal = rowData[x][currentAttrModal];
        data[currentAttrModal][rowData[x]["SKU Name"]] = (
          oldVal +
          (oldVal * attrPercentage) / 100
        ).toString();
        rowData[x][currentAttrModal] = oldVal + (oldVal * attrPercentage) / 100;
      }
      let min = rowData[x]["min ".concat(currentAttrModal)];
      let max = rowData[x]["max ".concat(currentAttrModal)];
      if (
        max <= parseFloat(data[currentAttrModal][rowData[x]["SKU Name"]]) ||
        parseFloat(data[currentAttrModal][rowData[x]["SKU Name"]]) <= min
      ) {
        setState({
          invalidSKU: rowData[x]["SKU Name"],
          invalidMax: max,
          invalidMin: min,
        });
        setState({
          isFetching: false,
        });
        setState({
          inputInvalid: true,
        });
        return;
      }
    }
  }
}
/* end applyChangedAttrPercentage */

/*start handleChangedSOP*/
export function handleChangedSOPLoping(
  rowData,
  decreased,
  increased,
  rowData1
) {
  for (var i = 0; i < rowData.length; i++) {
    if (rowData[i].base_sop > rowData[i].new_sop) {
      decreased.push(rowData[i]["SKU Name"]);
    } else {
      if (rowData[i].base_sop < rowData[i].new_sop) {
        increased.push(rowData[i]["SKU Name"]);
      }
    }
  }
  for (i = 0; i < rowData1.length; i++) {
    if (rowData1[i].base_sop > rowData1[i].new_sop) {
      decreased.push(rowData1[i]["Brand"]);
    } else {
      if (rowData1[i].base_sop < rowData1[i].new_sop) {
        increased.push(rowData1[i]["Brand"]);
      }
    }
  }
}
/*end handleChangedSOP*/
/* start update columns */
export function updateColumnsHandler(
  data,
  initial_columns,
  increasedAttr,
  decreasedAttr
) {
  let cs = [...initial_columns];
  let ic = [];
  let c = {};
  let d = [];
  for (const key in data["type"]) {
    if (Object.values(data["type"][key])[0] === 1) {
      d.unshift({
        prop: Object.keys(data["type"][key])[0],
        name: Object.keys(data["type"][key])[0].replace(/_/g, " "),
        columnType: "three",
        readonly: "true",
      });
      d.unshift(buildNewColumn(data, key, increasedAttr, decreasedAttr));
    }
  }
  for (let i = 0; i < d.length; i++) {
    if (d[i].options != null) {
      c = {
        prop: d[i].prop,
        name: d[i].name,
        source: d[i].options,
        columnType: "select",
        autoSizeColumn: "true",
        cellProperties: d[i].cellProperties,
      };
    } else {
      c = {
        prop: d[i].prop,
        name: d[i].name,
        readonly: d[i].readonly,
        cellProperties: d[i].cellProperties,
        autoSizeColumn: "true",
      };
    }
    //remove c from unshift to not repeat the price and size column
    ic.unshift();
    cs.unshift();
  }
  return { ic, cs };
}
/* end update columns */

/* start financial template and current scenario*/
export function exportToExcel(data, filename) {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  console.log(downloadUrl);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
/* end financial template and current scenario*/
