import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const StyledHeaderText = styled(Typography)(({ theme }) => ({
  fontSize: "clamp(1rem, 4vw, 9rem)",
  color: `${theme.palette.primary.main}`,
  fontFamily: "Barlow Semi Condensed",
}));

export const StyledHeaderDescription = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  color: `lightgray`,
  fontFamily: "Helvetica",
  width: "100%",
  marginBottom: "10px",
}));

export const StyledAboutTitle = styled(Typography)(({ theme }) => ({
  fontSize: "clamp(1rem, 3vw, 4rem)",
  color: `${theme.palette.primary.main}`,
  fontFamily: "Barlow Semi Condensed",
}));

export const StyledAbout = styled(Typography)(({ theme }) => ({
  fontSize: "clamp(0rem,0.9vw, 1rem)",
  color: `${theme.palette.primary.light}`,
  fontFamily: "Helvetica",
  // width: "80%",
  fontWeight: "bold",
}));

//Modules
export const StyledModulesDescription = styled(Typography)(({ theme }) => ({
  fontSize: "clamp(1rem,0.8vw, 3rem)",
  color: `${theme.palette.secondary.light}`,
  fontFamily: "Helvetica",
  width: "100%",
  marginBottom: "10px",
  // width: "80%",
  // fontSize: "1rem",
}));

export const StyledModulesTitle = styled(Typography)(({ theme }) => ({
  // fontSize: "clamp(1rem, 3vw, 4rem)",
  fontSize: "1.5rem",
  color: `${theme.palette.primary.main}`,

  //   textAlign:"left"
}));

export const StyledListTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  pl: 4,
  fontFamily: "Barlow Semi Condensed",
  fontWeight: 700,

  color: `#00265E`,
  marginLeft: "14px",
}));

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: `${theme.palette.primary.main}`,
  marginBottom: "10px",
  fontSize: "21px",
  fontFamily: "Barlow Semi Condensed",
}));
