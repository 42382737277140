import React, { useState } from "react";
import { RevoGrid } from "@revolist/revogrid-react";
import { Grid, Button, Icon, Segment, Header, Popup } from "semantic-ui-react";
import MultipleSelectCheckmarks from "../../../Shared/MultipleSelect";

function MainCalculation({ columnTypes, state, functions }) {
  const revoSOP = {
    minHeight: "450px",
    width: "auto",
    textAlign: "left",
  };
  return (
    <Grid.Column width={16}>
      <Segment loading={state.isFetching}>
        <Grid
          columns={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid.Column widescreen={8} computer={4}>
            <Header
              style={{
                color: "#00265e",
                display: "flex",
                alignItems: "center",
              }}
              as="h3"
            >
              <Icon name="chart line" />
              <Header.Content>Main Calculation</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column widescreen={8} computer={4}>
            <MultipleSelectCheckmarks state={state} functions={functions} />
          </Grid.Column>

          <Grid.Column className="nav-grid" width={7}>
            <Popup
              content="Set Current Scenario As Base"
              trigger={
                <Button
                  label="Set current base"
                  icon
                  size="mini"
                  floated="right"
                  variant="contained"
                  basic
                  color="blue"
                  onClick={functions.handleChangeBase}
                  value="Set Scenario As base"
                  style={{ padding: 10 }}
                  disabled={state.changedData["change_dict"] ? false : true}
                >
                  <Icon name="pen square" />
                </Button>
              }
            />
            <Popup
              content="Edit attribute by group"
              trigger={
                <Button
                  icon
                  size="mini"
                  floated="right"
                  variant="contained"
                  basic
                  color="blue"
                  value="Edit attribute by group"
                  onClick={() =>
                    functions.setState({ editGroupAttrFlag: true })
                  }
                  style={{ padding: 10 }}
                >
                  <Icon name="edit outline" />
                </Button>
              }
            />
            <Popup
              content="Save Scenario"
              trigger={
                <Button
                  icon
                  size="mini"
                  floated="right"
                  variant="contained"
                  basic
                  color="blue"
                  value="Save Scenario"
                  onClick={() => functions.setState({ scenarioInput: true })}
                  style={{ padding: 10 }}
                  disabled={state.changedData["change_dict"] ? false : true}
                >
                  <Icon name="save outline" />
                </Button>
              }
            />
            <Popup
              content="Redo"
              trigger={
                <Button
                  disabled={state.scenario_state_redo.length === 0}
                  icon
                  size="mini"
                  floated="right"
                  variant="contained"
                  basic
                  color="blue"
                  value="redo Scenario"
                  onClick={functions.handleRedo}
                  style={{ padding: 10 }}
                >
                  <Icon name="redo" />
                </Button>
              }
            />
            <Popup
              content="Undo"
              trigger={
                <Button
                  disabled={state.scenario_state_undo.length === 0}
                  icon
                  size="mini"
                  floated="right"
                  variant="contained"
                  basic
                  color="blue"
                  value="undo Scenario"
                  onClick={functions.handleUndo}
                  style={{ padding: 10 }}
                >
                  <Icon name="undo" />
                </Button>
              }
            />

            <Popup
              content="Upload Scenario"
              trigger={
                <Button
                  icon
                  size="mini"
                  floated="right"
                  variant="contained"
                  basic
                  color="blue"
                  onClick={() => functions.setState({ addScenario: true })}
                  value="Upload Scenario"
                  style={{ padding: 10 }}
                >
                  <Icon name="upload" />
                </Button>
              }
            />
            <Popup
              content="Download Current Scenario"
              trigger={
                <Button
                  icon
                  size="mini"
                  floated="right"
                  variant="contained"
                  basic
                  color="blue"
                  onClick={functions.exportCurrentScenario}
                  value="Download Current Scenario"
                  style={{ padding: 10 }}
                >
                  <Icon name="download" />
                </Button>
              }
            />
            <Popup
              content="Expand Rows"
              trigger={
                <Button
                  icon
                  size="mini"
                  floated="right"
                  variant="contained"
                  basic
                  color="blue"
                  onClick={() =>
                    functions.setState(
                      {
                        expandedAll: !state.expandedAll,
                      },
                      () =>
                        functions.handleGrouping(
                          state.currentGroup,
                          state.rowData,
                          state.rowData1,
                          false,
                          true
                        )
                    )
                  }
                  value="Expand Rows"
                  style={{ padding: 10 }}
                >
                  <Icon name="expand" />
                </Button>
              }
            />
            <Popup
              content="Finance"
              trigger={
                <Button
                  icon
                  size="mini"
                  floated="right"
                  variant="contained"
                  basic
                  color="blue"
                  onClick={functions.financeMinimize}
                  value="finance"
                  style={{ padding: 10 }}
                >
                  <Icon name="dollar" />
                </Button>
              }
            />
            <Popup
              content="Introduce New Product"
              trigger={
                <Button
                  icon
                  size="mini"
                  floated="right"
                  variant="contained"
                  basic
                  color="blue"
                  onClick={functions.handleintroduction}
                  value="introduction"
                  style={{ padding: 10 }}
                >
                  <Icon name="plus cart" />
                </Button>
              }
            />
          </Grid.Column>
        </Grid>
        <RevoGrid
          stretch="true"
          resize
          style={revoSOP}
          range="true"
          columns={state.columns}
          columnTypes={columnTypes}
          source={state.tree}
          trimmedRows={state.trimmedRows}
          onAfteredit={(e) => functions.afterEdit(e)}
          onBeforeeditstart={(e) => functions.beforeEditStart(e)}
          onBeforeedit={(e) => functions.beforeEdit(e)}
          className="revo-grid"
        />
      </Segment>
    </Grid.Column>
  );
}

export default MainCalculation;
