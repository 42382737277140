import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CollapseNotExtend } from "../Collapse";
import { useNavigate } from "react-router-dom";

export default function ListItem({
  label,
  open,
  subPath,
  theme,
  activeCondition,
  img,
  active,
  onClick,
  expend,
}) {
  return (
    <>
      <Box
        onClick={() => onClick(subPath)}
        sx={{
          color: `${
            activeCondition === active
              ? "blue"
              : `${theme.palette.primary.main}`
          }`,
          marginLeft: "-8px",
        }}
      >
        <CollapseNotExtend
          open={open}
          image={img}
          label={label}
          expend={expend}
          activate={activeCondition === active}
        />
      </Box>
    </>
  );
}
