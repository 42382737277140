import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function AvgPriceIndexChart() {
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "scatter",
      zoom: {
        enabled: true,
        type: "xy",
      },
    },
    markers: {
      size: 15,
    },
    xaxis: {
      tickAmount: 10,
      labels: {
        formatter: function (val) {
          return parseFloat(val).toFixed(1);
        },
      },
    },
    yaxis: {
      tickAmount: 7,
    },
  });

  const [series, setSeries] = useState([
    {
      name: "afia",
      data: [
        [16.4, 5.4],
        [24.5, 7.1],
        [10.9, 0],
        [8.1, 4.7],
      ],
    },
    {
      name: "araby",
      data: [
        [36.4, 13.4],
        [1.7, 11],
        [5.4, 8],
        [9, 17],
      ],
    },
    {
      name: "tayeb",
      data: [
        [21.7, 3],
        [23.6, 3.5],
        [24.6, 3],
        [29.9, 3],
      ],
    },
  ]);

  return (
    <Paper sx={{ p: 2 }}>
      <ReactApexChart
        options={options}
        series={series}
        type="scatter"
        height={300}
      />
    </Paper>
  );
}

export default AvgPriceIndexChart;
