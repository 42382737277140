import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  Paper,
  Stack,
} from "@mui/material";

import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { StyledTitleTypography } from "../../Styled/Typography";
import { getProjectData } from "../../Components/Network/Lib/Simulator";
import { SwitchingBehaviour } from "../../Components/Table";

function BrandSwitching({
  sku,
  setSku,
  selectSkus,
  setSelectSkus,
  brandSwitchingSKU,
}) {
  return (
    <Paper sx={{ minHeight: "100%", p: 1 }}>
      <Stack direction="row" alignItems="Center" justifyContent="space-between">
        <StyledTitleTypography sx={{ p: "2px" }}>
          Brand Switching
        </StyledTitleTypography>
        <Box minWidth={150}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: "12px",
                paddingBottom: "10px",
                overflow: "initial",
                lineHeight: ".5",
              }}
              id="demo-simple-select-label"
            >
              Sku
            </InputLabel>
            <Select
              multiple
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectSkus}
              label="Sku"
              onChange={(event) => setSelectSkus(event.target.value)}
              sx={{
                maxWidth: 250,
                "& .MuiSelect-select": {
                  padding: "8px",
                  height: "36px",
                },
                "& .MuiMenuItem-root": {
                  height: "36px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
              }}
            >
              {sku.map((row, idx) => (
                <MenuItem value={row} key={idx}>
                  {row}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Box
        sx={{
          fontSize: "12px",
          mb: "2px",
          color: "#6D6D6D",
          p: "2px",
        }}
      >
        Discover the key to building a loyal customer base by learning the
        reasons behind changes in consumer buying habits.
      </Box>
      <SwitchingBehaviour brandSwitchingSKU={brandSwitchingSKU} />
    </Paper>
  );
}

export default BrandSwitching;
