import React, { Fragment } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { theme } from "../../../Theme";

function TableData({ tableData }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      padding: "8px",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      textAlign: "center",
      justifyContent: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      textTransform: "capitalize",
      fontSize: 12,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.primary.main}`,

    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const tableHeader = tableData.tableConfig.map((column, idx) => {
    if (column.header) {
      return (
        <StyledTableCell key={column.label}>{column.header()}</StyledTableCell>
      );
    }
    return (
      <StyledTableCell sx={{ height: "50.44px !important" }} key={column.label}>
        {column.label}
      </StyledTableCell>
    );
  });

  const tableRowData = tableData.tableData.map((item, idx) => {
    const renderCells = tableData.tableConfig.map((column, idx) => (
      <StyledTableCell
        component="td"
        scope="row"
        sx={{
          color: `${theme.palette.primary.main}`,
          fontWeight: "bold",
        }}
        key={idx}
      >
        <Box sx={{ height: "15px !important" }}>{column.render(item)}</Box>
      </StyledTableCell>
    ));
    return <StyledTableRow key={idx}>{renderCells}</StyledTableRow>;
  });

  return (
    <TableContainer component={Paper} sx={{ height: "100%" }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>{tableHeader}</TableRow>
        </TableHead>
        <TableBody>{tableRowData}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableData;
