import { createTheme } from "@mui/material/styles";

let theme = createTheme({
    palette: {
        primary: {
            main: "#00265E",
            light: "#5750FF",
            dark: "#666666",
        },
        secondary: {
            main: "#FFFFFF",
            light: "#B3B3B3",
            dark: "#F2F4F7",
        },

    },

    typography: {
        fontFamily:
        'Barlow Semi Condensed',
            // ['Fredoka One','curve'].join(',')

    },


    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    }
});
export { theme };