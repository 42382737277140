import React, { useRef, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import TableData from "../../../../Components/Shared/table/TableData";
import DropDown from "../../utils/DropDown";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

function ValueTable({ companyPerformance }) {
  const tableData = companyPerformance;
  const tableConfig = [
    {
      label: "Value",
      render: (valueData) => valueData.value_sales + " $",
    },
    {
      label: "Growth",
      render: (valueData) => (
        <Box
          sx={{
            color: valueData.value_sales_growth < 1 ? "red" : "green",
            display: "flex",
            justifyContent: "center ",
            alignItems: "center",
          }}
        >
          {valueData.value_sales_growth < 1 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {valueData.value_sales_growth}
              <SouthIcon />
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {valueData.value_sales_growth}
              <NorthIcon />
            </Box>
          )}
        </Box>
      ),
      header: () => <DropDown />,
    },
  ];

  const ValueTable = {
    tableData,
    tableConfig,
  };

  return <TableData tableData={ValueTable} />;
}

export default ValueTable;
