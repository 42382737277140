import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Landscape from "../Pages/Landscape";
// import Landing from "../Pages/Landing";
import Login from "../Pages/Login";
import Modules from "../Pages/Modules";
import { theme } from "../Theme/index";
import "./App.css";
import BrandSwitching from "../Pages/BrandSwitching";
import SideBar from "../Components/SideBar";
import { Box } from "@mui/material";
import Optimization from "../Pages/Optmization";
import PPA from "../Pages/PPA";
import ValuePricing from "../Pages/ValuePricing";
import Assortment from "../Pages/Assortment";
import Scenario from "../Pages/Scenario";
import Scenarionew from "../Pages/Scenarionew";
import Simulator from "../Pages/Simulator";
import Home from "../Pages/Home";
import PricingAndPPA from "../Pages/pricingAndPPA/PricingAndPPA";
import KnowledgeAndInsights from "../Pages/KnowledgeAndInsights";
import Dashboard from "../Pages/Dashboard/Dashboard";
import MarketLandscape from "../Pages/MarketLandscape/MarketLandscape";
import Mix from "../Pages/Mix/Mix";
import BrandValue from "../Pages/BrandValue/brandValue";
import Promotions from "../Pages/Promtions/Promotions";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" element={<Landing />} /> */}
            <Route path="/" element={<Modules />} />
            <Route path="/login" element={<Login />} />
            <Route element={<SideBar />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/pricingAndPPA" element={<PricingAndPPA />} />
              <Route path="/Promotion" element={<Promotions />} />
              <Route path="/landscape" element={<MarketLandscape />} />
              <Route path="/mix" element={<Mix />} />
              <Route path="/brandValue" element={<BrandValue />} />

              <Route path="/PPA" element={<PPA />} />
              <Route path="/ValuePricing" element={<ValuePricing />} />
              <Route path="/Assortment" element={<Assortment />} />
              <Route path="/brand-switching" element={<BrandSwitching />} />
              <Route path="/optimization" element={<Optimization />} />
              <Route path="/Scenario" element={<Scenarionew />} />
              <Route path="/Scenario2" element={<Scenarionew />} />
              <Route path="/Simulator" element={<Simulator />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
