import React, { useState } from "react";
import { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { theme } from "../../../Theme";
import styled from "@emotion/styled";
import { TableCell, tableCellClasses } from "@mui/material";

function TableColumnsWithMarket() {
  const [years, setYears] = useState([]);
  const valueShare = ["1,261", "1,322", "1,442"];
  const volumeShare = [294, 295, 178];
  const valueGrowth = ["", "1.04 %", "1.09 %"];
  const volumeGrowth = ["-", "1.004", "0.84"];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      padding: "5px",
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      padding: "2px",
      textAlign: "center",
      fontSize: 12,
    },
  }));
  return (
    <TableContainer sx={{ borderRadius: "10px" }}>
      <Table sx={{ minWidth: 400 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {years.map((year) => {
              return (
                <Fragment key={year}>
                  <StyledTableCell sx={{ borderLeft: "1px dashed gray" }}>
                    FY{year}
                  </StyledTableCell>
                </Fragment>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell
              sx={{
                width: "100px",
                bgcolor: "rgba(0, 227, 150, 0.85)",
                color: "white",
              }}
            >
              Seg. Share
            </StyledTableCell>
            {valueShare.map((value) => {
              return (
                <Fragment key={value}>
                  <StyledTableCell
                    sx={{
                      borderLeft: "1px dashed gray",
                      paddingLeft: "20px",
                    }}
                  >
                    {value}
                  </StyledTableCell>
                </Fragment>
              );
            })}
          </TableRow>
          <TableRow>
            <StyledTableCell
              sx={{ bgcolor: "rgba(0, 227, 150, 0.85)", color: "white" }}
            >
              Price Index
            </StyledTableCell>
            {volumeShare.map((volume) => {
              return (
                <Fragment key={volume}>
                  <StyledTableCell sx={{ borderLeft: "1px dashed gray" }}>
                    {volume}
                  </StyledTableCell>
                </Fragment>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableColumnsWithMarket;
