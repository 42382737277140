import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";
import { GapsAndOpp, GapsAndOppTwoTable, TablePopUp } from "./Table";
import {
  ChartDountMarketShare,
  ChartDountValueShare,
  ChartDountRevenue,
} from "./Chart";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Loading } from "./Loading";
export function ResponsiveDialog({ text, labels }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        {text}
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <GapsAndOpp labels={labels} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogTwoTable({ text, labels, MFC }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        {text}
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "auto" }}>
          <GapsAndOppTwoTable labels={labels} MFC={MFC} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogTwoTable1({ text, labels, MFC }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography
          component="span"
          sx={{
            color: `${theme.palette.primary.main}`,
            fontWeight: "bold",
          }}
        >
          Manufacturer:
        </Typography>{" "}
        <Typography
          component="span"
          sx={{
            color: `#41DD64`,
            fontWeight: "bold",
          }}
        >
          PALM & VEGETABLE OIL.
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography>
            <Typography
              component="span"
              sx={{
                color: `${theme.palette.primary.light}`,
                fontWeight: "bold",
              }}
            >
              Manufacturer:
            </Typography>{" "}
            The top opportunity here for investment was{" "}
            <Typography
              component="span"
              sx={{
                color: `#41DD64`,
                fontWeight: "bold",
              }}
            >
              PALM & VEGETABLE OIL
            </Typography>{" "}
            Although SUNFLOWER OIL, CORN OIL has increased its volume sales over
            the past year it`s still below the market. There is clearly a gap in{" "}
            <Typography sx={{ color: "red", fontWeight: "bold" }}>
              {" "}
              BLEND OIL, CANOLA OIL
            </Typography>
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ width: "auto" }}>
          <GapsAndOppTwoTable labels={labels} MFC={MFC} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogTwoTable2({ text, labels, MFC }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography
          component="span"
          sx={{
            color: `${theme.palette.primary.main}`,
            fontWeight: "bold",
          }}
        >
          AFIA:
        </Typography>{" "}
        <Typography
          component="span"
          sx={{
            color: `#41DD64`,
            fontWeight: "bold",
          }}
        >
          PALM & VEGETABLE OIL and CORN OIL
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography>
            <Typography
              component="span"
              sx={{
                color: `${theme.palette.primary.light}`,
                fontWeight: "bold",
              }}
            >
              AFIA:
            </Typography>{" "}
            The top opportunities here for investment were{" "}
            <Typography
              component="span"
              sx={{
                color: `#41DD64`,
                fontWeight: "bold",
              }}
            >
              PALM & VEGETABLE OIL and CORN OIL.
            </Typography>{" "}
            There is clearly a gap in{" "}
            <Typography sx={{ color: "red", fontWeight: "bold" }}>
              {" "}
              SUNFLOWER OIL BLEND OIL, CANOLA OIL.
            </Typography>
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ width: "auto" }}>
          <GapsAndOppTwoTable labels={labels} MFC={MFC} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogTwoTable3({ text, labels, MFC }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography
          component="span"
          sx={{
            color: `${theme.palette.primary.main}`,
            fontWeight: "bold",
          }}
        >
          HALA:
        </Typography>{" "}
        <Typography
          component="span"
          sx={{
            color: `#41DD64`,
            fontWeight: "bold",
          }}
        >
          SUNFLOWER OIL.
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography>
            <Typography
              component="span"
              sx={{
                color: `${theme.palette.primary.light}`,
                fontWeight: "bold",
              }}
            >
              HALA:
            </Typography>{" "}
            Although
            <Typography
              component="span"
              sx={{
                color: `#41DD64`,
                fontWeight: "bold",
              }}
            >
              SUNFLOWER OIL, CORN OIL
            </Typography>{" "}
            has increased its volume sales over the past year, it`s still below
            the market. There is clearly a gap in
            <Typography sx={{ color: "red", fontWeight: "bold" }}>
              CORN OIL AND CANOLA OIL.
            </Typography>
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ width: "auto" }}>
          <GapsAndOppTwoTable labels={labels} MFC={MFC} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogTwoTable4({ text, labels, MFC }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography
          component="span"
          sx={{
            color: `${theme.palette.primary.main}`,
            fontWeight: "bold",
          }}
        >
          HALA:
        </Typography>{" "}
        <Typography
          component="span"
          sx={{
            color: `#41DD64`,
            fontWeight: "bold",
          }}
        >
          SUNFLOWER OIL, CORN OIL
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography>
            <Typography
              component="span"
              sx={{
                color: `${theme.palette.primary.light}`,
                fontWeight: "bold",
              }}
            >
              Total category:
            </Typography>{" "}
            <Typography
              component="span"
              sx={{
                color: `#41DD64`,
                fontWeight: "bold",
              }}
            >
              Total category: HALAH, ZAHRATI,
            </Typography>{" "}
            HALAH, ZAHRATI, LEEZA
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ width: "auto" }}>
          <GapsAndOppTwoTable labels={labels} MFC={MFC} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialog1({ text, labels }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography>
          <Typography
            component="span"
            sx={{
              color: `${theme.palette.primary.main}`,
              fontWeight: "bold",
            }}
          >
            Total category:
          </Typography>{" "}
          <Typography
            component="span"
            sx={{ color: "#41DD64", fontWeight: "bold" }}
          >
            HALAH, ZAHRATI, LEEZA{" "}
          </Typography>
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <GapsAndOpp labels={labels} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialog2({ text, labels }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography>
          <Typography
            component="span"
            sx={{
              color: `${theme.palette.primary.main}`,
              fontWeight: "bold",
            }}
          >
            BLEND OIL:
          </Typography>

          <Typography
            component="span"
            sx={{ color: "#41DD64", fontWeight: "bold" }}
          >
            {" "}
            LEEZA, ZAKI, HALAH
          </Typography>
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <GapsAndOpp labels={labels} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialog3({ text, labels }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography>
          <Typography
            component="span"
            sx={{
              color: `${theme.palette.primary.main}`,
              fontWeight: "bold",
            }}
          >
            PALM & VEGETABLE OIL :
          </Typography>
          <Typography
            component="span"
            sx={{ color: "#41DD64", fontWeight: "bold" }}
          >
            {" "}
            HALAH, ZAIN
          </Typography>{" "}
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <GapsAndOpp labels={labels} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialog4({ text, labels }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography>
          <Typography
            component="span"
            sx={{
              color: `${theme.palette.primary.main}`,
              fontWeight: "bold",
            }}
          >
            SUNFLOWER OIL :
          </Typography>
          <Typography
            component="span"
            sx={{ color: "#41DD64", fontWeight: "bold" }}
          >
            {" "}
            HALAH, ZAHRATI, AL ARABI
          </Typography>{" "}
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <GapsAndOpp labels={labels} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogTwoTableG1({ text, labels, MFC }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography>
          <Typography
            component="span"
            sx={{
              color: `${theme.palette.primary.main}`,
              fontWeight: "bold",
            }}
          >
            Manufacturer:
          </Typography>{" "}
          <Typography
            component="span"
            sx={{
              color: `red`,
              fontWeight: "bold",
            }}
          >
            BLEND OIL, CANOLA OIL
          </Typography>{" "}
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "auto" }}>
          <GapsAndOppTwoTable labels={labels} MFC={MFC} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogTwoTableG2({ text, labels, MFC }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography>
          <Typography
            component="span"
            sx={{
              color: `${theme.palette.primary.main}`,
              fontWeight: "bold",
            }}
          >
            AFIA:
          </Typography>{" "}
          <Typography
            component="span"
            sx={{
              color: `red`,
              fontWeight: "bold",
            }}
          >
            SUNFLOWER OIL BLEND OIL, CANOLA OIL.
          </Typography>{" "}
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "auto" }}>
          <GapsAndOppTwoTable labels={labels} MFC={MFC} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogTwoTableG3({ text, labels, MFC }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography sx={{}}>
          <Typography
            component="span"
            sx={{
              color: `${theme.palette.primary.main}`,

              fontWeight: "bold",
            }}
          >
            HALA:
          </Typography>{" "}
          <Typography
            component="span"
            sx={{
              color: `red`,
              fontWeight: "bold",
            }}
          >
            {" "}
            CORN OIL AND CANOLA OIL .
          </Typography>{" "}
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "auto" }}>
          <GapsAndOppTwoTable labels={labels} MFC={MFC} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogG4({ text, labels }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography>
          <Typography
            component="span"
            sx={{
              color: `${theme.palette.primary.main}`,
              fontWeight: "bold",
            }}
          >
            CORN OIL:
          </Typography>
          <Typography
            component="span"
            sx={{ color: "red", fontWeight: "bold" }}
          >
            {" "}
            AFIA
          </Typography>
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <GapsAndOpp labels={labels} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogG5({ text, labels }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography sx={{}}>
          <Typography
            component="span"
            sx={{
              color: `${theme.palette.primary.main}`,
              fontWeight: "bold",
            }}
          >
            PALM & VEGETABLE OIL :
          </Typography>

          <Typography
            component="span"
            sx={{ color: "red", fontWeight: "bold" }}
          >
            AFIA, AL ARABI, AL TAYEB
          </Typography>
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <GapsAndOpp labels={labels} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ResponsiveDialogG6({ text, labels }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography sx={{}}>
          <Typography
            component="span"
            sx={{
              color: `${theme.palette.primary.main}`,
              fontWeight: "bold",
            }}
          >
            SUNFLOWER OIL :
          </Typography>
          <Typography
            component="span"
            sx={{ color: "red", fontWeight: "bold" }}
          >
            {" "}
            AFIA
          </Typography>{" "}
        </Typography>
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{text}</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <GapsAndOpp labels={labels} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function PopUpCustomize({ button, result, flag,setResultBestOptimization }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  console.log(result);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setResultBestOptimization({});
    console.log(result);
  };

  return (
    <div>
      <Typography
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          "&:hover": {
            cursor: "pointer",
            textShadow: "2px 3px 8px #C7C8FA",
          },
        }}
      >
        <Typography
          component="span"
          sx={{
            color: `#41DD64`,
            fontWeight: "bold",
          }}
        >
          {button}
        </Typography>
      </Typography>
      
    </div>
  );
}

//delete pop up

{
  /* <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ textAlign: "center" }}
        >
          <Box
            component="img"
            src={deleteEdit}
            sx={{ height: "50%", width: "50%", margin: "auto" }}
          />
          <DialogTitle id="responsive-dialog-title">Are you sure ?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure You want to Remove this Record ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              sx={{
                color: "black",
                textTransform: "capitalize",
                backgroundColor: "#EFF2F",
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => {
                handleClose();
                handleDelete(id);
              }}
              autoFocus
              sx={{
                color: "white",
                textTransform: "capitalize",
                backgroundColor: "#FF6C6C",
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog> */
}
