import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";

function TopSkusTable() {
  const [rowData, setRowData] = useState([
    {
      index: "Afia SF 1.5L",
      elasticity: -0.035330368,
      "volume driver": false,
      "value driver": true,
      cannibalization: 68.5016470816,
      "high cannibalization": false,
      "Top SKU in interaction": "Zahrati SF 1.5L",
      "Top Cannibalizer": "Zahrati SF 1.5L",
      Strategy: "Don't Down Price",
      min: 14,
      max: 22.4,
    },
    {
      index: "Halah SF 1.5L",
      elasticity: -0.3437726942,
      "volume driver": false,
      "value driver": true,
      cannibalization: 60.2314911752,
      "high cannibalization": false,
      "Top SKU in interaction": "Zahrati SF 1.5L",
      "Top Cannibalizer": "Zahrati SF 1.5L",
      Strategy: "Follow Competitor",
      min: 10.3,
      max: 15,
    },
    {
      index: "Shams SF 1.5L",
      elasticity: -0.3550619332,
      "volume driver": true,
      "value driver": false,
      cannibalization: 58.2983563896,
      "high cannibalization": false,
      "Top SKU in interaction": "Zahrati SF 1.5L",
      "Top Cannibalizer": "Zahrati SF 1.5L",
      Strategy: "Use To Attract New Customers",
      min: 12.6,
      max: 19,
    },
    {
      index: "Shams SF 1.5x2L",
      elasticity: 0.0,
      "volume driver": false,
      "value driver": true,
      cannibalization: 50.356389292,
      "high cannibalization": false,
      "Top SKU in interaction": "Afia Corn 1.5L",
      "Top Cannibalizer": "Afia Corn 1.5L",
      Strategy: "Value Driver Don't Promote",
      min: 25.5,
      max: 32.5,
    },
    {
      index: "Shams SF 2.9L",
      elasticity: -0.0791582193,
      "volume driver": false,
      "value driver": true,
      cannibalization: 60.6156867911,
      "high cannibalization": false,
      "Top SKU in interaction": "Zahrati SF 1.5L",
      "Top Cannibalizer": "Zahrati SF 1.5L",
      Strategy: "Value Driver Don't Promote",
      min: 21.4,
      max: 29.3,
    },
    {
      index: "Afia SF 1.5x2L",
      elasticity: 0.0,
      "volume driver": false,
      "value driver": true,
      cannibalization: 76.6256560291,
      "high cannibalization": false,
      "Top SKU in interaction": "Afia Corn 1.5L",
      "Top Cannibalizer": "Afia Corn 1.5L",
      Strategy: "Value Driver Don't Promote",
      min: 22.3,
      max: 33,
    },
    {
      index: "Abu Zahra SF 1.8L",
      elasticity: -0.1267950152,
      "volume driver": false,
      "value driver": true,
      cannibalization: 64.5930503171,
      "high cannibalization": false,
      "Top SKU in interaction": "Abu Zahra SF 2.9L",
      "Top Cannibalizer": "Zahrati SF 1.5L",
      Strategy: "Use To Upsize",
      min: 11,
      max: 17.3,
    },
    {
      index: "Afia SF 2.9L",
      elasticity: -0.0097427815,
      "volume driver": true,
      "value driver": false,
      cannibalization: 72.1647560299,
      "high cannibalization": false,
      "Top SKU in interaction": "Afia Corn 1.5L",
      "Top Cannibalizer": "Afia Corn 1.5L",
      Strategy: "Apply Promotions",
      min: 30,
      max: 42,
    },
  ]);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [brandSwitchingSku, setBrandSwitchingSku] = React.useState([
    "Afia SF 1.5L",
    "Halah SF 1.5L",
    ,
    "Shams SF 1.5L",
    "Shams SF 1.5x2L",
    "Shams SF 2.9L",
    "Afia SF 1.5x2L",
    "Abu Zahra SF 1.8L",
    "Afia SF 2.9L",
  ]);

  useEffect(() => {
    setSelectedSkus(
      rowData.filter((item) => brandSwitchingSku.includes(item["index"]))
    );
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
      textAlign: "center",
      padding: "10px !important",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      textAlign: "center",
      padding: "10px !important",
    },
  }));

  return (
    <Table aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>SKU Name </StyledTableCell>
          <StyledTableCell sx={{ width: "20%    " }}>
            Cannibilization
          </StyledTableCell>
          <StyledTableCell>Top SKU in Interaction</StyledTableCell>
          <StyledTableCell>Strategy</StyledTableCell>
          <StyledTableCell>Volume Driver</StyledTableCell>
          <StyledTableCell>Value Driver</StyledTableCell>
          <StyledTableCell>Min Price</StyledTableCell>
          <StyledTableCell>Max Price</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedSkus.map((row) => (
          <TableRow>
            {console.log(row)}
            <StyledTableCell>{row["index"]} </StyledTableCell>
            <StyledTableCell>
              {row["cannibalization"].toFixed(2)} %
            </StyledTableCell>
            <StyledTableCell>{row["Top SKU in interaction"]}</StyledTableCell>
            <StyledTableCell sx={{ backgroundColor: "orange" }}>
              {row["Strategy"]}
            </StyledTableCell>
            <StyledTableCell>
              {row["volume driver"] == true ? (
                <DoneIcon sx={{ color: "green" }} />
              ) : (
                ""
              )}
            </StyledTableCell>
            <StyledTableCell>
              {row["value driver"] == true ? (
                <DoneIcon sx={{ color: "green" }} />
              ) : (
                ""
              )}
            </StyledTableCell>
            <StyledTableCell>{row["min"].toFixed(1)}</StyledTableCell>
            <StyledTableCell>{row["max"].toFixed(1)}</StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default TopSkusTable;
