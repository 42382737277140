import styled from "@emotion/styled";
import { Button, Link } from "@mui/material";

export const StyledLinkNav = styled(Link)(({ theme }) => ({
  color: `${theme.palette.primary.main}`,
  textTransform: "capitalize",
  padding: "10px",
  textDecoration: "none",
}));

export const StyledButtonNav = styled(Button)(({ theme }) => ({
  color: `${theme.palette.secondary.main}`,
  textTransform: "capitalize",
  backgroundColor: `${theme.palette.primary.main}`,
  marginRight: "3rem",
  // marginLeft:" 3rem"
}));

//Modules

export const StyledButtonModules = styled(Button)(({ theme }) => ({
  color: `${theme.palette.primary.dark}`,
  textTransform: "capitalize",
  // marginLeft:" 3rem"
}));
